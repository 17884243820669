import { ITEM_TYPE } from '@/utils/enum.js';

const commonMixin = {
  created: function() {},
  methods: {
    handleOrderItemUpsert(orderItems) {
      const itemHandlers = orderItems.map(item => {
        switch (item.orderItemType) {
          case ITEM_TYPE.MAIN: {
            item.productType = ITEM_TYPE.MAIN;
            break;
          }
          case ITEM_TYPE.GIFT: {
            item.productType = ITEM_TYPE.GIFT;
            break;
          }
          case ITEM_TYPE.ATTACH: {
            item.productType = ITEM_TYPE.ATTACH;
            break;
          }
        }
        item.productPrice = Number(item.sellingPriceMask);
        return item;
      });

      const mainItems = itemHandlers.filter(
        item => item.orderItemType === ITEM_TYPE.MAIN
      );
      const relatedItems = itemHandlers.filter(
        item => item.orderItemType !== ITEM_TYPE.MAIN
      );

      return mainItems.map(mainItem => {
        const gifts = relatedItems.filter(
          item =>
            item.belongOrderDetailId === mainItem.id &&
            item.orderItemType === ITEM_TYPE.GIFT
        );

        const attachs = relatedItems.filter(
          item =>
            item.belongOrderDetailId === mainItem.id &&
            item.orderItemType === ITEM_TYPE.ATTACH
        );
        mainItem.gifts = gifts;
        mainItem.attachs = attachs;
        return mainItem;
      });
    }
  }
};
export default commonMixin;
