import ApiService from '@/core/services/api.service';

const commonMixin = {
  created: function() {},
  methods: {
    fetchStores: async function() {
      return ApiService.get('/stores/getStores').then(response => {
        return response.data;
      });
    },
    fetchOrderSources() {
      return ApiService.get(`orderSources/getAll`).then(response => {
        return response.data;
      });
    }
  }
};
export default commonMixin;
