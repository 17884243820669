<template>
  <div>
    <b-form-group :disabled="disabled">
      <div class="d-inline-flex">
        <label>Hoá đơn đỏ </label>
        <b-checkbox v-model="billData.companyBill" class="ml-2"></b-checkbox>
      </div>

      <b-form-radio-group
        v-model="vatInvoice.vatCompanyType"
        v-if="billData.companyBill"
        class="mt-2"
        :options="[
          { text: 'Công ty', value: 1 },
          { text: 'Cá nhân', value: 2 },
        ]"
        name="radio-inline"
      ></b-form-radio-group>
    </b-form-group>
    <b-form-group v-show="billData.companyBill" :disabled="disabled">
      <label v-if="vatInvoice.vatCompanyType === 2" class="mt-2"
        >Mã số cá nhân:</label
      >
      <label v-else class="mt-2">Mã số thuế:</label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="vatInvoice.taxCode"
        :placeholder="
          vatInvoice.vatCompanyType === 2
            ? 'Nhập mã số cá nhân'
            : 'Nhập mã số thuế'
        "
      ></b-form-input>
    </b-form-group>
    <b-form-group v-show="billData.companyBill" :disabled="disabled">
      <label v-if="vatInvoice.vatCompanyType === 2">Địa chỉ:</label>
      <label v-else>Địa chỉ công ty:</label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="vatInvoice.companyAddress"
        :placeholder="
          vatInvoice.vatCompanyType === 2
            ? 'Nhập địa chỉ'
            : 'Nhập địa chỉ công ty'
        "
      ></b-form-input>
    </b-form-group>
    <b-form-group v-show="billData.companyBill" :disabled="disabled">
      <label v-if="vatInvoice.vatCompanyType === 2">Họ và tên:</label>
      <label v-else>Công ty:</label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="vatInvoice.companyName"
        :placeholder="
          vatInvoice.vatCompanyType === 2
            ? 'Nhập họ và tên'
            : 'Nhập tên công ty'
        "
      ></b-form-input>
    </b-form-group>
    <b-form-group v-show="billData.companyBill" :disabled="disabled">
      <label>Email:</label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="vatInvoice.email"
        placeholder="Nhập địa chỉ email"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { cloneDeep } from '../../../utils/common';
export default {
  props: ['billData', 'disabled'],
  data() {
    return {
      vatInvoice: {
        vatCompanyType: 1,
        taxCode: '',
        companyAddress: '',
        companyName: '',
        email: '',
      },
    };
  },
  watch: {
    vatInvoice: {
      handler: function (newValue) {
        this.billData.vatInvoice = newValue;
      },
      deep: true,
    },
  },
  created() {
    this.vatInvoice = cloneDeep(this.billData.vatInvoice);
  },
};
</script>