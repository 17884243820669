<template>
  <div class="upsert-order">
    <KTCodePreview v-bind:title="''">
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div class="wizard-nav-bg">
            <b-col
              class="py-2 mb-1"
              cols="12"
              style="border-bottom: 0.5px solid #e7e7e7"
            >
              <!-- <i class="fas fa-user-headset"></i> -->
              <b-skeleton-wrapper :loading="!loadingComponent">
                <template #loading>
                  <b-row>
                    <b-col cols="3">
                      <div class="d-flex d-inline">
                        <b-skeleton type="avatar"></b-skeleton>
                        <div class="w-100 pl-2">
                          <b-skeleton width="30%"></b-skeleton>
                          <b-skeleton width="50%"></b-skeleton>
                        </div>
                      </div>
                    </b-col>
                    <b-col cols="9" class="text-center align-self-center">
                      <b-skeleton width="70%"></b-skeleton>
                    </b-col>
                  </b-row>
                </template>
                <OrderHeader
                  :entity="orderInfo"
                  :userInfor="userInfor"
                  :action="action"
                  typeHeader="order-upsert"
                  v-if="loadingComponent"
                />
              </b-skeleton-wrapper>
            </b-col>
          </div>
          <div
            class="wizard wizard-4"
            id="kt_wizard_v4_2"
            data-wizard-state="step-first"
            data-wizard-clickable="true"
          >
            <div class="wizard-nav wizard-nav-bg" style="border-radius: 0px">
              <div class="wizard-steps">
                <div
                  class="wizard-step"
                  data-wizard-type="step"
                  data-wizard-state="current"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Thông tin</div>
                    </div>
                  </div>
                </div>
                <div class="wizard-step ml-1" data-wizard-type="step">
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Sản phẩm</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step ml-1"
                  data-wizard-type="step"
                  v-if="orderId"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Bút toán</div>
                    </div>
                  </div>
                </div>
                <div
                  class="wizard-step ml-1"
                  data-wizard-type="step"
                  v-if="orderId"
                >
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Lịch sử</div>
                    </div>
                  </div>
                </div>
                <div class="wizard-step ml-1" data-wizard-type="step">
                  <div class="wizard-wrapper p-2">
                    <div class="wizard-label">
                      <div class="wizard-title">Đính kèm</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-custom card-shadowless rounded-top-0">
              <div class="card-body p-0">
                <b-row class="py-8">
                  <b-col cols="12">
                    <div
                      class="pb-5 full-width"
                      data-wizard-type="step-content"
                      data-wizard-state="current"
                    >
                      <b-container>
                        <b-row class="col-md-12 mb-1 m-0 p-0" v-if="preOrderId">
                          <b-alert
                            show
                            variant="warning"
                            style="
                              background-color: #fff3db;
                              border-color: #ffecc7;
                            "
                            class="w-100"
                          >
                            <i
                              class="fa fa-exclamation-triangle text-warning"
                            ></i>
                            <strong class="text-dark">
                              Đơn hàng được tạo từ đơn hàng đặt trước:</strong
                            >
                            <b
                              class="text-primary orderCode"
                              @click="linkToPreOrder(preOrderId)"
                              style="cursor: pointer"
                            >
                              {{ preOrderId }}
                            </b>
                          </b-alert>
                        </b-row>
                      </b-container>
                      <b-container>
                        <b-row
                          class="col-md-12 mb-1 m-0 p-0"
                          v-if="!userInfor.jobTitleId"
                        >
                          <b-alert
                            show
                            variant="danger"
                            style="
                              background-color: #ffffff;
                              border-color: #efefef;
                            "
                            class="w-100"
                          >
                            <i
                              class="fa fa-exclamation-triangle text-warning"
                            ></i>
                            <strong class="text-danger">
                              Tài khoản chưa được gán chức vụ, vui lòng liên hệ
                              admin để xử lí</strong
                            >
                          </b-alert>
                        </b-row>
                      </b-container>
                      <b-container class="bv-example-row">
                        <b-skeleton-wrapper :loading="!loadingComponent">
                          <b-row>
                            <b-col>
                              <OrderInfo
                                :data="orderInfo"
                                v-if="loadingComponent"
                                :action="action"
                              />
                            </b-col>
                            <b-col>
                              <CustomerInfo
                                v-if="loadingComponent"
                                :fromOrder="fromOrder"
                                :action="action"
                              />
                            </b-col>
                            <b-col>
                              <OrderNote
                                v-if="loadingComponent"
                                :action="action"
                              />
                            </b-col>
                            <b-col>
                              <OrderDiscount
                                v-if="loadingComponent"
                                :action="action"
                              />
                            </b-col>
                            <b-col>
                              <BillPayment
                                :finalAmount="totalAmount"
                                :billData="orderFinal"
                                :onSubmiting="false"
                                :fromOrder="fromOrder"
                                :subtractPointAmount="
                                  orderDiscount.subtractPointAmount
                                "
                                :action="action"
                                v-if="loadingComponent"
                                v-on:receive-payment="onReceivePayment"
                              />
                            </b-col>
                          </b-row>
                          <template #loading>
                            <b-row>
                              <b-col>
                                <div>
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                              </b-col>
                              <b-col>
                                <div>
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                              </b-col>
                              <b-col>
                                <div>
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                              </b-col>
                              <b-col>
                                <div>
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                              </b-col>
                              <b-col>
                                <div>
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                                <div class="mt-4">
                                  <b-skeleton width="50%"></b-skeleton>
                                  <b-skeleton></b-skeleton>
                                </div>
                              </b-col>
                            </b-row>
                          </template>
                        </b-skeleton-wrapper>
                        <hr
                          class="hr-text"
                          data-content="Thông tin sản phẩm"
                          style="font-weight: 600"
                        />
                        <div class="mt-2" v-if="!onChangeProductTab">
                          <b-skeleton-wrapper :loading="!loadingComponent">
                            <template #loading>
                              <b-skeleton-table
                                :rows="5"
                                :columns="4"
                                :table-props="{ bordered: true, striped: true }"
                              ></b-skeleton-table>
                            </template>

                            <OrderProductsTable
                              :editable="true"
                              v-if="loadingComponent"
                            />
                          </b-skeleton-wrapper>

                          <hr
                            class="hr-text"
                            data-content="Bút toán"
                            style="font-weight: 600"
                            v-if="orderId"
                          />

                          <OrderTransactions
                            :orderNumber="orderId"
                            v-if="orderId"
                          />
                          <div v-if="false">
                            <code class="mt-4 d-block">
                              {{ orderInfo ? Object.values(orderInfo) : [] }}
                            </code>
                            <code class="mt-4 d-block">
                              {{
                                orderCustomerInfo
                                  ? Object.values(orderCustomerInfo)
                                  : []
                              }}
                            </code>
                            <code class="mt-4 d-block">
                              {{
                                orderDiscount
                                  ? Object.values(orderDiscount)
                                  : []
                              }}
                            </code>
                            <code class="mt-4 d-block">
                              {{ orderNote ? Object.values(orderNote) : [] }}
                            </code>
                            <code class="mt-4 d-block">
                              {{ orderItems || [] }}
                            </code>
                          </div>
                        </div>
                      </b-container>
                    </div>
                    <div
                      class="pb-5 full-width"
                      data-wizard-type="step-content"
                    >
                      <b-container
                        class="bv-example-row"
                        v-if="onChangeProductTab"
                      >
                        <OrderProductsTable
                          :editable="true"
                          v-if="loadingComponent"
                        />
                      </b-container>
                    </div>
                    <div
                      class="pb-5 full-width"
                      data-wizard-type="step-content"
                      v-if="orderId"
                    >
                      <b-container class="bv-example-row">
                        <OrderTransactions :orderNumber="orderId" />
                      </b-container>
                    </div>
                    <div
                      class="pb-5 full-width"
                      data-wizard-type="step-content"
                      v-if="orderId"
                    >
                      <b-container class="bv-example-row">
                        <b-row></b-row>
                      </b-container>
                    </div>
                    <div
                      class="pb-5 full-width"
                      data-wizard-type="step-content"
                    >
                      <b-container class="bv-example-row">
                        <b-row>
                          <div class="w-100">
                            <UploadFile :id="orderId" :entity="'orders'" />
                          </div>
                        </b-row>
                      </b-container>
                    </div>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <template #footer>
            <!-- <b-dropdown
              size="sm"
              split
              variant="primary"
              class="mr-3"
              @click="upsertOrder"
            >
              <template v-slot:button-content>
                <div class="font-weight-bolder" style="width: 4rem">
                  <span v-if="action === 'create'">Tạo mới</span
                  ><span v-else-if="action === 'update'">Cập nhật</span>
                </div>
              </template>
              <b-dropdown-item-button @click="onContinueCreateOrder"
                >Lưu và tiếp tục tạo đơn</b-dropdown-item-button
              >
            </b-dropdown> -->
            <b-button
              v-if="!preOrderId"
              class="font-weight-bolder mr-4"
              size="sm"
              variant="primary"
              style="width: 80px"
              @click="upsertOrder"
              :disabled="!loadingComponent"
            >
              <span v-if="action === 'create'">Tạo mới</span
              ><span v-else-if="action === 'update'">Cập nhật</span></b-button
            >
            <b-button
              v-else
              class="font-weight-bolder mr-4"
              size="sm"
              variant="primary"
              style="width: 80px"
              @click="upsertOrder"
              :disabled="!loadingComponent"
            >
              <span>Tạo mới</span></b-button
            >
            <b-button
              class="font-weight-bolder"
              size="sm"
              variant="secondary"
              style="width: 80px"
              @click="onCloseOrder"
              >Huỷ</b-button
            >
          </template>
        </b-card>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
</style>
<script>
import KTCodePreview from '@/view/content/CodeTab.vue';
import KTWizard from '@/assets/js/components/wizard';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
// common import
import { validationMixin } from 'vuelidate';
import {
  apiCommonMixin,
  linkMixin,
  commonMixin,
  calculateMixin,
} from '@/view/mixins/';

// import component
import CustomerInfo from '@/view/components/customers/CustomerInfo';
import BillPayment from '@/view/components/bills/BillPayment';
import OrderInfo from '@/view/components/order/OrderInfo';
import OrderNote from '@/view/components/order/OrderNote';
import OrderDiscount from '@/view/components/order/OrderDiscount';
import OrderHeader from '@/view/components/order/OrderHeader';
import OrderProductsTable from '@/view/components/order/OrderProductsTable';
import OrderTransactions from '@/view/components/OrderTransactions.vue';
import UploadFile from '@/view/modules/upload/Upload';

import SaveDataUtil from '@/utils/saveDataToLocal';
import { mapGetters } from 'vuex';

// setter vuex
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import {
  HANDLE_SET_ORDER_INFO,
  HANDLE_PURGE_ORDER_INFO,
} from '@/core/services/store/order/order-info.module';
import {
  SET_CUSTOMER_ORDER_INFO,
  PURGE_CUSTOMER_ORDER_INFO,
} from '@/core/services/store/order/order-customer-info.module';
import {
  SET_ORDER_DISCOUNT_INFO,
  PURGE_ORDER_DISCOUNT_INFO,
} from '@/core/services/store/order/order-discount.module';
import {
  SET_ORDER_NOTE_INFO,
  PURGE_ORDER_NOTE_INFO,
} from '@/core/services/store/order/order-note.module';
import {
  SET_PRODUCTS,
  PURGE_PRODUCTS,
} from '@/core/services/store/order/order-product-table.module';
import {
  makeToastFaile,
  getValueByKey,
  cloneDeep,
  unMaskPrice,
  makeToastFailHtml,
} from '@/utils/common';

import { ITEM_TYPE, ORDER_STATUS } from '@/utils/enum.js';
import { TIME_TRIGGER } from '@/utils/constants';

export default {
  props: ['fromModal', 'orderIdFromModal'],
  mixins: [
    validationMixin,
    apiCommonMixin,
    linkMixin,
    calculateMixin,
    commonMixin,
  ],
  data() {
    return {
      wizard: null,
      currentWizard: 1,
      orderId: null,
      preOrderId: null,
      orderInfo: {},
      orderNote: {},
      orderDiscount: {},
      orderCustomerInfo: {},
      orderPayment: {},
      onChangeProductTab: false,
      orderItems: [],
      loadingComponent: true,
      orderFinal: {
        storeId: null,
      },
      fromOrder: true,
      action: 'create',
      footSetting: {
        continue: false,
      },
    };
  },
  validations: {},
  components: {
    KTCodePreview,
    CustomerInfo,
    BillPayment,
    OrderInfo,
    OrderNote,
    OrderDiscount,
    OrderHeader,
    OrderProductsTable,
    OrderTransactions,
    UploadFile,
  },
  created() {
    this.mappingEnum();
    this.resetParamsVuex();
    this.getOrderIdFromAny();
  },
  mounted() {
    if (!this.fromModal) {
      const title = !this.orderId ? 'Thêm mới đơn hàng' : 'Cập nhật đơn hàng';
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Đơn hàng', route: '/orders' },
        { title: 'Danh sách đơn hàng', route: '/orders' },
        { title: title },
      ]);
    }

    // const orderId = this.$route.params.id || null;

    this.wizard = new KTWizard('kt_wizard_v4_2', {
      startStep: this.currentWizard,
      clickableSteps: true,
    });

    this.$nextTick(function () {
      this.wizard.on('change', this.onChangeTab);
    });
  },
  destroyed() {
    this.resetParamsVuex();
  },
  computed: {
    ...mapGetters([
      'getProducts',
      'getOrderInfo',
      'getOrderCustomerInfo',
      'getOrderDiscount',
      'getOrderNote',
    ]),
    totalAmount() {
      let totalPrice = 0;
      this.orderItems.map((item) => {
        if (item.orderItemType !== ITEM_TYPE.GIFT) {
          const totalPriceItem = this.calculateTotalAmountOrderItems(item);
          totalPrice += totalPriceItem;
        }
      });
      const discountAmount = unMaskPrice(this.orderDiscount.discountAmount);
      const discountType = this.orderDiscount.discountType;
      const customerShipFee = unMaskPrice(this.orderDiscount.customerShipFee);
      const shipFee = unMaskPrice(this.orderDiscount.shipFee);
      const subtractPointAmount = this.orderDiscount.subtractPointAmount;

      const discount = this.calculateDiscountAmount(
        discountAmount,
        discountType,
        totalPrice,
        1
      );
      totalPrice =
        totalPrice -
        Number(discount) +
        Number(customerShipFee) -
        Number(shipFee) -
        Number(subtractPointAmount);

      return totalPrice;
    },
    totalPrice() {
      let total = 0;
      return total;
    },
  },
  watch: {
    getOrderInfo: {
      handler(value) {
        console.log('getOrderInfo changed');
        this.orderInfo = value;
        //billpayment
        this.orderFinal.storeId = value.storeId;
      },
      deep: true,
    },
    getOrderCustomerInfo: {
      handler(value) {
        console.log('getOrderCustomerInfo changed');
        if (value) {
          this.orderCustomerInfo = value;
          //billpayment
          this.orderFinal.customerMobile = value.customerMobile;
          this.orderFinal.customerName = value.customerName;
        }
      },
      deep: true,
    },
    getOrderDiscount: {
      handler(value) {
        console.log('getOrderDiscount changed');
        if (value) {
          this.orderDiscount = value;
        }
      },
      deep: true,
    },
    getOrderNote: {
      handler(value) {
        console.log('getOrderNote changed');
        if (value) {
          this.orderNote = value;
        }
      },
      deep: true,
    },
    getProducts: {
      handler(value) {
        console.log('getProducts changed');
        if (value) {
          this.orderItems = value;
        }
      },
      deep: true,
    },
  },
  methods: {
    getOrderIdFromAny() {
      if (this.fromModal) {
        const orderId = this.orderIdFromModal || null;
        if (orderId) {
          this.orderId = this.orderIdFromModal;
        }
      } else {
        const orderId = this.$route.params.id || null;
        if (orderId) {
          this.orderId = orderId;
        }

        const preOrderIdFromUrl = this.$route.query.fromPreOrder || null;
        if (preOrderIdFromUrl) {
          this.preOrderId = preOrderIdFromUrl;
        }
      }

      if (this.orderId) {
        this.loadingComponent = false;
        this.action = 'update';
        this.getOrder(this.orderId);
      }

      if (this.preOrderId) {
        this.loadingComponent = false;
        this.action = 'update';
        this.getPreOrderById();
      }
    },
    resetParamsVuex() {
      console.log('Clear vues');
      this.$store.dispatch(HANDLE_PURGE_ORDER_INFO);
      this.$store.dispatch(PURGE_ORDER_NOTE_INFO);
      this.$store.dispatch(PURGE_CUSTOMER_ORDER_INFO);
      this.$store.dispatch(PURGE_ORDER_DISCOUNT_INFO);
      this.$store.dispatch(PURGE_PRODUCTS);
      console.log('Clear vuex Infor', JSON.stringify(this.getOrderInfo));
    },
    mappingEnum() {
      const userInfor = this.getEmployeeFromLocal();
      this.userInfor = userInfor;
    },
    getEmployeeFromLocal() {
      const userFromLocal = SaveDataUtil.getData('employee_info');
      if (userFromLocal) {
        const tmpInfo = JSON.parse(userFromLocal);
        return tmpInfo;
      }
    },
    onChangeTab(item) {
      const TAB = {
        INFO: 1,
        PRODUCT: 2,
      };
      if (item.currentStep === TAB.PRODUCT) {
        this.onChangeProductTab = true;
      } else if (item.currentStep === TAB.INFO) {
        this.onChangeProductTab = false;
      }
    },
    validateState(name) {
      if (!name) return null;
      //   const { $dirty, $error } = this.$v[name];
      //   return $dirty ? !$error : null;
    },
    mappingOrderVuex(data) {
      const orderInfoVuex = this.getOrderInfo;
      const orderInfo = getValueByKey(orderInfoVuex, data);

      this.$store.dispatch(HANDLE_SET_ORDER_INFO, orderInfo);

      const orderNoteVuex = this.getOrderNote;
      const orderNote = getValueByKey(orderNoteVuex, data);
      this.$store.dispatch(SET_ORDER_NOTE_INFO, orderNote);

      const orderDiscountInfoVuex = this.getOrderDiscount;
      const orderDiscountInfo = getValueByKey(orderDiscountInfoVuex, data);
      this.$store.dispatch(SET_ORDER_DISCOUNT_INFO, orderDiscountInfo);

      const orderCustomerInfoVuex = this.getOrderCustomerInfo;
      const orderCustomerInfo = getValueByKey(orderCustomerInfoVuex, data);
      this.$store.dispatch(SET_CUSTOMER_ORDER_INFO, orderCustomerInfo);

      const orderItems = this.handleOrderItemGet(cloneDeep(data.orderItems));

      this.$store.dispatch(SET_PRODUCTS, orderItems);

      this.orderFinal = data;

      setTimeout(() => {
        this.loadingComponent = true;
      }, TIME_TRIGGER);
    },
    upsertOrder: function () {
      const checkDate = this.orderInfo.checkDate
        ? moment(this.orderInfo.checkDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null;

      const paymentDate = this.orderDiscount.paymentDate
        ? moment(this.orderInfo.checkDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        : null;

      const orderItems = this.handleOrderItemUpsert(cloneDeep(this.orderItems));

      const discountAmount =
        unMaskPrice(this.orderDiscount.discountAmount) || 0;
      const customerShipFee =
        unMaskPrice(this.orderDiscount.customerShipFee) || 0;
      const shipFee = unMaskPrice(this.orderDiscount.shipFee) || 0;

      console.log('orderPayment', this.orderPayment);
      const order = {
        id: this.orderId,
        ...this.orderInfo,
        checkDate: checkDate,
        ...this.orderCustomerInfo,
        ...this.orderDiscount,
        discountAmount: discountAmount,
        customerShipFee: customerShipFee,
        shipFee: shipFee,
        paymentDate: paymentDate,
        ...this.orderNote,
        ...this.orderPayment,
        orderItems: orderItems,
      };

      const [isValid, messages] = this.validateUpsertOrder(order);

      if (!isValid) {
        makeToastFailHtml(this, messages);
        return;
      }

      let method = null;
      if (this.preOrderId) {
        method = 'post';
      } else {
        method = this.action === 'update' ? 'put' : 'post';
      }

      ApiService[method]('/orders/v2', order)
        .then(() => {
          if (this.fromModal) {
            this.$emit('refresh-order');
          } else {
            this.settingFooter();
          }
        })
        .catch((err) => {
          if (!err.response) {
            // network error
            makeToastFaile('Lỗi!');
          } else {
            const message = err.response.data.message;
            makeToastFaile(message);
          }
          this.$nprogress.done();
        });
    },
    validateUpsertOrder(order) {
      let isValid = true;
      const messages = [];

      if (!order.storeId) {
        isValid = false;
        messages.push('Vui lòng chọn cửa hàng');
      }

      if (!order.orderSourceId) {
        isValid = false;
        messages.push('Vui lòng chọn nguồn đơn hàng');
      }

      if (!order.orderType) {
        isValid = false;
        messages.push('Vui lòng nhập loại đơn hàng');
      }

      if (!order.status) {
        isValid = false;
        messages.push('Vui lòng chọn trạng thái đơn hàng');
      }

      if (
        [
          ORDER_STATUS.UN_SUCCESS,
          ORDER_STATUS.SYSTEM_CANCEL,
          ORDER_STATUS.CR_CANCEL,
        ].includes(Number(order.status)) &&
        !order.cancelStatus
      ) {
        isValid = false;
        messages.push('Vui lòng chọn lý do huỷ');
      }

      if (!order.orderItems.length) {
        isValid = false;
        messages.push('Vui lòng chọn sản phẩm');
      }

      if (!order.customerId) {
        isValid = false;
        messages.push('Vui lòng nhập số điện thoại khách hàng');
      }

      if (order.customerMobile && order.customerMobile.length > 12) {
        isValid = false;
        messages.push('Vui lòng nhập số điện thoại < 12 số');
      }

      if (!order.customerName || !order.customerName.trim().length) {
        isValid = false;
        messages.push('Vui lòng nhập tên khách hàng');
      }

      if (
        order.depositAmount &&
        order.depositAmount > 0 &&
        !order.cashAccountId
      ) {
        isValid = false;
        messages.push('Vui lòng nhập tài khoản tiền mặt');
      }

      if (
        order.transferAmount &&
        order.transferAmount > 0 &&
        !order.transferAccountId
      ) {
        isValid = false;
        messages.push('Vui lòng nhập tài khoản chuyển khoản');
      }

      if (
        order.installedMoneyAmount &&
        order.installedMoneyAmount > 0 &&
        !order.installMoneyAccountId
      ) {
        isValid = false;
        messages.push('Vui lòng nhập tài khoản trả góp');
      }

      if (
        order.creditAmount &&
        order.creditAmount > 0 &&
        !order.creditAccountId
      ) {
        isValid = false;
        messages.push('Vui lòng nhập tài khoản quẹt thẻ');
      } 
      
      if (
        order.payCreditFeeType &&
        order.otherFees &&
        order.otherFees > 0 &&
        !order.creditFeeAcountId
      ) {
        isValid = false;
        messages.push('Vui lòng nhập tài khoản phí quẹt thẻ');
      }

      return [isValid, messages];
    },
    handleOrderItemGet(orderItems) {
      let term = [];
      const itemHandlers = orderItems.map((item) => {
        item.sellingPrice = item.productPrice;
        item.sellingPriceMask = item.productPrice;
        item.discountType = item.discountType || 1;
        item.comboItems = item.productInCombos || []
        return item;
      });

      const mainItems = itemHandlers.filter(
        (item) => item.orderItemType === ITEM_TYPE.MAIN
      );

      let count = 1;
      mainItems.map((parent) => {
        const relatedItems = itemHandlers.filter(
          (child) => child.belongOrderDetailId === parent.orderItemId
        );
        const parentId = this.$uuid.v4();
        parent.id = parentId;
        parent.stt = count;
        term.push(parent);
        relatedItems.map((child) => {
          child.id = this.$uuid.v4();
          child.belongOrderDetailId = parentId;
          term.push(child);
        });
        count++;
      });
      return term;
    },
    getOrder: function (id) {
      ApiService.get(`/orders/${id}`).then((response) => {
        const { data } = response.data;
        const { status, message } = response.data;
        console.log('data from get by id', data);
        if (status) {
          this.mappingOrderVuex(data);
        } else {
          makeToastFaile(message);
        }
      });
    },
    getPreOrderById: async function () {
      ApiService.query(`pre-orders/${this.preOrderId}`).then(
        async (response) => {
          const { data } = response.data;
          const { status } = response.data;
          if (status) {
            if (data.orderId) {
              makeToastFaile(
                `Đơn hàng đặt trước đã được gắn với đơn hàng ${data.orderId}`
              );
              this.preOrderId = null;
              this.$router.push({
                path: '/orders',
                query: {
                  id: data.orderId,
                },
              });
              return;
            }
            const product = data.product;

            data.orderItems = [];
            if (product) {
              product.productId = product.id;
              product.productPrice = product.sellingPrice;
              product.orderItemType = 1;
              product.quantity = 1;
              product.belongOrderDetailId = null;
              product.isPrivateNote = false;
              product.popPrivateNote = '';
              data.orderItems = [product];
            }
            data.orderSourceId = 756139;
            data.orderSourceName = 'Pre-Order';
            data.saleNote = data.notes ? data.notes : '';
            data.installedMoneyAmount = data.installMoneyAmount || 0;
            data.installMoneyAccountId =
              Number(data.installMoneyAccountId) || null;
            data.installmentCode = data.installMoneyCode;
            data.installMoneyAccountName = data.installmentMoneyAccountName;
            data.preOrderId = this.preOrderId;

            this.mappingOrderVuex(data);
          }
        }
      );
    },
    onReceivePayment(paymentInfo) {
      this.orderPayment = paymentInfo;
    },
    onCloseOrder() {
      if (this.fromModal) {
        this.$bvModal.hide('md-edit-order');
      } else {
        this.$router.go(-1);
      }
    },
    onContinueCreateOrder() {
      this.footSetting.continue = true;
      this.upsertOrder();
    },
    settingFooter() {
      this.$router.push({
        path: '/orders',
      });
    },
  },
};
</script>
<style lang="scss">
.upsert-order {
  .relative {
    position: relative;
  }
  #input-discount {
    padding-left: 35%;
    border: 1px solid #ced4da;
  }
  .absolute {
    top: 9px;
    left: 4%;
    font-size: 0.74vw;
    position: absolute;
  }

  .bootstrap-datetimepicker-widget.dropdown-menu {
    width: 21em;
  }

  .vue-treeselect__input-container {
    padding-top: 0px;
  }

  .vue-treeselect__control {
    border-radius: 0.28rem;
    border: 1px solid #ced4da;
    height: 2.5rem;
  }

  .vue-treeselect--has-value .vue-treeselect__multi-value {
    margin-bottom: 2px;
  }

  .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
    flex: 0 0 calc(15% - 0.25rem);
  }

  @media (max-width: 1399.98px) {
    .wizard.wizard-4 .wizard-nav .wizard-steps .wizard-step {
      flex: 0 0 calc(17% - 0.25rem);
    }
  }

  .wizard-nav-bg {
    // background-color: #eef0f8;
    // border-radius: 4px 4px 0px 0px;
    background-color: #f7f7f7;
    border-radius: 5px 5px 0px 0px;
  }

  .btn-config .btn {
    padding: 0.4rem 0.4rem;
  }
  .card-footer {
    padding: 1rem 2.25rem;
  }
  .form-group {
    margin-bottom: 1rem;
  }
}
</style>
<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

#autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

#autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}
</style>
<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>
