var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bill-employee"},[_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('label',{attrs:{"for":"input-name"}},[_vm._v("Nhân viên bán hàng:")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning('Sale')),expression:"showWarning('Sale')"}],staticClass:"fas fa-exclamation-circle text-warning ml-2 icon-size",attrs:{"title":'Nhân viên chưa được lưu'}}),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
        class: 'autosuggest__input',
        placeholder: 'Nhân viên bán hàng',
      },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":function($event){return _vm.onSelected($event, 'Sale')},"input":_vm.onInputChange,"focus":function($event){return _vm.onFocusInput(_vm.jobTitle.TVBH)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('span',[_vm._v(_vm._s(suggestion.item.fullName))]),(suggestion.item.code)?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.code)+" ")]):_vm._e()])])}}]),model:{value:(_vm.billEmployeeInfo.saleName),callback:function ($$v) {_vm.$set(_vm.billEmployeeInfo, "saleName", $$v)},expression:"billEmployeeInfo.saleName"}})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('label',[_vm._v("Nhân viên kỹ thuật:")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning('Technical')),expression:"showWarning('Technical')"}],staticClass:"fas fa-exclamation-circle text-warning ml-2 icon-size",attrs:{"title":'Nhân viên chưa được lưu'}}),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
        class: 'autosuggest__input',
        placeholder: 'Nhân viên kỹ thuật',
      },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":function($event){return _vm.onSelected($event, 'Technical')},"input":_vm.onInputChange,"focus":function($event){return _vm.onFocusInput(_vm.jobTitle.KTPM)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('span',[_vm._v(_vm._s(suggestion.item.fullName))]),(suggestion.item.code)?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.code)+" ")]):_vm._e()])])}}]),model:{value:(_vm.billEmployeeInfo.technicalName),callback:function ($$v) {_vm.$set(_vm.billEmployeeInfo, "technicalName", $$v)},expression:"billEmployeeInfo.technicalName"}})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('label',[_vm._v("Nhân viên CDPK:")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning('Accessory')),expression:"showWarning('Accessory')"}],staticClass:"fas fa-exclamation-circle text-warning ml-2 icon-size",attrs:{"title":'Nhân viên chưa được lưu'}}),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
        class: 'autosuggest__input',
        placeholder: 'Nhân viên CDPK',
      },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":function($event){return _vm.onSelected($event, 'Accessory')},"input":_vm.onInputChange,"focus":function($event){return _vm.onFocusInput(_vm.jobTitle.CDPK)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('span',[_vm._v(_vm._s(suggestion.item.fullName))]),(suggestion.item.code)?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.code)+" ")]):_vm._e()])])}}]),model:{value:(_vm.billEmployeeInfo.accessoryStaffName),callback:function ($$v) {_vm.$set(_vm.billEmployeeInfo, "accessoryStaffName", $$v)},expression:"billEmployeeInfo.accessoryStaffName"}})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('label',[_vm._v("Nhân viên thu ngân:")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning('Cashier')),expression:"showWarning('Cashier')"}],staticClass:"fas fa-exclamation-circle text-warning ml-2 icon-size",attrs:{"title":'Nhân viên chưa được lưu'}}),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
        class: 'autosuggest__input',
        placeholder: 'Nhân viên thu ngân',
      },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":function($event){return _vm.onSelected($event, 'Cashier')},"input":_vm.onInputChange,"focus":function($event){return _vm.onFocusInput(_vm.jobTitle.KTBH)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('span',[_vm._v(_vm._s(suggestion.item.fullName))]),(suggestion.item.code)?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.code)+" ")]):_vm._e()])])}}]),model:{value:(_vm.billEmployeeInfo.cashierName),callback:function ($$v) {_vm.$set(_vm.billEmployeeInfo, "cashierName", $$v)},expression:"billEmployeeInfo.cashierName"}})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('label',[_vm._v("Quản lý cửa hàng:")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning('Manager')),expression:"showWarning('Manager')"}],staticClass:"fas fa-exclamation-circle text-warning ml-2 icon-size",attrs:{"title":'Nhân viên chưa được lưu'}}),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
        class: 'autosuggest__input',
        placeholder: 'Quản lý cửa hàng',
      },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":function($event){return _vm.onSelected($event, 'Manager')},"input":_vm.onInputChange,"focus":function($event){return _vm.onFocusInput(_vm.jobTitle.QL)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('span',[_vm._v(_vm._s(suggestion.item.fullName))]),(suggestion.item.code)?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.code)+" ")]):_vm._e()])])}}]),model:{value:(_vm.billEmployeeInfo.managerName),callback:function ($$v) {_vm.$set(_vm.billEmployeeInfo, "managerName", $$v)},expression:"billEmployeeInfo.managerName"}})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('label',[_vm._v("Trợ lý cửa hàng:")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning('Assistant')),expression:"showWarning('Assistant')"}],staticClass:"fas fa-exclamation-circle text-warning ml-2 icon-size",attrs:{"title":'Nhân viên chưa được lưu'}}),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
        class: 'autosuggest__input',
        placeholder: 'Trợ lý cửa hàng',
      },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":function($event){return _vm.onSelected($event, 'Assistant')},"input":_vm.onInputChange,"focus":function($event){return _vm.onFocusInput(_vm.jobTitle.TL)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('span',[_vm._v(_vm._s(suggestion.item.fullName))]),(suggestion.item.code)?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.code)+" ")]):_vm._e()])])}}]),model:{value:(_vm.billEmployeeInfo.assistantName),callback:function ($$v) {_vm.$set(_vm.billEmployeeInfo, "assistantName", $$v)},expression:"billEmployeeInfo.assistantName"}})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('label',[_vm._v("Tiếp đón khách hàng:")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning('CustomerReception')),expression:"showWarning('CustomerReception')"}],staticClass:"fas fa-exclamation-circle text-warning ml-2 icon-size",attrs:{"title":'Nhân viên chưa được lưu'}}),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
        class: 'autosuggest__input',
        placeholder: 'Tiếp đón khách hàng',
      },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":function($event){return _vm.onSelected($event, 'CustomerReception')},"input":_vm.onInputChange,"focus":function($event){return _vm.onFocusInput(_vm.jobTitle.TDKH)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('span',[_vm._v(_vm._s(suggestion.item.fullName))]),(suggestion.item.code)?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.code)+" ")]):_vm._e()])])}}]),model:{value:(_vm.billEmployeeInfo.customerReceptionName),callback:function ($$v) {_vm.$set(_vm.billEmployeeInfo, "customerReceptionName", $$v)},expression:"billEmployeeInfo.customerReceptionName"}})],1),_c('b-form-group',{attrs:{"disabled":_vm.disabled}},[_c('label',[_vm._v("Nhân viên giao hàng:")]),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.showWarning('Shipper')),expression:"showWarning('Shipper')"}],staticClass:"fas fa-exclamation-circle text-warning ml-2 icon-size",attrs:{"title":'Nhân viên chưa được lưu'}}),_c('vue-autosuggest',{attrs:{"suggestions":_vm.filteredOptionsEmployee,"limit":10,"input-props":{
        class: 'autosuggest__input',
        placeholder: 'Nhân viên giao hàng',
      },"should-render-suggestions":function (size, loading) { return size >= 0 && !loading; }},on:{"selected":function($event){return _vm.onSelected($event, 'Shipper')},"input":_vm.onInputChange},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var suggestion = ref.suggestion;
return _c('div',{staticStyle:{"display":"flex","align-items":"center"}},[_c('div',[_c('span',[_vm._v(_vm._s(suggestion.item.fullName))]),(suggestion.item.code)?_c('span',{staticClass:"text-primary"},[_vm._v(" - "+_vm._s(suggestion.item.code)+" ")]):_vm._e()])])}}]),model:{value:(_vm.billEmployeeInfo.shipperName),callback:function ($$v) {_vm.$set(_vm.billEmployeeInfo, "shipperName", $$v)},expression:"billEmployeeInfo.shipperName"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }