import { AMOUNT_TYPE } from '@/utils/enum';

const calculateMixin = {
  created: function() {},
  methods: {
    calculateTotalAmountOrderItems(item) {
      const sellingPrice = item.sellingPriceMask || 0;
      const quantity = item.quantity || 0;
      const discountAmount = item.discountAmount || 0;

      const totalAmountItem =
        Number(sellingPrice) * Number(quantity) - Number(discountAmount);
      return totalAmountItem;
    },
    calculateDiscountAmount(discount, type, money, quantity = 1) {
      let amount = 0;
      const PERCENT_100 = 100;
      // percent default discount by 1 product or item
      if (type === AMOUNT_TYPE.PERCENT) {
        amount = money * (discount / PERCENT_100);
      } else {
        amount = discount / quantity;
      }
      return amount;
    }
  }
};
export default calculateMixin;
