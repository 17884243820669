<template>
  <div>
    <b-form-group>
      <label>Ghi chú CSKH:</label>
      <b-form-textarea
        v-model="orderNote.saleNote"
        size="sm"
        class="input-style"
        :placeholder="'Thêm ghi chú'"
        :rows="6"
        :max-rows="6"
      ></b-form-textarea>
    </b-form-group>
    <b-form-group>
      <label>Ghi chú của khách:</label>
      <b-form-textarea
        v-model="orderNote.customerNote"
        size="sm"
        class="input-style"
        :placeholder="'Thêm ghi chú'"
        :rows="6"
        :max-rows="6"
      ></b-form-textarea>
    </b-form-group>
    <b-form-group>
      <label>Ghi chú bảo hành:</label>
      <b-form-textarea
        v-model="orderNote.warrantyNote"
        size="sm"
        class="input-style"
        :placeholder="'Thêm ghi chú bảo hành'"
        :rows="6"
        :max-rows="6"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { SET_ORDER_NOTE_INFO } from '@/core/services/store/order/order-note.module.js';
export default {
  mixins: [],
  props: ['data'],
  components: {},
  data() {
    return {
      orderNote: null,
    };
  },
  watch: {
    orderNote: {
      handler: function (value) {
        this.$store.dispatch(SET_ORDER_NOTE_INFO, value);
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(['getOrderNote']),
  },
  created() {
    this.orderNote = this.getOrderNote;
  },
  methods: {},
};
</script>
