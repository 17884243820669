<template>
  <div class="payment-common">
    <b-form-group :disabled="disabled">
      <label>{{ fromOrder ? 'Đặt cọc' : 'Tiền mặt' }}</label>
      <b-input-group>
        <template #prepend>
          <b-button
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingMoney(paymentType.CASH)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
        <b-form-input
          class="input-style text-right"
          size="sm"
          type="text"
          v-model="paymentInfo.cashAmount"
          placeholder="Nhập số tiền mặt"
          v-mask="mask"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group v-show="checkAmount('cashAmount')" :disabled="disabled">
      <label>Tài khoản tiền mặt:</label>
      <vue-autosuggest
        aria-describedby="input-cash-live-feedback"
        v-model="paymentInfo.cashAccountantName"
        :suggestions="filteredCashAccOptions"
        @selected="onSelected($event, 'cash')"
        :limit="10"
        @input="onInputChange($event, 'cash')"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhập tài khoản tiền mặt',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; aign-items: center"
        >
          {{ suggestion.item.name }}
        </div>
      </vue-autosuggest>
      <b-form-invalid-feedback
        :state="validateCash"
        id="input-cash-live-feedback"
        >Vui lòng chọn tài khoản tiền mặt</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Tiền chuyển khoản:</label>
      <b-input-group>
        <template #prepend>
          <b-button
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingMoney(paymentType.TRANSFER)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
        <b-form-input
          class="input-style text-right"
          size="sm"
          type="text"
          v-model="paymentInfo.transferAmount"
          placeholder="Nhập số tiền chuyển khoản"
          v-mask="mask"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group v-show="checkAmount('transferAmount')" :disabled="disabled">
      <label>Tài khoản chuyển khoản:</label>
      <vue-autosuggest
        aria-describedby="input-transfer-live-feedback"
        v-model="paymentInfo.transferAccountantName"
        :suggestions="filteredTransferAccOptions"
        @selected="onSelected($event, 'transfer')"
        :limit="10"
        @input="onInputChange($event, 'transfer')"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhập tài khoản chuyển khoản',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          {{ suggestion.item.name }}
        </div>
      </vue-autosuggest>
      <b-form-invalid-feedback
        :state="validateTransfer"
        id="input-transfer-live-feedback"
      >
        Vui lòng chọn tài khoản chuyển khoản!
      </b-form-invalid-feedback>
    </b-form-group>
    <b-form-group v-if="checkAmount('transferAmount')" :disabled="disabled">
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="paymentInfo.transferReferenceCode"
        placeholder="Mã tham chiếu"
      ></b-form-input>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Tiền quẹt thẻ:</label>
      <b-input-group>
        <template #prepend>
          <b-button
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingMoney(paymentType.CREDIT)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
        <b-form-input
          class="input-style text-right"
          size="sm"
          type="text"
          v-model="paymentInfo.creditAmount"
          placeholder="Tiền quẹt thẻ"
          v-mask="mask"
          @change="onCreditAmountChange"
        ></b-form-input>
      </b-input-group>
    </b-form-group>

    <b-form-group v-if="checkAmount('creditAmount')" :disabled="disabled">
      <label>Tài khoản quẹt thẻ:</label>
      <vue-autosuggest
        aria-describedby="input-credit-live-feedback"
        v-model="paymentInfo.creditAccountantName"
        :suggestions="filteredCreditAccOptions"
        @selected="onSelected($event, 'credit')"
        :limit="10"
        @input="onInputChange($event, 'credit')"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhập tài khoản quẹt thẻ',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          {{ suggestion.item.name }}
        </div>
      </vue-autosuggest>
      <b-form-invalid-feedback
        :state="validateCredit"
        id="input-credit-live-feedback"
        >Vui lòng chọn tài khoản quẹt thẻ!</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group v-if="checkAmount('creditAmount')" :disabled="disabled">
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="paymentInfo.creditCardCode"
        placeholder="Nhập 4 số cuối mã thẻ"
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="checkAmount('creditAmount')" :disabled="disabled">
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="paymentInfo.creditCardNo"
        placeholder="Mã giao dịch"
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="checkAmount('creditAmount')" :disabled="disabled">
      <label>Hình thức trả phí:</label>
      <b-form-select
        class="select-style"
        v-model="paymentInfo.payCreditFeeType"
        :options="listPayCreditFeeType"
        size="sm"
        value-field="id"
        text-field="name"
        @change="onCreditFeePayTypeChange"
      >
        <template v-slot:first>
          <b-form-select-option :value="null"
            >Chọn hình thức trả phí quẹt thẻ</b-form-select-option
          >
        </template>
      </b-form-select>
    </b-form-group>
    <b-form-group v-if="checkAmount('creditAmount')" :disabled="disabled">
      <b-form-input
        class="input-style text-right"
        size="sm"
        type="text"
        v-model="paymentInfo.creditCardFee"
        placeholder="Phí quẹt thẻ"
        v-mask="mask"
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="checkAmount('creditAmount')" :disabled="disabled">
      <label>Tài khoản thu phí quẹt thẻ:</label>
      <vue-autosuggest
        aria-describedby="input-credit-fee-live-feedback"
        v-model="paymentInfo.creditFeeAccountantName"
        :suggestions="filteredCreditFeeAccOptions"
        @selected="onSelected($event, 'creditFee')"
        :limit="10"
        @input="onInputChange($event, 'creditFee')"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhập tài khoản thu phí',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          {{ suggestion.item.name }}
        </div>
      </vue-autosuggest>
      <b-form-invalid-feedback
        :state="validateCreditFee"
        id="input-credit-fee-live-feedback"
        >Vui lòng chọn tài khoản thu phí quẹt thẻ!</b-form-invalid-feedback
      >
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Trả góp</label>
      <b-input-group>
        <template #prepend>
          <b-button
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="bindingMoney(paymentType.INSTALLMENT)"
          >
            <i class="fas fa-arrow-right"></i>
          </b-button>
        </template>
        <b-form-input
          class="input-style text-right"
          size="sm"
          type="text"
          v-model="paymentInfo.installedMoneyAmount"
          placeholder="Trả góp"
          v-mask="mask"
        ></b-form-input>
      </b-input-group>
    </b-form-group>
    <b-form-group
      v-if="checkAmount('installedMoneyAmount')"
      :disabled="disabled"
    >
      <label>Dịch vụ trả góp:</label>
      <b-form-group v-if="checkAmount('installedMoneyAmount')">
        <b-form-select
          id="input-style"
          v-model="paymentInfo.installmentProgramType"
          :options="[
            {
              id: 1,
              name: 'Dịch vụ trả lãi',
            },
            {
              id: 2,
              name: 'Dịch vụ trả góp 0%',
            },
          ]"
          size="sm"
          value-field="id"
          text-field="name"
          @change="onChangeInstallmentProgram"
        ></b-form-select>
      </b-form-group>

      <b-form-group v-if="showInsProgram" :disabled="disabled">
        <vue-autosuggest
          v-model="selectedInstallmentProgram"
          :suggestions="filteredOptionsInstallmentProgram"
          @selected="onInstallmentSelectedProgram"
          :limit="100"
          @input="onInputInstallmentChangeProgram"
          :input-props="{
            class: 'autosuggest__input',
            placeholder: 'Nhập chương trình trả góp',
            disabled: true,
          }"
        >
          <div
            slot-scope="{ suggestion }"
            style="display: flex; align-items: center"
          >
            {{ suggestion.item.name }}
          </div>
        </vue-autosuggest>
      </b-form-group>

      <b-form-group v-if="showInsProgram" :disabled="disabled">
        <b-form-select
          v-model="paymentInfo.installmentProgramMonthNo"
          :options="optionsMonthNo"
          size="sm"
          value-field="id"
          text-field="name"
        ></b-form-select>
      </b-form-group>

      <vue-autosuggest
        aria-describedby="input-installment-live-feedback"
        v-model="paymentInfo.installmentAccountName"
        :suggestions="filteredOptionsInstallment"
        @selected="onSelected($event, 'installment')"
        :limit="100"
        @input="onInputChange($event, 'installment')"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhập dịch vụ trả góp',
        }"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          {{ suggestion.item.name }}
        </div>
      </vue-autosuggest>
      <b-form-invalid-feedback
        :state="validateInstallment"
        id="input-installment-live-feedback"
      >
        Vui lòng chọn dịch vụ trả góp
      </b-form-invalid-feedback>
      <b-form-group
        v-if="showValid('checkInstallMentLoanStatus')"
        class="mb-0 mt-2"
      >
        <label for="input-intallment">Trạng thái vay:</label>
        <b-form-group>
          <b-form-select
            id="input-style"
            :options="LOAN_STATUS"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="paymentInfo.installApplicationStatus"
          >
            <template v-slot:first>
              <b-form-select-option :value="-1" disabled
                >Chọn trạng thái vay</b-form-select-option
              >
            </template>
          </b-form-select>
        </b-form-group>
      </b-form-group>
      <div class="row" v-if="showValid('checkInstallMentLoan')">
        <div class="col-md-7">
          <p class="textTitlePayment">T/tin khoản vay:</p>
        </div>
        <div
          class="col-md-4 productCode"
          style="cursor: pointer"
          @click="showLoanData('NEW')"
          v-if="paymentInfo.installApplicationStatus === -1"
        >
          <b style="color: blue; font-size: 12px">
            <i class="fas fa-plus"></i>
            &nbsp; Tạo
          </b>
        </div>
        <div
          class="col-md-5 productCode"
          style="cursor: pointer"
          @click="showLoanData('DETAIL')"
          v-else
        >
          <b style="color: blue; font-size: 12px">
            <i class="far fa-eye"></i>
            &nbsp; Xem
          </b>
        </div>
      </div>
    </b-form-group>
    <b-form-group
      v-if="checkAmount('installedMoneyAmount')"
      :disabled="disabled"
    >
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="paymentInfo.installmentCode"
        placeholder="Mã hợp đồng"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      v-if="checkAmount('installedMoneyAmount')"
      :disabled="disabled"
    >
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="paymentInfo.customerIndentifyNo"
        placeholder="Số CMND"
      ></b-form-input>
    </b-form-group>
    <b-row>
      <b-col md="6">
        <b
          :class="{
            textFinal: titleFinal === 'Còn thiếu',
            textExcessCash: titleFinal === 'Tiền thừa',
          }"
        >
          {{ titleFinal }}:
        </b>
      </b-col>
      <b-col md="6">
        <p class="text-right textFinal">
          {{ convertPrice(remainAmount) }}
        </p>
      </b-col>
    </b-row>
    <template v-if="fromOrder">
      <b-row v-if="subtractPointAmount">
        <b-col md="6">
          <b class="text-warning"> Tiêu điểm: </b>
        </b-col>
        <b-col md="6">
          <p class="text-right textFinal">
            {{ convertPrice(subtractPointAmount) }}
          </p>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="6">
          <b class="text-success"> Tổng cộng: </b>
        </b-col>
        <b-col md="6">
          <p class="text-right textFinal">
            {{ convertPrice(finalAmount) }}
          </p>
        </b-col>
      </b-row>
    </template>
    <LoanData
      ref="loan-data-modal"
      :loanApplicationId="paymentInfo.loanApplicationId"
      :totalAmount="finalAmount"
      :mode="loanDataMode"
      :loanApplicationData="loanApplicationData"
      v-on:onCreateSuccess="onCreateLoanSuccess"
    />
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import {
  convertPrice,
  currencyMask,
  removeAccents,
  unMaskPrice,
  getValueByKey,
  cloneDeep,
  makeToastFaile,
} from '@/utils/common';
import { TIME_TRIGGER, LOAN_STATUS, SHINHAN_ID } from '@/utils/constants';
import LoanData from '../order/LoanData.vue';
import Swal from 'sweetalert2';

const payCreditFeeType = {
  CASH: 1,
  TRANSFER: 2,
  CREDIT: 3,
};

export default {
  props: [
    'finalAmount',
    'billData',
    'onSubmiting',
    'fromOrder',
    'action',
    'subtractPointAmount',
    'disabled',
  ],
  data() {
    return {
      mask: currencyMask,
      filteredCashAccOptions: [],
      cashAccOptions: [
        {
          data: [],
        },
      ],
      filteredTransferAccOptions: [],
      transferOptions: [
        {
          data: [],
        },
      ],
      filteredCreditAccOptions: [],
      creditOptions: [
        {
          data: [],
        },
      ],
      listPayCreditFeeType: [
        {
          id: payCreditFeeType.CASH,
          name: 'Tiền mặt',
        },
        {
          id: payCreditFeeType.TRANSFER,
          name: 'Chuyển khoản',
        },
        {
          id: payCreditFeeType.CREDIT,
          name: 'Quẹt thẻ',
        },
      ],
      filteredCreditFeeAccOptions: [],
      creditFeeAccOptions: [
        {
          data: [],
        },
      ],
      showInsProgram: false,
      selectedInstallmentProgram: null,
      filteredOptionsInstallmentProgram: [],
      optionsMonthNo: [],
      installmentAccountantName: '',
      filteredOptionsInstallment: [],
      optionsInstallment: [
        {
          data: [],
        },
      ],
      creditFeeRate: 0,
      paymentInfo: {
        //tiền mặt
        cashAmount: 0,
        cashAccountantName: '',
        cashAccountCode: '',
        cashAccountId: null,
        //chuyển khoản
        transferAmount: 0,
        transferAccountantName: '',
        transferAccountCode: '',
        transferAccountantId: null,
        transferReferenceCode: '',
        //quẹt thẻ
        creditAmount: 0,
        creditAccountantName: '',
        creditAccountCode: '',
        creditAccountantId: null,
        creditCardNo: '',
        creditCardCode: '',
        creditCode: '',
        //phí quẹt thẻ
        creditCardFee: 0,
        creditFeeAccountantName: '',
        creditFeeAcountCode: '',
        creditFeeAccountantId: null,
        payCreditFeeType: 1,
        //trả góp
        installedMoneyAmount: 0,
        installmentCode: '',
        installmentAccountName: '',
        installMoneyAccountId: null,
        customerIndentifyNo: '',
        installmentProgramMonthNo: 0,
        installmentProgramType: 1,
        //shinhan bank
        loanApplicationId: null,
        installApplicationStatus: null,
      },
      paymentType: {
        CASH: 1,
        TRANSFER: 2,
        CREDIT: 3,
        INSTALLMENT: 4,
      },
      LOAN_STATUS: LOAN_STATUS,
      loanDataMode: '',
      loanApplicationData: {
        prepaidAmount: 0,
        loanAmount: 0,
        phone: '',
        email: '',
        fullName: '',
      },
    };
  },
  components: {
    LoanData,
  },
  created() {
    if (this.fromOrder) {
      if (this.action === 'update') {
        const paymentInfo = getValueByKey(this.paymentInfo, this.billData);
        paymentInfo.cashAmount = this.billData.depositAmount;
        paymentInfo.cashAccountantName = this.billData.cashAccountName;

        paymentInfo.creditCardNo = this.billData.creditCardNo;
        paymentInfo.creditCardCode = this.billData.creditCode;

        paymentInfo.creditAccountantName = this.billData.creditAccountName;
        paymentInfo.creditAccountantId = this.billData.creditAccountId;

        paymentInfo.transferAccountantName = this.billData.transferAccountName;
        paymentInfo.transferAccountantId = this.billData.transferAccountId;

        paymentInfo.creditCardFee = this.billData.otherFees;
        paymentInfo.creditFeeAccountantName =
          this.billData.creditFeeAccountName;
        paymentInfo.creditFeeAccountantId = this.billData.creditFeeAcountId;

        paymentInfo.installmentCode = this.billData.installMoneyCode;
        paymentInfo.installMoneyAccountId = this.billData.installMoneyAccountId;
        paymentInfo.installmentAccountName =
          this.billData.installMoneyAccountName;

        paymentInfo.installmentProgramType = 1;
        if (paymentInfo.installmentProgramId) {
          paymentInfo.installmentProgramType = 2;
        }
        this.fetchCashAcc();
        this.paymentInfo = cloneDeep(paymentInfo);
      }
    } else {
      const billPayment = this.billData.paymentInfo;
      this.paymentInfo = cloneDeep(billPayment);
      this.fetchCashAcc();
    }
  },
  mounted() {
    this.getCreditFee();
    this.fetchInstallments();
    this.fetchAccountant();
    this.fetchCreditAcc();
  },
  watch: {
    'billData.storeId': {
      handler: async function (newValue, oldValue) {
        if (this.fromOrder && this.action === 'update') {
          if (!newValue) {
            // nếu xoá thì giữ nguyên
            return;
          }
          const cashId = this.paymentInfo.cashAccountId;
          const transferId = this.paymentInfo.transferAccountId;

          if (newValue !== oldValue && (cashId || transferId)) {
            const text = 'Chỉ chuyển cửa hàng giữ lại thanh toán';
            const alert = await this.showWarningAlert(text);
            if (alert.isConfirmed) {
              return;
            }
          }
        }

        this.paymentInfo.cashAccountantName = '';
        this.paymentInfo.cashAccountCode = '';
        this.paymentInfo.cashAccountId = null;
        this.filteredCashAccOptions = [];
        if (newValue) {
          this.fetchCashAcc();
        }
      },
    },
    paymentInfo: {
      handler: function (newValue) {
        this.handleEmitPayment(newValue);
      },
      deep: true,
    },
  },
  computed: {
    titleFinal() {
      const cashAmount = this.paymentInfo.cashAmount
        ? unMaskPrice(this.paymentInfo.cashAmount)
        : 0;
      const transferAmount = this.paymentInfo.transferAmount
        ? unMaskPrice(this.paymentInfo.transferAmount)
        : 0;
      const creditAmount = this.paymentInfo.creditAmount
        ? unMaskPrice(this.paymentInfo.creditAmount)
        : 0;
      const installemntAmount = this.paymentInfo.installedMoneyAmount
        ? unMaskPrice(this.paymentInfo.installedMoneyAmount)
        : 0;

      const amount =
        this.finalAmount -
        (parseInt(cashAmount) +
          parseInt(transferAmount) +
          parseInt(creditAmount) +
          parseInt(installemntAmount));
      return amount > 0 ? 'Còn thiếu' : 'Tiền thừa';
    },
    remainAmount() {
      const cashAmount = this.paymentInfo.cashAmount
        ? unMaskPrice(this.paymentInfo.cashAmount)
        : 0;
      const transferAmount = this.paymentInfo.transferAmount
        ? unMaskPrice(this.paymentInfo.transferAmount)
        : 0;
      const creditAmount = this.paymentInfo.creditAmount
        ? unMaskPrice(this.paymentInfo.creditAmount)
        : 0;
      const installemntAmount = this.paymentInfo.installedMoneyAmount
        ? unMaskPrice(this.paymentInfo.installedMoneyAmount)
        : 0;

      const result =
        this.finalAmount -
        (parseInt(cashAmount) +
          parseInt(transferAmount) +
          parseInt(creditAmount) +
          parseInt(installemntAmount));
      return Math.abs(result);
    },
    validateCash() {
      if (
        unMaskPrice(this.paymentInfo.cashAmount) > 0 &&
        !this.paymentInfo.cashAccountId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateTransfer() {
      if (
        unMaskPrice(this.paymentInfo.transferAmount) > 0 &&
        !this.paymentInfo.transferAccountantId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateCredit() {
      if (
        unMaskPrice(this.paymentInfo.creditAmount) > 0 &&
        !this.paymentInfo.creditAccountantId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateCreditFee() {
      if (
        unMaskPrice(this.paymentInfo.creditCardFee || 0) > 0 &&
        !this.paymentInfo.creditFeeAccountantId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
    validateInstallment() {
      if (
        unMaskPrice(this.paymentInfo.installedMoneyAmount) > 0 &&
        !this.paymentInfo.installMoneyAccountId &&
        this.onSubmiting
      ) {
        return false;
      }
      return true;
    },
  },
  methods: {
    convertPrice,
    isTextNul(text) {
      if (!text || !text.length) {
        return true;
      }
      return false;
    },
    showValid(type) {
      let check = false;
      switch (type) {
        case 'checkInstallMentLoan': {
          check =
            this.checkInstallmentAmount() &&
            this.paymentInfo.installApplicationStatus &&
            this.billData.id;
          break;
        }
        case 'checkInstallMentLoanStatus': {
          check =
            this.checkInstallmentAmount() &&
            this.paymentInfo.installApplicationStatus;
          break;
        }
        default: {
          makeToastFaile('Phương thức kiểm tra không tồn tại');
          break;
        }
      }
      return check;
    },
    handleEmitPayment(value) {
      if (this.fromOrder) {
        const paymentInfo = cloneDeep(value);
        paymentInfo.depositAmount = paymentInfo.cashAmount
          ? unMaskPrice(paymentInfo.cashAmount)
          : 0;

        paymentInfo.transferAmount = paymentInfo.transferAmount
          ? unMaskPrice(paymentInfo.transferAmount)
          : 0;
        paymentInfo.transferAccountId = paymentInfo.transferAccountantId;

        paymentInfo.creditAmount = paymentInfo.creditAmount
          ? unMaskPrice(paymentInfo.creditAmount)
          : 0;
        paymentInfo.creditAccountId = paymentInfo.creditAccountantId;

        paymentInfo.installedMoneyAmount = paymentInfo.installedMoneyAmount
          ? unMaskPrice(paymentInfo.installedMoneyAmount)
          : 0;
        paymentInfo.installMoneyCode = paymentInfo.installmentCode;

        paymentInfo.otherFees = paymentInfo.creditCardFee
          ? unMaskPrice(paymentInfo.creditCardFee)
          : 0;
        paymentInfo.creditFeeAcountId = paymentInfo.creditFeeAccountantId;

        // paymentInfo.creditCardNo = paymentInfo.creditCardNo;

        paymentInfo.creditCode = paymentInfo.creditCardCode;

        this.$emit('receive-payment', paymentInfo);
      } else {
        //handle bill payment
        const paymentInfo = cloneDeep(value);
        this.$emit('receive-payment', paymentInfo);
      }
    },
    bindingMoney(type) {
      if (this.remainAmount === 0) {
        return;
      }
      let cashAmount = this.paymentInfo.cashAmount
        ? unMaskPrice(this.paymentInfo.cashAmount)
        : 0;
      let transferAmount = this.paymentInfo.transferAmount
        ? unMaskPrice(this.paymentInfo.transferAmount)
        : 0;
      let creditAmount = this.paymentInfo.creditAmount
        ? unMaskPrice(this.paymentInfo.creditAmount)
        : 0;
      let installedMoneyAmount = this.paymentInfo.installedMoneyAmount
        ? unMaskPrice(this.paymentInfo.installedMoneyAmount)
        : 0;

      switch (type) {
        case this.paymentType.CASH:
          cashAmount = 0;
          break;
        case this.paymentType.TRANSFER:
          transferAmount = 0;
          break;
        case this.paymentType.CREDIT: {
          creditAmount = 0;
          this.onCreditAmountChange();
          break;
        }
        case this.paymentType.INSTALLMENT:
          installedMoneyAmount = 0;
          break;
      }
      const totalPay =
        parseInt(cashAmount) +
        parseInt(transferAmount) +
        parseInt(creditAmount) +
        parseInt(installedMoneyAmount);

      switch (type) {
        case this.paymentType.CASH:
          this.paymentInfo.cashAmount = this.finalAmount - totalPay;
          break;
        case this.paymentType.TRANSFER:
          this.paymentInfo.transferAmount = this.finalAmount - totalPay;
          break;
        case this.paymentType.CREDIT: {
          this.paymentInfo.creditAmount = this.finalAmount - totalPay;
          this.onCreditAmountChange();
          break;
        }
        case this.paymentType.INSTALLMENT:
          this.paymentInfo.installedMoneyAmount = this.finalAmount - totalPay;
          break;
      }
    },
    onSelected(option, type) {
      switch (type) {
        case 'cash':
          this.paymentInfo.cashAccountId = option.item.id;
          this.paymentInfo.cashAccountCode = option.item.code;
          this.paymentInfo.cashAccountantName = option.item.name;
          break;
        case 'transfer':
          this.paymentInfo.transferAccountantId = option.item.id;
          this.paymentInfo.transferAccountCode = option.item.code;
          break;
        case 'credit':
          this.paymentInfo.creditAccountantId = option.item.id;
          this.paymentInfo.creditAccountCode = option.item.code;
          break;
        case 'creditFee':
          this.paymentInfo.creditFeeAccountantId = option.item.id;
          this.paymentInfo.creditFeeAcountCode = option.item.code;
          this.paymentInfo.creditFeeAccountantName = option.item.name;
          break;
        case 'installment': {
          this.paymentInfo.installMoneyAccountId = option.item.id;
          this.paymentInfo.installMoneyAccountCode = option.item.code;
          if (this.action === 'update' && option.item.id == SHINHAN_ID) {
            this.paymentInfo.installApplicationStatus = -1;
          } else if (this.action === 'create' && option.item.id == SHINHAN_ID) {
            makeToastFaile(
              'Trả góp Shinhan bank không áp dụng cho tạo đơn hàng mới'
            );
            this.paymentInfo.installApplicationStatus = null;
            this.paymentInfo.installMoneyAccountId = null;
            this.paymentInfo.installmentAccountName = null;
            this.filteredOptionsInstallment = [];
          } else {
            this.paymentInfo.installApplicationStatus = null;
          }
          break;
        }
      }
    },
    onInputChange(text, type) {
      switch (type) {
        case 'cash':
          this.filteredCashAccOptions = this.onFilterOptions(
            this.cashAccOptions,
            text
          );
          if (this.isTextNul(text)) {
            this.paymentInfo.cashAccountId = null;
          }
          break;
        case 'transfer':
          this.filteredTransferAccOptions = this.onFilterOptions(
            this.transferOptions,
            text
          );
          if (this.isTextNul(text)) {
            this.paymentInfo.transferAccountantId = null;
          }
          break;
        case 'credit':
          this.filteredCreditAccOptions = this.onFilterOptions(
            this.creditOptions,
            text
          );
          if (this.isTextNul(text)) {
            this.paymentInfo.creditAccountantId = null;
          }
          break;
        case 'creditFee':
          this.filteredCreditFeeAccOptions = this.onFilterOptions(
            this.creditFeeAccOptions,
            text
          );
          if (this.isTextNul(text)) {
            this.paymentInfo.creditFeeAccountantId = null;
          }
          break;
        case 'installment':
          this.filteredOptionsInstallment = this.onFilterOptions(
            this.optionsInstallment,
            text
          );
          if (this.isTextNul(text)) {
            this.paymentInfo.installMoneyAccountId = null;
          }
          break;
      }
    },
    onFilterOptions(options, text) {
      const filteredData = options[0].data
        .filter((item) => {
          return (
            removeAccents(item.name)
              .toLowerCase()
              .indexOf(removeAccents(text.toLowerCase())) > -1
          );
        })
        .slice(0, this.limit);

      return [
        {
          data: filteredData,
        },
      ];
    },
    checkAmount(key) {
      return unMaskPrice(this.paymentInfo[key] || 0) > 0;
    },
    onCreditFeePayTypeChange(option) {
      if (!option) {
        this.paymentInfo.creditFeeAccountantId = null;
        this.paymentInfo.creditFeeAccountantName = '';
        this.creditFeeAccOptions[0].data = [];
      }

      if (this.paymentInfo.payCreditFeeType === payCreditFeeType.CASH) {
        this.creditFeeAccOptions[0].data = this.cashAccOptions[0].data;
      } else {
        this.creditFeeAccOptions[0].data = this.transferOptions[0].data;
      }
      this.filteredCreditFeeAccOptions = [
        { data: this.creditFeeAccOptions[0].data },
      ];
    },
    onChangeInstallmentProgram() {
      console.log('Change installment');
    },
    onInstallmentSelectedProgram() {},
    onInputInstallmentChangeProgram() {
      console.log('Installment program change');
    },
    showLoanData(mode) {
      this.loanDataMode = mode;
      const depositAmount = this.paymentInfo.cashAmount
        ? unMaskPrice(this.paymentInfo.cashAmount)
        : 0;
      const transferAmount = this.paymentInfo.transferAmount
        ? unMaskPrice(this.paymentInfo.transferAmount)
        : 0;
      const creditAmount = this.paymentInfo.creditAmount
        ? unMaskPrice(this.paymentInfo.creditAmount)
        : 0;
      const prepaidAmount =
        parseInt(depositAmount) +
        parseInt(transferAmount) +
        parseInt(creditAmount);
      this.loanApplicationData = {
        orderId: this.billData.id,
        prepaidAmount: prepaidAmount,
        loanAmount: this.paymentInfo.installedMoneyAmount,
        phone: this.billData.customerMobile,
        email: '',
        fullName: this.billData.customerName,
      };
      setTimeout(() => {
        this.$refs['loan-data-modal'].showModal();
      }, TIME_TRIGGER);
    },
    checkInstallmentAmount() {
      return unMaskPrice(this.paymentInfo.installedMoneyAmount) > 0;
    },
    onCreateLoanSuccess() {},
    fetchCashAcc: async function () {
      if (!this.billData.storeId) {
        return;
      }
      const params = {
        storeId: this.billData.storeId,
        //  tk tien mat
        type: 1,
      };
      ApiService.query('accountants/getByStore', { params }).then(
        (response) => {
          this.cashAccOptions[0].data = [];
          response.data.data.forEach((item) => {
            if (item.level >= 2) {
              const acc = {
                id: item.id,
                name: item.name,
                code: item.code,
              };
              this.cashAccOptions[0].data.push(acc);
            }
          });
          this.filteredCashAccOptions = [{ data: this.cashAccOptions[0].data }];
          if (this.paymentInfo.payCreditFeeType === payCreditFeeType.CASH) {
            this.creditFeeAccOptions[0].data = this.cashAccOptions[0].data;
            this.filteredCreditFeeAccOptions = [
              { data: this.creditFeeAccOptions[0].data },
            ];
          }
        }
      );
    },
    getCreditFee: async function () {
      await ApiService.query('settings/credit-fee').then((res) => {
        this.creditFeeRate = res.data.data;
      });
    },
    onCreditAmountChange() {
      const creditAmount = unMaskPrice(this.paymentInfo.creditAmount);
      if (!creditAmount) {
        this.paymentInfo.creditCardFee = 0;
      } else {
        this.paymentInfo.creditCardFee =
          (creditAmount * this.creditFeeRate) / 100;
      }
    },
    fetchInstallments: async function () {
      const params = {
        page: 1,
        pageSize: 1000,
      };
      await ApiService.query('/installments', { params }).then((response) => {
        this.optionsInstallment[0].data = response.data.data.result;
        this.filteredOptionsInstallment = [
          { data: this.optionsInstallment[0].data },
        ];
      });
    },
    fetchAccountant: async function () {
      const params = {
        //  tk ngan hang
        type: 2,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            const acc = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.transferOptions[0].data.push(acc);
            this.creditOptions[0].data.push(acc);
          }
        });
        this.filteredTransferAccOptions = [
          { data: this.transferOptions[0].data },
        ];
        if (
          this.paymentInfo.payCreditFeeType === payCreditFeeType.TRANSFER ||
          this.paymentInfo.payCreditFeeType === payCreditFeeType.CREDIT
        ) {
          this.creditFeeAccOptions[0].data = this.transferOptions[0].data;
          this.filteredCreditFeeAccOptions = [
            { data: this.creditFeeAccOptions[0].data },
          ];
        }
      });
    },
    fetchCreditAcc: async function () {
      const params = {
        //  tk quet the
        type: 3,
      };
      ApiService.query('accountants/getByType', { params }).then((response) => {
        //this.creditAccOptions[0].data = [];
        response.data.data.forEach((item) => {
          if (item.level >= 2) {
            const acc = {
              id: item.id,
              name: item.name,
              code: item.code,
            };
            this.creditOptions[0].data.push(acc);
          }
        });
        this.filteredCreditAccOptions = [{ data: this.creditOptions[0].data }];
      });
    },
    showWarningAlert: function (text) {
      return Swal.fire({
        title: 'Đơn hàng có thanh toán!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    },
  },
};
</script>

<style lang="scss">
.payment-common {
  .autosuggest__results {
    position: absolute;
    width: 100%;
    max-height: 200px;
    z-index: 999;
    border: 1px solid #e0e0e0;
    overflow-y: scroll;
    overflow-x: hidden;
    background-color: #fff;
  }

  .autosuggest__results-container {
    position: relative;
    width: 100%;
  }

  .autosuggest__input {
    display: block;
    width: 100%;
    height: calc(1.35em + 1.1rem + 2px);
    line-height: 1.35;
    padding: 0.55rem 0.75rem;
    font-weight: 400;
    font-size: 0.925rem;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.28rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .autosuggest__input:focus {
    outline: none !important;
    border: 1px solid #92c5f9;
  }

  .autosuggest__results-item:hover {
    background-color: #ddd;
  }

  .autosuggest__results ul li {
    padding: 8px;
  }

  ::-webkit-scrollbar {
    width: 13px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #d6dee1;
    border-radius: 20px;
    border: 3px solid transparent;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #a8bbbf;
  }
}
</style>
