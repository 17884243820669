<template>
  <b-modal
    ref="modal-edit-order-item"
    size="lg"
    hide-footer
    id="modal-edit-order-item"
  >
    <template #modal-title>
      <h5 v-html="titleExcel" class="mb-0"></h5>
    </template>

    <div class="mb-4">
      <template
        v-if="
          [
            MODAL_TYPE_UPSERT_ORDER.GIFT,
            MODAL_TYPE_UPSERT_ORDER.CHANGE_GIFT,
            MODAL_TYPE_UPSERT_ORDER.CHANGE_MAIN,
          ].includes(modalType)
        "
      >
        <b-form-group label="Chọn sản phẩm quà tặng:">
          <ProductSearch
            :storeId="params.storeId"
            :searchType="1"
            @selected="onSelectedProduct"
            mode="gift"
            :product="product"
          />
        </b-form-group>
      </template>
      <template v-else-if="modalType === MODAL_TYPE_UPSERT_ORDER.ATTACH">
      </template>
      <template v-else-if="modalType === MODAL_TYPE_UPSERT_ORDER.PRODUCT_IMEI">
        <b-row>
          <b-col cols="4">
            <b-form-group label="Chọn imei:">
              <b-input
                placeholder="Nhập imei"
                v-model="apiParams.imeiSearch"
                size="sm"
                autocomplete="off"
                @keyup="onKeyUp"
              ></b-input>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
      <template>
        <b-table
          :items="products"
          :fields="productFields"
          :per-page="perPage"
          :current-page="currentPage"
          bordered
          hover
        >
          <template
            #cell(stt)="data"
            class="justify-content-center"
            v-if="isShowTableFields('stt')"
          >
            {{ data.index + 1 }}
          </template>
          <template
            v-slot:cell(imeiCode)="row"
            v-if="isShowTableFields('status')"
          >
            <span v-html="highlightMatches(row.item.imeiCode)"></span>
          </template>
          <template v-slot:cell(productName)="row">
            <span class="d-block">{{ row.item.productName }}</span>
            <span
              class="d-block"
              v-if="isShowTableFields('imeicode') && row.item.imeiCode"
              style="font-size: 12px; font-style: italic"
              ><b>IMEI</b>:
              <span class="text-warning">{{ row.item.imeiCode }}</span></span
            >
          </template>
          <template
            v-slot:cell(status)="row"
            v-if="isShowTableFields('status')"
          >
            <div class="d-flex justify-content-center">
              <span
                v-text="checkStatus(row.item.status)"
                class="label font-weight-bold label-lg label-inline"
                v-bind:class="{
                  'label-light-warning': row.item.status === 1,
                  'label-light-success': row.item.status === 2,
                  'label-light-danger': row.item.status === 3,
                  'label-light-default': row.item.status === 4,
                }"
              ></span>
            </div>
          </template>
          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <div v-if="isShowTableFields('choose')">
                <b-button
                  v-if="!row.item.added"
                  size="sm"
                  variant="primary"
                  class="btn-add"
                  @click="onSelectedProductImei(row.item)"
                  >Chọn</b-button
                >

                <b-button
                  v-else
                  size="sm"
                  variant="secondary"
                  class="btn-add"
                  @click="onSelectedProductImei(row.item)"
                  >Đã chọn</b-button
                >
              </div>
              <v-icon
                v-if="isShowTableFields('delete')"
                small
                class="text-danger"
                @click="deleteProduct(row.item)"
                v-b-tooltip
                title="Xóa"
                >mdi-delete</v-icon
              >
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col lg="3" md="3" sm="12">
            <b>Tổng số: {{ products.length }} </b>
          </b-col>
          <b-col lg="9" md="9" sm="12">
            <b-pagination
              pills
              v-if="products.length > perPage"
              v-model="currentPage"
              :total-rows="products.length"
              :per-page="perPage"
              aria-controls="my-table"
              align="right"
              size="sm"
            ></b-pagination>
          </b-col>
        </b-row>
      </template>
    </div>

    <b-overlay
      v-if="isShowTableFields('apply')"
      :show="isProcessing"
      rounded
      opacity="0.6"
      class="d-inline-block mr-3"
      @hidden="$refs.button.focus()"
    >
      <template #overlay>
        <div class="d-flex align-items-center">
          <b-spinner variant="light" small type="grow"></b-spinner>
        </div>
      </template>
      <b-button
        :disabled="!products.length"
        variant="primary"
        size="sm"
        @click="onApplyProducts"
      >
        <v-icon small class="text-white" v-b-tooltip>mdi-download</v-icon>
        <strong>Áp dụng</strong>
      </b-button>
    </b-overlay>

    <b-button
      style="width: 80px"
      variant="secondary"
      size="sm"
      @click="$bvModal.hide('modal-edit-order-item')"
    >
      <strong>Hủy</strong>
    </b-button>
  </b-modal>
</template>

<style>
.bootstrap-datetimepicker-widget.dropdown-menu {
  width: 21em;
}
</style>
<style lang="scss" scoped>
#modal-edit-order-item {
  .btn-add {
    width: 60px;
    padding: 3px;
    font-size: 0.85rem;
    font-weight: bolder;
  }
}
</style>
<script>
import { validationMixin } from 'vuelidate';
import { checkPermissions } from '@/utils/saveDataToLocal';
import ProductSearch from '@/view/components/products/ProductSearch';
import { MODAL_TYPE_UPSERT_ORDER } from '@/utils/enum';
import ApiService from '@/core/services/api.service';
import { makeToastFaile, makeToastSuccess, cloneDeep ,convertPrice} from '@/utils/common';
export default {
  props: {
    parent: {
      type: Object,
    },
    mode: {
      type: String,
    },
    params: {
      type: Object,
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      MODAL_TYPE_UPSERT_ORDER,
      modalType: null,
      titleExcel: null,
      isProcessing: false,
      products: [],
      defaultFields: [{ key: 'actions', label: '' }],
      productFields: [],
      product: null,
      apiParams: {
        imeiSearch: null,
      },
      productsTerm: [],
      currentPage: 1,
      perPage: 5,
    };
  },
  components: {
    ProductSearch,
  },
  computed: {},
  created() {
    console.log('this.mode ', this.mode);
    console.log('this.params ', this.params);
  },
  watch: {
    $props: {
      handler() {
        if (this.mode !== this.modalType) {
          this.products = [];
        }
        if (this.parent) {
          const modalType = this.mode;
          switch (modalType) {
            case MODAL_TYPE_UPSERT_ORDER.CHANGE_MAIN: {
              this.titleExcel = `<i class="fas fa-gift fa-lg mr-2 pb-2" style="color:#2E7D32"></i> Đổi sản phẩm chính <span class='font-weight-bolder text-dark'>${this.parent.productName}</span>`;
              break;
            }
            case MODAL_TYPE_UPSERT_ORDER.CHANGE_GIFT: {
              this.titleExcel = `<i class="fas fa-gift fa-lg mr-2 pb-2" style="color:#2E7D32"></i> Đổi quà tặng sản phẩm <span class='font-weight-bolder text-dark'>${this.parent.productName}</span>`;
              break;
            }
            case MODAL_TYPE_UPSERT_ORDER.GIFT: {
              this.titleExcel = `<i class="fas fa-gift fa-lg mr-2 pb-2" style="color:#2E7D32"></i> Thêm quà tặng cho sản phẩm <span class='font-weight-bolder text-dark'>${this.parent.productName}</span>`;
              break;
            }
            case MODAL_TYPE_UPSERT_ORDER.ATTACH: {
              this.titleExcel = `<i class="fas fa-tags fa-lg mr-2 pb-2" style="color:#37474f"></i> Thêm bán kèm cho sản phẩm <span class='font-weight-bolder text-dark'>${this.parent.productName}</span>`;
              break;
            }
            case MODAL_TYPE_UPSERT_ORDER.PRODUCT_IMEI: {
              this.titleExcel = `Imei cho sản phẩm <span class='font-weight-bolder text-dark'>${this.parent.productName}</span>`;
              console.log("this.params imei", cloneDeep(this.params));
              const storeId = this.params.storeId ? this.params.storeId: null;
              this.getProductImei(this.parent, storeId);
              break;
            }
            default:
              break;
          }

          this.productFields = this.getHeaderTableByMode(modalType);
          this.modalType = modalType;
        }
      },
      deep: true,
      immediate: true,
    },
  },
  validations: {},
  methods: {
    checkPermissions,
    convertPrice,
    highlightMatches(text = '') {
      const searchTerm = this.apiParams.imeiSearch
        ? this.apiParams.imeiSearch.trim()
        : '';

      const matchExists = text.toLowerCase().includes(searchTerm.toLowerCase());

      if (!matchExists) return text;

      const regex = new RegExp(searchTerm, 'ig');
      return text.replace(
        regex,
        (matchedText) => `<strong class='text-primary'>${matchedText}</strong>`
      );
    },
    onKeyUp() {
      const searchTerm = this.apiParams.imeiSearch
        ? this.apiParams.imeiSearch.trim()
        : '';

      const filtered = this.productsTerm.filter((productImei) => {
        const imeis = productImei.imeiCode.toString().toLowerCase();

        return imeis.includes(searchTerm.toLowerCase());
      });

      if (filtered.length <= this.perPage) {
        this.currentPage = 1;
      }

      this.products = filtered;
    },
    isShowTableFields(fieldName) {
      let show = false;
      switch (this.modalType) {
        case MODAL_TYPE_UPSERT_ORDER.CHANGE_MAIN:
        case MODAL_TYPE_UPSERT_ORDER.CHANGE_GIFT:
        case MODAL_TYPE_UPSERT_ORDER.GIFT: {
          if (['stt', 'delete', 'apply', 'imeicode'].includes(fieldName)) {
            show = true;
          }
          break;
        }
        case MODAL_TYPE_UPSERT_ORDER.PRODUCT_IMEI: {
          if (['status', 'choose'].includes(fieldName)) {
            show = true;
          }
          break;
        }
      }
      return show;
    },
    getHeaderTableByMode(mode) {
      const header = {
        [MODAL_TYPE_UPSERT_ORDER.GIFT]: [
          {
            key: 'stt',
            label: 'STT',
            sortable: false,
            thStyle: {
              fontWeight: 600,
              color: '#181c32',
              textAlign: 'center',
            },
            tdClass: 'text-center',
          },
          {
            key: 'productName',
            label: 'Sản phẩm',
            sortable: false,
            thStyle: { fontWeight: 600, color: '#181c32' },
          },
          {
            key: 'quantityInStock',
            label: 'Tồn',
            sortable: false,
            thStyle: { fontWeight: 600, color: '#181c32' },
          },
          {
            key: 'sellingPrice',
            label: 'Giá',
            thStyle: { fontWeight: 600, color: '#181c32' },
            tdClass: 'text-right',
            formatter: (value) => {
              return this.convertPrice(value);
            },
          },
          {
            key: 'returnPrice',
            label: 'Giá thu lại',
            thStyle: { fontWeight: 600, color: '#181c32' },
            tdClass: 'text-right',
            formatter: (value) => {
              return this.convertPrice(value);
            },
          },
          ...this.defaultFields,
        ],
        [MODAL_TYPE_UPSERT_ORDER.PRODUCT_IMEI]: [
          {
            key: 'productName',
            label: 'Sản phẩm',
            sortable: false,
            thStyle: { fontWeight: 600, color: '#181c32' },
          },
          {
            key: 'imeiCode',
            label: 'Imei',
          },
          {
            key: 'sellingPrice',
            label: 'Giá bán',
            formatter: (value) => {
              return this.convertPrice(value);
            },
          },
          {
            key: 'status',
            label: 'Trạng thái',
            thStyle: {
              textAlign: 'center',
            },
            tdClass: 'text-center',
          },
          ...this.defaultFields,
        ],
      };

      header[MODAL_TYPE_UPSERT_ORDER.CHANGE_GIFT] = [
        ...header[MODAL_TYPE_UPSERT_ORDER.GIFT],
      ];

      header[MODAL_TYPE_UPSERT_ORDER.CHANGE_MAIN] = [
        ...header[MODAL_TYPE_UPSERT_ORDER.GIFT],
      ];

      return header[mode];
    },
    onSelectedProductImei(productImei) {
      if (productImei.added) {
        makeToastFaile(`Imei ${productImei.imeiCode} đã có trong danh sách`);
        return;
      }

      this.parent.productImei = productImei;
      this.$emit('receives-imei', this.parent);
      this.mode = null;
      makeToastSuccess(`Thêm imei ${productImei.imeiCode} thành công`);
      this.$bvModal.hide('modal-edit-order-item');
    },
    onSelectedProduct(product) {
      if (
        [
          MODAL_TYPE_UPSERT_ORDER.CHANGE_GIFT,
          MODAL_TYPE_UPSERT_ORDER.CHANGE_MAIN,
        ].includes(this.modalType) &&
        this.products.length > 0
      ) {
        makeToastFaile('Đổi sản phẩm thêm tối đa 1 sản phẩm');
        return;
      }
      this.products.push(product);
    },
    onApplyProducts() {
      this.parent.childs = this.products;
      this.$emit('receives', this.parent);
      this.products = [];
      this.$bvModal.hide('modal-edit-order-item');
      makeToastSuccess(`Thêm sản phẩm thành công`);
    },
    deleteProduct(item) {
      const filterProductId = (product) => product.id === item.id;
      const index = this.products.findIndex(filterProductId);

      if (index > -1) {
        this.products.splice(index, 1);
      }
    },
    getProductImei(product, storeId) {
      const params = {
        productId: product.productId,
        storeId: storeId,
      };
      console.log("params imei",params)
      ApiService.query('imeis/get-by-product', {
        params,
      }).then((response) => {
        const { data } = response.data;
        const imeiCodes = this.params.orderItems
          .filter((item) => item.imeiCode)
          .map((item) => item.imeiCode);

        const products = data.map((element) => {
          return {
            ...element,
            productName: product.productName,
            imeiCode: element.imeiNo,
            originalPrice: element.originalPrice || 0,
            sellingPrice: element.sellingPrice || 0,
            status: element.status,
            added: imeiCodes.includes(element.imeiNo),
          };
        });

        this.products = cloneDeep(products);
        this.productsTerm = cloneDeep(products);
      });
    },
    checkStatus: function (item) {
      switch (item) {
        case 1:
          return 'Mới';
        case 2:
          return 'Đã bán';
        case 3:
          return 'Đang vận chuyển';
        case 4:
          return 'Lỗi';
        case 5:
          return 'Đã trả NCC';
        case 6:
          return 'Đang chuyển kho';
        case 9:
          return 'Đang giữ hàng';
        default:
          return '';
      }
    },
  },
};
</script>
