<template>
  <div class="bill-info">
    <KTCodePreview v-bind:title="pageTitle">
      <template v-slot:preview>
        <b-card no-body class="full-width">
          <div>
            <div
              class="wizard wizard-4"
              id="kt_wizard_v4_bill"
              data-wizard-state="step-first"
              data-wizard-clickable="true"
            >
              <!--begin: Wizard Nav-->
              <div class="wizard-nav" style="background-color: #eef0f8">
                <div class="wizard-steps">
                  <div
                    class="wizard-step"
                    data-wizard-type="step"
                    data-wizard-state="current"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Thông tin</div>
                      </div>
                    </div>
                  </div>

                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Lịch sử</div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="wizard-step ml-1"
                    data-wizard-type="step"
                    v-show="mainModel.id"
                  >
                    <div class="wizard-wrapper">
                      <div class="wizard-label">
                        <div class="wizard-title">Tệp đính kèm</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card card-custom card-shadowless rounded-top-0">
                <b-skeleton-wrapper :loading="!renderComponent">
                  <template #loading>
                    <b-card>
                      <b-row>
                        <b-col md="9">
                          <b-skeleton
                            type="input"
                            animation="fade"
                            width="100%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton-table
                            :rows="4"
                            :columns="7"
                            :table-props="{ bordered: true, striped: true }"
                          ></b-skeleton-table>
                          <b-row>
                            <b-col md="4">
                              <b-skeleton
                                animation="fade"
                                width="30%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="50%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="100%"
                                class="mb-4"
                                type="input"
                              ></b-skeleton>
                            </b-col>
                            <b-col md="4">
                              <b-skeleton
                                animation="fade"
                                width="30%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="50%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="30%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="30%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="30%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="100%"
                                class="mb-4"
                                type="input"
                              ></b-skeleton>
                            </b-col>
                            <b-col md="4">
                              <b-skeleton
                                animation="fade"
                                width="30%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="50%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                type="input"
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="100%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="20%"
                                class="mb-4"
                              ></b-skeleton>
                              <b-skeleton
                                animation="fade"
                                width="100%"
                                class="mb-4"
                                type="input"
                              ></b-skeleton>
                            </b-col>
                          </b-row>
                        </b-col>
                        <b-col md="3">
                          <b-skeleton
                            animation="fade"
                            width="100%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="20%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            type="input"
                            animation="fade"
                            width="100%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="20%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="100%"
                            class="mb-4"
                            type="input"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="50%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="95%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="65%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            type="input"
                            animation="fade"
                            width="60%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="85%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            type="input"
                            animation="fade"
                            width="55%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            type="input"
                            animation="fade"
                            width="70%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="20%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="100%"
                            class="mb-4"
                            type="input"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="30%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="100%"
                            class="mb-4"
                            type="input"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="30%"
                            class="mb-4"
                          ></b-skeleton>
                          <b-skeleton
                            animation="fade"
                            width="100%"
                            class="mb-4"
                            type="input"
                          ></b-skeleton>
                        </b-col>
                      </b-row>
                    </b-card>
                  </template>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-xl-12">
                        <div
                          class="py-4 mb-5 full-width"
                          data-wizard-type="step-content"
                          data-wizard-state="current"
                        >
                          <b-form ref="form" lazy-validation class="row">
                            <b-row
                              class="col-md-12 mb-1 m-0 p-0"
                              v-if="mainModel.fromBillNumber"
                            >
                              <div class="alert-header" style="width: 100%">
                                Tạo lại hóa đơn mới từ hóa đơn:
                                <span class="text-primary">{{
                                  mainModel.fromBillNumber
                                }}</span>
                              </div>
                            </b-row>
                            <b-row
                              class="col-md-12 mb-1 m-0 p-0"
                              v-if="mainModel.orderId"
                            >
                              <div class="alert-header" style="width: 100%">
                                Chuyển đơn hàng
                                <span
                                  class="text-primary"
                                  style="cursor: pointer;"
                                  @click="linkToOrder(mainModel.orderId)"
                                  >{{ mainModel.orderId }}</span
                                >
                                sang hóa đơn bán lẻ .
                                <ul
                                  style="
                                    padding-left: 15px;
                                    line-height: 20px;
                                    margin-top: 5px;
                                    list-style: circle;
                                  "
                                >
                                  <li v-show="mainModel.moneyPaid > 0">
                                    Số tiền đã đặt cọc:
                                    <span class="text-primary">
                                      {{ formatMoney(mainModel.moneyPaid) }}
                                    </span>
                                  </li>
                                  <li v-show="mainModel.moneyTransfered > 0">
                                    Số tiền đã chuyển khoản:
                                    <span class="text-primary">
                                      {{
                                        formatMoney(mainModel.moneyTransfered)
                                      }}
                                    </span>
                                  </li>
                                  <li v-show="mainModel.moneyCredit > 0">
                                    Số tiền quẹt thẻ:
                                    <span class="text-primary">
                                      {{ formatMoney(mainModel.moneyCredit) }}
                                    </span>
                                  </li>
                                  <li v-show="mainModel.moneyInstallment > 0">
                                    Số tiền đã trả góp:
                                    <span class="text-primary">
                                      {{
                                        formatMoney(mainModel.moneyInstallment)
                                      }}
                                    </span>
                                  </li>
                                  <li v-show="mainModel.customerShipFee > 0">
                                    Phí ship:
                                    <span class="text-primary">
                                      {{
                                        formatMoney(mainModel.customerShipFee)
                                      }}
                                    </span>
                                  </li>
                                </ul>
                                Mã vận đơn:
                                <span class="text-primary">
                                  {{ mainModel.codeShip }}
                                </span>
                              </div>
                            </b-row>
                            <b-row
                              class="col-md-12 mb-1 m-0 p-0"
                              v-if="
                                !mainModel.id &&
                                !mainModel.orderId &&
                                orderNotComplete
                              "
                            >
                              <div class="alert-header" style="width: 100%">
                                Khách hàng đang có đơn hàng chưa hoàn tất:
                                <span class="text-primary"
                                  >#{{ orderNotComplete.id }}</span
                                >
                                -
                                <span
                                  v-text="orderNotComplete.statusName"
                                  class="text-warning"
                                ></span>
                              </div>
                            </b-row>
                            <b-row class="col-md-12 mb-1 m-0 p-0">
                              <b-col md="9" class="mb-2">
                                <b-row>
                                  <b-col md="3" class="p-0">
                                    <b-form-select
                                      v-model="selectTypeSearch"
                                      :options="listTypeSearch"
                                      size="sm"
                                      value-field="id"
                                      text-field="name"
                                      @change="changePlaceholder"
                                    ></b-form-select>
                                  </b-col>
                                  <b-col md="9" class="p-0">
                                    <vue-autosuggest
                                      v-model="searchProduct"
                                      :suggestions="filteredOptionsProduct"
                                      @selected="onSelectedProduct"
                                      :limit="20"
                                      @input="onInputChangProduct"
                                      :input-props="inputSearchProductProp"
                                      :should-render-suggestions="
                                        (size, loading) =>
                                          size >= 0 &&
                                          !loading &&
                                          searchProduct !== ''
                                      "
                                    >
                                      <div
                                        v-if="selectTypeSearch === 1"
                                        slot-scope="{ suggestion }"
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <div>
                                          <span class="text-primary"
                                            >({{
                                              formatMoney(
                                                suggestion.item.sellingPrice
                                              )
                                            }})
                                          </span>
                                          <span
                                            v-if="
                                              suggestion.item.quantityInStock >
                                                0 &&
                                              suggestion.item.productType !== 4
                                            "
                                            class="text-success"
                                            >(Tồn CTB:
                                            {{
                                              suggestion.item.quantityInStock
                                            }})</span
                                          >
                                          <span
                                            v-if="
                                              suggestion.item.quantityInStock <=
                                                0 &&
                                              suggestion.item.productType !== 4
                                            "
                                            class="text-danger"
                                            >(Tồn CTB:
                                            {{
                                              suggestion.item.quantityInStock
                                            }})</span
                                          >
                                          {{ suggestion.item.productName }}
                                        </div>
                                      </div>
                                      <div
                                        v-else-if="selectTypeSearch === 2"
                                        slot-scope="{ suggestion }"
                                        style="
                                          display: flex;
                                          align-items: center;
                                        "
                                      >
                                        <div>
                                          <span class="text-primary"
                                            >({{
                                              formatMoney(
                                                suggestion.item.sellingPrice
                                              )
                                            }})</span
                                          >
                                          <span
                                            v-if="
                                              suggestion.item.quantityInStock >
                                              0
                                            "
                                            class="text-success"
                                            >(Tồn CTB:
                                            {{
                                              suggestion.item.quantityInStock
                                            }})</span
                                          >
                                          {{ suggestion.item.productName }}-
                                          <span class="text-warning"
                                            >IMEI:
                                            {{ suggestion.item.imeiCode }}</span
                                          >
                                        </div>
                                      </div>
                                    </vue-autosuggest>
                                  </b-col>
                                </b-row>
                              </b-col>
                              <b-col md="3" class="mb-3 pr-0">
                                <SearchStore
                                  :onSubmiting="onSubmiting"
                                  :storeId="mainModel.storeId"
                                  :storeName="mainModel.storeName"
                                  v-on:onStoreSelected="onStoreSelected"
                                  :disable="
                                    !!mainModel.id || mainModel.storeDisable
                                  "
                                  v-if="renderComponent"
                                />
                              </b-col>
                            </b-row>
                            <b-row class="mb-3 m-0 p-0">
                              <b-col md="9" class="mb-3">
                                <b-row>
                                  <table
                                    class="
                                      table
                                      table-bordered
                                      table-vertical-center
                                      table-hover
                                    "
                                  >
                                    <thead>
                                      <tr>
                                        <th
                                          class="headerTable"
                                          style="textalign: center"
                                        >
                                          STT
                                        </th>
                                        <th class="name headerTable">
                                          Sản phẩm
                                        </th>
                                        <th class="value headerTable">Tồn</th>
                                        <th class="value headerTable">SL</th>
                                        <th class="code headerTable">Giá</th>
                                        <th class="code headerTable">
                                          Thành tiền
                                        </th>
                                        <th class="code headerTable">
                                          Chiết khấu
                                        </th>
                                        <th class="code headerTable">VAT</th>
                                        <th v-if="true"></th>
                                      </tr>
                                    </thead>

                                    <tbody
                                      v-for="item in listBillItem"
                                      :key="item.id"
                                    >
                                      <BillItem
                                        v-bind:productItem="item"
                                        v-bind:editable="mainModel.editable"
                                        v-bind:editIMEI="
                                          !mainModel.id &&
                                          !mainModel.fromBillNumber
                                        "
                                        v-on:update="updateItem"
                                        v-on:deleteItem="deleteBillItem"
                                        v-on:checkValidImei="checkValidImei"
                                        v-on:addProductBonus="showGiftModal"
                                        v-on:addProductPromotion="
                                          showAttachModal
                                        "
                                        v-on:addProductWarranty="
                                          showWarrantyModal
                                        "
                                        v-on:changeProductBonus="
                                          showChangeGiftModal
                                        "
                                        v-on:addNote="showNoteModal"
                                        v-on:linkToListImei="showListImeiModal"
                                        v-on:viewInventory="viewInventory"
                                        v-on:addWarranty="linkToWarranty"
                                      />
                                    </tbody>
                                    <tfoot>
                                      <tr>
                                        <th
                                          style="text-align: right"
                                          id="total"
                                          colspan="5"
                                        >
                                          Tổng:
                                        </th>
                                        <td
                                          style="
                                            text-align: right;
                                            padding: 0px 20px;
                                          "
                                        >
                                          {{ formatMoney(totalAmount) }}
                                        </td>
                                        <td
                                          style="
                                            text-align: right;
                                            padding: 0px 20px;
                                          "
                                        ></td>
                                        <td
                                          style="
                                            text-align: right;
                                            padding: 0px 20px;
                                          "
                                        ></td>
                                        <td></td>
                                      </tr>
                                    </tfoot>
                                  </table>
                                </b-row>
                                <b-row style="margin-left: -25px">
                                  <b-col md="4">
                                    <CustomerInfo
                                      :onSubmiting="onSubmiting"
                                      :billData="mainModel"
                                      :disabled="!mainModel.editable"
                                      v-on:onUpdate="onUpdateCustomer"
                                      v-if="renderComponent"
                                      v-on:fetch-customer-transaction="
                                        fetchCustomerCransaction
                                      "
                                    />
                                    <b-form-group
                                      :disabled="!mainModel.editable"
                                    >
                                      <label>Nguồn khách hàng:</label>
                                      <b-form-select
                                        v-model="mainModel.orderSourceId"
                                        :options="listCustomerSource"
                                        size="sm"
                                        value-field="id"
                                        text-field="name"
                                        class="select-style"
                                      >
                                        <template v-slot:first>
                                          <b-form-select-option
                                            :value="null"
                                            disabled
                                            >Chọn nguồn khách
                                            hàng:</b-form-select-option
                                          >
                                        </template>
                                      </b-form-select>
                                    </b-form-group>
                                  </b-col>
                                  <b-col md="4">
                                    <EmployeeInfo
                                      :storeId="mainModel.storeId"
                                      :billDataEmployeeInfo="
                                        mainModel.billEmployeeInfo
                                      "
                                      :disabled="!mainModel.editable"
                                    />
                                  </b-col>
                                  <b-col md="4">
                                    <WarrantyCustomerInfo
                                      :billData="mainModel"
                                      :disabled="!mainModel.editable"
                                      v-if="renderComponent"
                                    />
                                    <BillVatInfo
                                      :billData="mainModel"
                                      :disabled="!mainModel.editable"
                                      v-if="renderComponent"
                                    />
                                  </b-col>
                                </b-row>
                                <BillNote
                                  :billNote="mainModel.billNote"
                                  :disabled="!mainModel.editable"
                                  v-if="renderComponent"
                                />
                              </b-col>
                              <b-col md="3" class="mb-3 pr-0">
                                <b-form-group :disabled="!mainModel.editable">
                                  <label class="lable-form">Chiết khấu:</label>
                                  <b-row>
                                    <b-col class="pr-0" cols="3">
                                      <b-form-select
                                        class="select-style"
                                        v-model="mainModel.discountType"
                                        :options="listAmountType"
                                        size="sm"
                                        value-field="id"
                                        text-field="name"
                                      >
                                      </b-form-select>
                                    </b-col>
                                    <b-col class="pl-0" cols="9">
                                      <b-form-input
                                        class="input-style text-right"
                                        size="sm"
                                        v-model="mainModel.discountAmount"
                                        v-mask="mask"
                                        placeholder="Chiết khấu"
                                      ></b-form-input>
                                    </b-col>
                                  </b-row>
                                </b-form-group>
                                <b-form-group :disabled="!mainModel.editable">
                                  <label>Mã coupon:</label>
                                  <b-input-group>
                                    <b-form-input
                                      class="input-style"
                                      size="sm"
                                      type="text"
                                      v-model="mainModel.couponCode"
                                      placeholder="Nhập mã coupon"
                                      @change="changeCouponCode()"
                                      :state="stateCouponCode"
                                    ></b-form-input>
                                    <b-input-group-append>
                                      <b-button
                                        style="width: 40px"
                                        variant="secondary"
                                        size="sm"
                                        @click="checkCoupon()"
                                      >
                                        <i class="fas fa-sync"></i>
                                      </b-button>
                                    </b-input-group-append>
                                  </b-input-group>
                                </b-form-group>
                                <div
                                  v-if="mainModel.customerModel.totalPoint > 0"
                                >
                                  <b-form-group :disabled="!mainModel.editable">
                                    <label>Tiêu điểm tích lũy:</label>
                                    <b-input-group>
                                      <b-form-input
                                        class="input-style text-right"
                                        size="sm"
                                        type="text"
                                        v-model="mainModel.subtractPoint"
                                        placeholder="Nhập số điểm"
                                        v-mask="mask"
                                        @change="onChangeSubtractPoint()"
                                      ></b-form-input>
                                      <b-input-group-append>
                                        <b-button
                                          style="width: 40px"
                                          variant="secondary"
                                          size="sm"
                                          @click="getOTP()"
                                        >
                                          <i class="fas fa-mobile-alt"></i>
                                        </b-button>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>
                                  <b-form-group :disabled="!mainModel.editable">
                                    <label>Mã OTP:</label>
                                    <b-input-group>
                                      <b-form-input
                                        class="input-style text-right"
                                        size="sm"
                                        type="text"
                                        v-model="mainModel.otpCode"
                                        placeholder="Nhập mã OTP"
                                        :state="stateOTP"
                                      ></b-form-input>
                                      <b-input-group-append>
                                        <b-button
                                          style="width: 40px"
                                          variant="secondary"
                                          size="sm"
                                          @click="checkOTP()"
                                        >
                                          <i class="fas fa-sync"></i>
                                        </b-button>
                                      </b-input-group-append>
                                    </b-input-group>
                                  </b-form-group>
                                </div>
                                <b-row>
                                  <b-col>
                                    <b-checkbox
                                      v-model="mainModel.isCountPoint"
                                      class="mt-2 mb-2"
                                      >Tự động tích điểm</b-checkbox
                                    >
                                  </b-col>
                                </b-row>
                                <div class="row-center-vertical">
                                  <b-col md="6" class="no-side">
                                    <p class="textPayment">Thanh toán</p>
                                  </b-col>
                                  <b-col md="6" class="no-side">
                                    <p
                                      class="textPoint text-right"
                                      v-if="mainModel.isCountPoint"
                                    >
                                      {{ addPoint }} điểm
                                    </p>
                                  </b-col>
                                </div>
                                <b-row>
                                  <b-col md="6">
                                    <p class="textTitlePayment">Tổng tiền:</p>
                                  </b-col>
                                  <b-col md="6" v-if="totalAmount > 0">
                                    <p class="text-right textPayment">
                                      {{ formatMoney(totalAmount) }}
                                    </p>
                                  </b-col>
                                  <b-col md="6" v-else>
                                    <p class="text-right textPayment">
                                      {{ 0 }}
                                    </p>
                                  </b-col>
                                </b-row>
                                <b-row v-if="mainModel.totalAmountPoint > 0">
                                  <b-col md="6">
                                    <p class="textTitlePayment">Tiêu điểm:</p>
                                  </b-col>
                                  <b-col md="6">
                                    <p class="text-right textPayment">
                                      {{
                                        formatMoney(mainModel.totalAmountPoint)
                                      }}
                                    </p>
                                  </b-col>
                                </b-row>
                                <b-row v-if="totalAmountReturn > 0">
                                  <b-col md="6">
                                    <p class="textTitlePayment">
                                      Tiền thu lại:
                                    </p>
                                  </b-col>
                                  <b-col md="6">
                                    <p class="text-right textPayment">
                                      {{ formatMoney(totalAmountReturn) }}
                                    </p>
                                  </b-col>
                                </b-row>
                                <b-row v-if="totalPaid > 0">
                                  <b-col md="6">
                                    <p class="textTitlePayment">
                                      Khách đã đưa:
                                    </p>
                                  </b-col>
                                  <b-col md="6">
                                    <p class="text-right textPayment">
                                      {{ formatMoney(totalPaid) }}
                                    </p>
                                  </b-col>
                                </b-row>
                                <b-row>
                                  <b-col md="6">
                                    <p class="textTitlePayment">
                                      Khách cần trả:
                                    </p>
                                  </b-col>
                                  <b-col md="6" v-if="finalAmount > 0">
                                    <p class="text-right textPayment">
                                      {{ formatMoney(finalAmount) }}
                                    </p>
                                  </b-col>
                                  <b-col md="6" v-else>
                                    <p class="text-right textPayment">
                                      {{ 0 }}
                                    </p>
                                  </b-col>
                                </b-row>
                                <BillPayment
                                  :finalAmount="finalAmount"
                                  :billData="mainModel"
                                  :onSubmiting="onPaymentSubmiting"
                                  :disabled="!mainModel.editable"
                                  v-on:onUpdate="onUpdatePayment"
                                  v-if="renderComponent"
                                  v-on:receive-payment="onUpdatePayment"
                                />
                              </b-col>
                            </b-row>
                            <b-row
                              v-if="mainModel.id"
                              class="col-md-12 mb-1 m-0 p-0"
                            >
                              <b-container
                                class="p-0"
                                v-if="checkPermission('TRANSACTION_VIEW')"
                              >
                                <hr
                                  class="hr-text"
                                  data-content="Bút Toán"
                                  style="font-weight: 600"
                                />
                                <b-row>
                                  <b-col md="12" lg="12" sm="12">
                                    <BillTransactions
                                      :billNumber="$route.query.id"
                                    />
                                  </b-col>
                                </b-row>
                              </b-container>
                              <b-container
                                class="p-0"
                                v-if="checkPermission('STOCK_VIEW')"
                              >
                                <hr
                                  class="hr-text"
                                  data-content="Phiếu bảo hành"
                                  style="font-weight: 600"
                                />
                                <b-row>
                                  <b-col md="12" lg="12" sm="12">
                                    <WarrantyTransaction
                                      :billNumber="$route.query.id"
                                    />
                                  </b-col>
                                </b-row>
                              </b-container>
                            </b-row>
                            <b-row>
                              <b-col>
                                <b-button
                                  style="
                                    fontweight: 600;
                                    width: 70px;
                                    margin-right: 10px;
                                  "
                                  variant="primary"
                                  size="sm"
                                  @click="onSubmit('save')"
                                  :disabled="onSubmiting"
                                  v-if="mainModel.editable"
                                  >Lưu</b-button
                                >
                                <b-button
                                  style="
                                    margin-right: 10px;
                                    fontweight: 600;
                                    width: 100px;
                                  "
                                  variant="warning"
                                  size="sm"
                                  @click="onSubmit('save-print')"
                                  :disabled="onSubmiting"
                                  v-if="mainModel.editable && mainModel.id"
                                >
                                  Lưu và in
                                </b-button>
                                <router-link to="/bills" tag="button">
                                  <b-button
                                    tabindex="16"
                                    style="font-weight: 600; width: 70px"
                                    variant="secondary"
                                    size="sm"
                                    >Hủy</b-button
                                  >
                                </router-link>
                              </b-col>
                            </b-row>
                          </b-form>
                        </div>

                        <div
                          class="py-4 mb-5 full-width"
                          data-wizard-type="step-content"
                        >
                          <b-container class="bv-example-row">
                            <b-table
                              :fields="historyFields"
                              :items="histories"
                              class="table-bordered table-hover col-md-6"
                            >
                              <template v-slot:cell(updatedBy)="row">
                                <p>{{ row.item.updatedBy }}</p>
                                <p>{{ row.item.updatedAt }}</p>
                              </template>
                              <template v-slot:cell(action)="row">
                                <p>
                                  {{
                                    row.item.action === 1
                                      ? 'Sửa hóa đơn'
                                      : 'Xóa hóa đơn'
                                  }}
                                </p>
                              </template>
                              <template v-slot:cell(detail)="row">
                                <div
                                  class="orderCode"
                                  @click="viewHistoryDetail(row.item)"
                                  style="cursor: pointer; color: #3699ff"
                                >
                                  <span v-text="row.item.id"></span>
                                </div>
                              </template>
                            </b-table>
                          </b-container>
                        </div>

                        <div
                          class="py-4 mb-5 full-width"
                          data-wizard-type="step-content"
                        >
                          <div class="upload-file col-12">
                            <vue-easy-lightbox
                              escDisabled
                              moveDisabled
                              :visible="previewFile.visible"
                              :imgs="previewFile.imgs"
                              :index="previewFile.index"
                              @hide="handleHidePreviewFile"
                            ></vue-easy-lightbox>

                            <vue-easy-lightbox
                              escDisabled
                              moveDisabled
                              :visible="previewAttachment.visible"
                              :imgs="previewAttachment.imgs"
                              :index="previewAttachment.index"
                              @hide="handleHidePreviewAttachment"
                            ></vue-easy-lightbox>

                            <div>
                              <b-col
                                class="preview-img-container mb-4"
                                v-if="isLoadAttachmentSuccess"
                              >
                                <div>
                                  <!--File Previews-->
                                  <div class="d-flex">
                                    <div
                                      v-for="(item, index) in attachments"
                                      :key="index"
                                      class="img-container align-items-start"
                                    >
                                      <img
                                        v-if="typecheck(item.fileName)"
                                        :src="item.url"
                                        class="preview-img"
                                        width="80"
                                        height="100"
                                        :alt="item.originalName"
                                        @click="
                                          showMultiplePreViewAttachment(index)
                                        "
                                      />
                                      <img
                                        v-else
                                        src="../../../assets/google-docs.svg"
                                        class="preview-img"
                                        width="80"
                                        height="100"
                                        :alt="item.originalName"
                                        @click="
                                          showMultiplePreViewAttachment(index)
                                        "
                                      />
                                      <i
                                        class="
                                          fas
                                          fa-times-circle
                                          text-danger
                                          close
                                        "
                                        @click="deleteAttachment(item.id)"
                                      >
                                      </i>
                                      <a
                                        :download="item.fileName"
                                        :href="item.url"
                                        ><i
                                          class="
                                            fas
                                            fa-arrow-circle-down
                                            text-warning
                                            remove
                                          "
                                        >
                                        </i
                                      ></a>
                                    </div>
                                  </div>
                                </div>
                              </b-col>

                              <!--UPLOAD-->
                              <form
                                enctype="multipart/form-data"
                                novalidate
                                class="mb-4"
                              >
                                <b-button
                                  size="sm"
                                  variant="secondary"
                                  @click="openFileUpload()"
                                  class="font-weight-bolder mr-2"
                                >
                                  <i class="fas fa-paperclip"></i>
                                  Đính kèm tệp
                                </b-button>
                                <b-button
                                  size="sm"
                                  variant="warning"
                                  @click="submitFileUpload()"
                                  class="font-weight-bolder"
                                  v-if="uploadedFiles.length"
                                >
                                  <i class="fas fa-upload"></i>
                                  Tải tệp lên
                                </b-button>
                                <b-form-file
                                  v-model="fileAttach.files"
                                  ref="upload-files"
                                  id="upload-files"
                                  class="mb-2 d-none input-file"
                                  multiple
                                  :name="uploadFieldName"
                                  :disabled="isSaving"
                                  v-on:change="
                                    filesChange(
                                      $event.target.name,
                                      $event.target.files
                                    );
                                    fileCount = $event.target.files.length;
                                  "
                                />

                                <p v-if="isSaving">
                                  Đang tải {{ fileCount }} tệp...
                                </p>
                                <div v-if="isFailed" class="d-flex">
                                  <small class="text-danger"
                                    >Tải tệp thất bại.</small
                                  >
                                  <small>
                                    <a
                                      href="javascript:void(0)"
                                      @click="reset()"
                                      >Thử lại</a
                                    >
                                  </small>
                                  <pre>{{ uploadError }}</pre>
                                </div>
                              </form>

                              <b-col
                                class="preview-img-container mb-4"
                                v-if="isSuccess"
                              >
                                <div>
                                  <!--File Previews-->
                                  <div class="d-flex">
                                    <div
                                      v-for="(item, index) in uploadedFiles"
                                      :key="index"
                                      class="img-container align-items-start"
                                    >
                                      <img
                                        v-if="typecheck(item.fileName)"
                                        :src="item.url"
                                        class="preview-img"
                                        width="80"
                                        height="100"
                                        :alt="item.originalName"
                                        @click="showMultiplePreViewFile(index)"
                                      />
                                      <img
                                        v-else
                                        src="../../../assets/google-docs.svg"
                                        class="preview-img"
                                        width="80"
                                        height="100"
                                        :alt="item.originalName"
                                        @click="showMultiplePreViewFile(index)"
                                      />

                                      <i
                                        class="
                                          fas
                                          fa-times-circle
                                          text-danger
                                          close
                                        "
                                        @click="
                                          removeFile(
                                            fileCount,
                                            uploadedFiles.indexOf(item)
                                          )
                                        "
                                      >
                                      </i>
                                    </div>
                                  </div>
                                </div>
                              </b-col>
                              <!--SUCCESS-->
                              <p v-if="isSuccess">
                                <b>Tổng số {{ uploadedFiles.length }} tệp</b>
                              </p>
                              <!--FAILED-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-skeleton-wrapper>
              </div>
            </div>
          </div>
          <!--end: Wizard Nav -->
        </b-card>
        <BillGiftModal
          ref="bill-gift-modal"
          :billItemId="selectedBillItem.billItemId"
          :productName="selectedBillItem.productName"
          :storeId="mainModel.storeId"
          v-on:addGift="addGift"
        />
        <BillAttachModal
          ref="bill-attach-modal"
          :billItemId="selectedBillItem.billItemId"
          :productName="selectedBillItem.productName"
          :storeId="mainModel.storeId"
          :attachItems="selectedBillItem.attachItems"
          v-on:addAttach="addAttach"
        />
        <BillWarrantyModal
          ref="bill-warranty-modal"
          :productName="selectedBillItem.productName"
          :storeId="mainModel.storeId"
          :productId="selectedBillItem.productId"
          v-on:addWarranty="addWarranty"
        />
        <ChangeGiftModal
          ref="change-gift-modal"
          :productId="selectedBillItem.productId"
          :listProductVariant="selectedBillItem.listProductVariant"
          v-on:changeGift="onChangeGift"
        />
        <BillNoteModal
          ref="bill-note-modal"
          :productName="selectedBillItem.productName"
          :note="selectedBillItem.note"
          v-on:addNote="onChangeNote"
        />
        <ImeiListByProduct
          ref="list-imei-modal"
          :billItemId="selectedBillItem.billItemId"
          :productName="selectedBillItem.productName"
          :storeId="mainModel.storeId"
          :productId="selectedBillItem.productId"
          v-on:applyImei="applyImei"
        />
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import ApiService from '@/core/services/api.service';
import JwtService from '@/core/services/jwt.service';
import Swal from 'sweetalert2';
import {
  convertPrice,
  makeToastFaile,
  currencyMask,
  unMaskPrice,
  makeToastSuccess,
} from './../../../utils/common';
import decounce from 'debounce';
import { v4 } from 'uuid';
import { required } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import { TIME_TRIGGER } from '../../../utils/constants';
import SearchStore from '../../components/stores/SearchStore.vue';
import CustomerInfo from '../../components/customers/CustomerInfo.vue';
import EmployeeInfo from '../../components/bills/EmployeeInfo.vue';
import WarrantyCustomerInfo from '../../components/bills/WarrantyCustomerInfo.vue';
import BillVatInfo from '../../components/bills/BillVatInfo.vue';
import BillPayment from '../../components/bills/BillPayment.vue';
import BillNote from '../../components/bills/BillNote.vue';
import BillGiftModal from '../../components/bills/BillGiftModal';
import BillAttachModal from '../../components/bills/BillAttachModal';
import BillWarrantyModal from '../../components/bills/BillWarrantyModal';
import ChangeGiftModal from '../../components/bills/ChangeGiftModal';
import BillNoteModal from '../../components/bills/BillNoteModal';
import ImeiListByProduct from '../../components/bills/ImeiListByProduct';
import BillItem from '../../components/BillItem.vue';
import {
  AMOUNT_TYPE,
  BILL_ITEM_TYPE,
  PRODUCT_SEARCH_TYPE,
  PRODUCT_TYPE,
  UPLOAD_ENTITY,
} from '../../../utils/enum';

import { sortBy } from 'lodash';
import BillTransactions from '../../components/BillTransactions.vue';
import WarrantyTransaction from '../../components/WarrantyTransaction.vue';
import localData from '../../../utils/saveDataToLocal';

import VueEasyLightbox from 'vue-easy-lightbox';
import moment from 'moment';

import axios from 'axios';
import { cmdUrl } from './../../../utils/apiUrl';
import { cloneDeep } from 'lodash';

const STATUS_INITIAL = 0,
  STATUS_SAVING = 1,
  STATUS_SUCCESS = 2,
  STATUS_FAILED = 3;

export default {
  mixins: [validationMixin],
  data() {
    return {
      listTypeSearch: [
        {
          id: PRODUCT_SEARCH_TYPE.PRODUCT_NAME,
          name: 'Tìm sản phẩm',
        },
        {
          id: PRODUCT_SEARCH_TYPE.IMEI_CODE,
          name: 'Tìm sản phẩm IMEI',
        },
      ],
      selectTypeSearch: 1,
      filteredOptionsProduct: [],
      optionsProduct: [
        {
          data: [],
        },
      ],
      searchProduct: '',
      isSearching: false,
      filteredOptionsStore: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      searchStore: '',
      inputSearchProductProp: {
        class: 'autosuggest__input',
        placeholder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
        style: 'border-radius:0px!important',
        disabled: true,
      },
      mainModel: {
        id: null,
        billNumber: null,
        storeId: null,
        storeName: '',
        discountType: 1,
        discountAmount: 0,
        couponCode: '',
        isCountPoint: true,
        subtractPoint: 0,
        otpCode: '',
        isSubtractPointValidated: false,
        moneyTransfered: 0,
        moneyPaid: 0,
        moneyCredit: 0,
        moneyInstallment: 0,
        totalAmountPoint: 0,
        customerShipFee: 0,
        codeShip: '',
        customerModel: {
          customerId: null,
          customerMobile: '',
          customerName: '',
          customerLastName: '',
          customerAddress: '',
          customerCity: null,
          customerDistrict: null,
          customerWard: null,
          totalPoint: 0,
        },
        warrantyCustomerModel: {
          customerId: null,
          customerMobile: '',
          customerName: '',
          customerLastName: '',
        },
        billEmployeeInfo: {
          saleId: null,
          saleName: '',
          technicalId: null,
          technicalName: '',
          cashierId: null,
          cashierName: '',
          accessoryStaffId: null,
          accessoryStaffName: '',
          managerId: null,
          managerName: '',
          assistantId: null,
          assistantName: '',
          customerReceptionId: null,
          customerReceptionName: '',
          shipperId: null,
          shipperName: '',
        },
        companyBill: false,
        vatInvoice: {
          vatCompanyType: 1,
          taxCode: '',
          companyAddress: '',
          companyName: '',
          email: '',
        },
        paymentInfo: {
          //tiền mặt
          cashAmount: 0,
          cashAccountantName: '',
          cashAccountId: null,
          //chuyển khoản
          transferAmount: 0,
          transferAccountantName: '',
          transferAccountantId: null,
          transferReferenceCode: '',
          //quẹt thẻ
          creditAmount: 0,
          creditAccountantName: '',
          creditAccountantId: null,
          creditCode: '',
          creditCardNo: '',
          //phí quẹt thẻ
          creditCardFee: 0,
          creditFeeAccountantName: '',
          creditFeeAccountantId: null,
          payCreditFeeType: 1,
          //trả góp
          installedMoneyAmount: 0,
          installmentCode: '',
          installmentAccountName: '',
          installMoneyAccountId: null,
          customerIndentifyNo: '',
          installmentProgramMonthNo: 0,
        },
        billNote: {
          customerNote: '',
          saleNote: '',
          warrantyNote: '',
        },
        orderSourceId: null,
        editable: true,
        fromBillNumber: null,
        storeDisable: false,
      },
      listBillItem: [],
      listAmountType: [
        {
          id: 1,
          name: '$',
        },
        {
          id: 2,
          name: '%',
        },
      ],
      mask: currencyMask,
      stateCouponCode: null,
      stateOTP: null,
      isValidCoupon: false,
      validCouponCode: '',
      selectedBillItem: {
        billItemId: null,
        productName: '',
        attachItems: [],
        listProductVariant: [],
        note: '',
      },
      isValidImei: true,
      onSubmiting: false,
      pointSetting: {
        rate: 0,
        allowRoundToDown: false,
      },
      listCustomerSource: [],
      couponValue: 0,
      fileAttach: {
        files: [],
        fileModels: [],
      },
      uploadedFiles: [],
      fileCount: 0,
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'photos',
      hashName: null,
      previewFile: {
        visible: false,
        imgs: '',
        index: 0,
      },
      previewAttachment: {
        visible: false,
        imgs: '',
        index: 0,
      },
      attachments: null,
      isLoadAttachmentSuccess: false,
      histories: [],
      historyFields: [
        {
          key: 'updatedBy',
          label: 'Người tạo',
          thStyle: { textAlign: 'center' },
        },
        {
          key: 'action',
          label: 'Hành động',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
        },
        {
          key: 'detail',
          label: 'Chi tiết',
          sortable: false,
          thStyle: { textAlign: 'center', color: 'rgb(24, 28, 50)' },
        },
      ],
      prevSubtractPoint: 0,
      prevCouponCode: '',
      renderComponent: false,
      pageTitle: 'Thông tin hóa đơn',
      onPaymentSubmiting: false,
      orderNotComplete: null,
    };
  },
  validations: {
    searchStore: {
      required,
    },
  },
  components: {
    KTCodePreview,
    SearchStore,
    BillItem,
    CustomerInfo,
    EmployeeInfo,
    WarrantyCustomerInfo,
    BillVatInfo,
    BillPayment,
    BillNote,
    BillGiftModal,
    BillAttachModal,
    BillWarrantyModal,
    ChangeGiftModal,
    BillNoteModal,
    ImeiListByProduct,
    BillTransactions,
    WarrantyTransaction,
    VueEasyLightbox,
  },
  computed: {
    totalAmount() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (element.billItemType === BILL_ITEM_TYPE.PRODUCT) {
          const totalPrice =
            unMaskPrice(element.price) * unMaskPrice(element.quantity);
          // Check discount type
          if (element.discountType == AMOUNT_TYPE.PERCENT) {
            const discountAmount = totalPrice * element.discount;
            total += totalPrice - (discountAmount / 100).toFixed(0);
          } else {
            total += totalPrice - unMaskPrice(element.discount);
          }
        } else if (
          element.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS &&
          element.checked === false
        ) {
          total -=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        } else if (
          element.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION &&
          element.checked === true
        ) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      const discountAmount = this.mainModel.discountAmount
        ? unMaskPrice(this.mainModel.discountAmount)
        : 0;
      if (this.mainModel.discountType == AMOUNT_TYPE.MONEY) {
        total = total - discountAmount;
      } else {
        total = total - (total * discountAmount) / 100;
      }
      return total;
    },
    totalAmountReturn() {
      let total = 0;
      for (let index = 0; index < this.listBillItem.length; index++) {
        const element = this.listBillItem[index];
        if (
          element.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS &&
          element.checked === false
        ) {
          total +=
            unMaskPrice(element.returnPrice) * unMaskPrice(element.quantity);
        }
      }
      return total;
    },
    addPoint() {
      if (!this.pointSetting.rate) {
        return 0;
      }

      if (this.pointSetting.allowRoundToDown) {
        return this.totalAmount
          ? parseInt(this.totalAmount / this.pointSetting.rate)
          : 0;
      }

      return this.totalAmount
        ? Math.ceil(this.totalAmount / this.pointSetting.rate)
        : 0;
    },
    finalAmount() {
      if (!this.totalAmount) {
        return 0;
      }

      return (
        this.totalAmount -
        this.mainModel.moneyTransfered -
        this.mainModel.moneyPaid -
        this.mainModel.moneyCredit -
        this.mainModel.moneyInstallment -
        this.mainModel.totalAmountPoint +
        this.mainModel.customerShipFee
      );
    },
    totalPaid() {
      return (
        this.mainModel.moneyPaid +
        this.mainModel.moneyTransfered +
        this.mainModel.moneyCredit +
        this.mainModel.moneyInstallment
      );
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  async mounted() {
    this.reset();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Hóa đơn bán lẻ', route: '/bills' },
      { title: 'Danh sách hóa đơn bán lẻ', route: '/bills' },
      { title: 'Thêm mới hóa đơn bán lẻ' },
    ]);
    this.getPointRate();
    this.getListOrderSource();
    this.mainModel.orderId = this.$route.query.fromOrderId
      ? this.$route.query.fromOrderId
      : null;
    this.mainModel.billNumber = this.$route.query.id
      ? this.$route.query.id
      : null;
    if (this.mainModel.billNumber) {
      if (this.$route.name === 'add-bill') {
        this.mainModel.fromBillNumber = this.$route.query.id;
      }
      this.getBillById();
      this.fetchBillHistory();
      this.loadAttachments();
    } else {
      if (this.mainModel.orderId) {
        this.getOrderById();
      } else {
        this.renderComponent = true;
      }
      this.fetchStoreByUser();
    }

    // Initialize form wizard
    const hashName = this.$route.hash ? this.$route.hash.split('#')[1] : '';

    this.hashName = hashName === 'attach' ? 'attach' : null;

    const wizard = new KTWizard('kt_wizard_v4_bill', {
      startStep: hashName === 'attach' ? 3 : 1, // initial active step number
      clickableSteps: true, // allow step clicking
    });

    wizard.on('beforeNext', function (/*wizardObj*/) {});

    wizard.on('change', function (/*wizardObj*/) {
      setTimeout(() => {
        KTUtil.scrollTop();
      }, TIME_TRIGGER);
    });
  },
  methods: {
    linkToOrder: function (item) {
      const { href } = this.$router.resolve({
        name: 'edit-order',
        params: { id: item },
      });
      window.open(href, '_blank');
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    formatMoney(value) {
      return convertPrice(value);
    },
    async onSelectedProduct(option) {
      const installmentProgramModel = {
        isInstallmentProgramProduct: false,
        installmentProgramName: null,
        disabledByInstallmentProgram: false,
      };

      let price = option.item.sellingPrice;

      if (this.applyInstallmentProgram) {
        price = option.item.listedPrice;

        let textWarning =
          'Chỉ áp dụng cho 1 sản phẩm thuộc chương trình trả góp 0%';

        if (this.listBillItem.length > 0) {
          return this.showWarning(textWarning);
        }

        const product = await this.checkInsProgramByProductId(
          option.item.productId
        );

        if (!product) {
          return this.showWarning(
            'Sản phẩm không thuộc chương trình trả góp 0%'
          );
        }
        this.productInstallmentProgram = null;

        if (product && product.installmentProgram) {
          this.productInstallmentProgram = product;
          const installmentProgram = product.installmentProgram;

          installmentProgramModel.installmentProgramName =
            installmentProgram.name;
          installmentProgramModel.isInstallmentProgramProduct = true;
        }

        if (this.productInstallmentProgram) {
          const installmentProgram =
            this.productInstallmentProgram.installmentProgram;

          this.selectedInstallmentProgram = installmentProgram.name;

          this.selectedInstallmentProgramAccount = installmentProgram.id;

          const indexProgram =
            this.filteredOptionsInstallmentProgram[0].data.findIndex(
              (x) => x.id === installmentProgram.id
            );

          if (indexProgram < 0) {
            this.filteredOptionsInstallmentProgram[0].data.push({
              id: installmentProgram.id,
              name: installmentProgram.name,
            });
          }

          this.filteredOptionsInstallment[0].data = [];
          installmentProgram.installmentProgramDetails.map((e) => {
            const exist = this.optionsInstallment[0].data.find(
              (x) => x.id === e.installmentId
            );

            if (exist) {
              this.filteredOptionsInstallment[0].data.push(exist);
            }
          });

          this.optionsMonthNo = [
            {
              id: null,
              name: 'Chọn kỳ hạn',
            },
          ];

          installmentProgram.monthNo.map((current) => {
            this.optionsMonthNo.push({
              id: current,
              name: `${current} Tháng`,
            });
          });
        }
      }

      this.searchProduct = '';
      const selectedProduct = option.item;
      let imeiCode = '';
      let productType = selectedProduct.productType;

      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        imeiCode = selectedProduct.imeiCode;
        productType = PRODUCT_TYPE.PRODUCT_IMEI;
      }

      const billItem = {
        id: v4(),
        stt: 0,
        productId: selectedProduct.productId,
        name: selectedProduct.productName,
        productNameVat: selectedProduct.productNameVat,
        code: selectedProduct.productCode,
        price: price,
        type: productType,
        quantity: 1,
        imeiCode: imeiCode,
        discount: 0,
        quantityInStock: selectedProduct.quantityInStock,
        storeId: this.mainModel.storeId,
        comboItems: selectedProduct.listProductInCombo,
        billItemType: BILL_ITEM_TYPE.PRODUCT,
        checked: true,
        listAccessoryPromotion: selectedProduct.listAccessoryPromotion,
        returnPrice: 0,
        accessoryGroupId: '',
        accessoryGroupCode: '',
        note: '',
        discountType: AMOUNT_TYPE.PERCENT,
        discountProgramId: -1,
        isGetVat: false,
        sellingPrice: selectedProduct.sellingPrice,
        listedPrice: selectedProduct.listedPrice,
        ...installmentProgramModel,
      };
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        if (this.isExistImeiCode(this.listBillItem, imeiCode)) {
          return makeToastFaile('Mã IMEI đã tồn tại trong hóa đơn');
        }
        this.listBillItem.push(billItem);
        await this.fetchProductAttach(selectedProduct.productId, billItem.id);
      } else {
        if (
          selectedProduct.productType === PRODUCT_TYPE.PRODUCT_SERVICE ||
          selectedProduct.quantityInStock > 0
        ) {
          this.listBillItem.push(billItem);
          await this.fetchProductAttach(selectedProduct.productId, billItem.id);
        } else {
          makeToastFaile('Số sản phẩm tồn trong kho không đủ!');
        }
      }

      this.listBillItem = this.sortListBillItem(this.listBillItem);
      this.optionsProduct[0].data = [];
    },
    onInputChangProduct(text) {
      this.optionsProduct[0].data = [];
      this.filteredOptionsProduct = [
        {
          data: this.optionsProduct[0].data,
        },
      ];
      if (!text) {
        text = '';
      }
      this.searchProduct = text.trim();
      this.debounceInputProduct();
    },
    debounceInputProduct: decounce(function () {
      if (this.searchProduct) {
        this.searchProductAPI();
      }
    }, TIME_TRIGGER),
    searchProductAPI() {
      if (!this.isSearching) {
        const textSearch = this.searchProduct ? this.searchProduct.trim() : '';
        if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
          this.fetchProduct(textSearch);
        } else if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
          this.fetchProductImei(textSearch);
        }
      }
    },
    fetchProduct(textSearch) {
      this.isSearching = true;
      this.optionsProduct[0].data = [];
      ApiService.get(
        `productSearch?storeId=${this.mainModel.storeId}&searchProduct=${textSearch}`
      ).then(({ data }) => {
        const products = data.data;
        this.optionsProduct[0].data = products.map((element) => {
          return {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            productNameVat: element.productNameVat,
            barCode: element.barCode,
            quantityInStock: element.quantityInStock
              ? element.quantityInStock
              : 0,
            sellingPrice: element.sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            productType: element.productType,
            listProductInCombo: element.listProductInCombo,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
            listedPrice: element.listedPrice || 0,
          };
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          this.searchProductAPI();
        }
      });
    },
    fetchProductImei(textSearch) {
      this.isSearching = true;
      this.optionsProduct[0].data = [];
      ApiService.get(
        `productSearch/search-by-imei?storeId=${this.mainModel.storeId}&imeiCode=${textSearch}`
      ).then(({ data }) => {
        const products = data.data;
        this.optionsProduct[0].data = products.map((element) => {
          return {
            productId: element.productId,
            productName: element.productName,
            productCode: element.productCode,
            productNameVat: element.productNameVat,
            sellingPrice: element.sellingPrice,
            returnSellingPrice: element.returnSellingPrice,
            productType: element.productType,
            imeiCode: element.imeiCode,
            listAccessoryBonus: element.listAccessoryBonus,
            listAccessoryPromotion: element.listAccessoryPromotion,
            quantityInStock: element.quantityInStock
              ? element.quantityInStock
              : 0,
            listedPrice: element.listedPrice,
          };
        });
        this.filteredOptionsProduct = [
          {
            data: this.optionsProduct[0].data,
          },
        ];
        this.isSearching = false;
        if (textSearch !== this.searchProduct) {
          this.searchProductAPI();
        }
      });
    },
    changePlaceholder() {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
      } else if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        this.inputSearchProductProp.placeholder =
          'Tìm kiếm theo mã IMEI sản phẩm';
      }
      this.optionsProduct[0].data = [];
      this.debounceInputProduct();
    },
    onStoreSelected(storeId) {
      this.mainModel.storeId = storeId;
      this.inputSearchProductProp.disabled = false;
      this.listBillItem = [];
    },
    isExistImeiCode(listProduct, imeiCode) {
      const existed = listProduct.find((x) => x.imeiCode === imeiCode);
      return existed ? true : false;
    },
    async checkInsProgramByProductId(productId) {
      const params = {
        productId: productId,
      };

      return ApiService.query(`installment-programs/product`, {
        params,
      }).then(({ data }) => {
        const product = data.data;
        return product;
      });
    },
    showWarning(text) {
      return Swal.fire({
        title: 'Trả góp 0%!',
        text: `${text}`,
        icon: 'warning',
        showCancelButton: false,
        confirmButtonColor: 'rgb(221, 51, 51)',
        confirmButtonText: 'Đồng ý',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    },
    sortListBillItem(listBillItem) {
      const listResult = [];
      const mainItems = listBillItem.filter(
        (item) => item.billItemType === BILL_ITEM_TYPE.PRODUCT
      );
      const attachItems = listBillItem.filter(
        (item) => item.billItemType !== BILL_ITEM_TYPE.PRODUCT
      );
      mainItems.forEach((billItem, index) => {
        billItem.stt = index + 1;
        listResult.push(billItem);
        let belongBillItems = attachItems.filter(
          (item) => item.belongBillDetailId === billItem.id
        );
        belongBillItems = sortBy(belongBillItems, ['billItemType']);
        belongBillItems.forEach((attachItem) => {
          listResult.push(attachItem);
        });
      });
      return listResult;
    },
    async fetchProductAttach(productId, parentId) {
      ApiService.get(
        `productSearch/get-product-attach?storeId=${this.mainModel.storeId}&productId=${productId}`
      ).then(({ data }) => {
        //response data
        const item = {
          productId: data.data.productId,
          productName: data.data.productName,
          productNameVat: data.data.productNameVat,
          listAccessoryBonus: data.data.listAccessoryBonus,
          listAccessoryPromotion: data.data.listAccessoryPromotion,
        };
        //mapping data to main item
        const billItem = this.listBillItem.find((item) => item.id === parentId);
        if (!billItem) {
          return;
        }
        //gift
        billItem.listAccessoryBonus = item.listAccessoryBonus;
        item.listAccessoryBonus.forEach((element) => {
          const code = `Quà tặng kèm- ${element.accessoryGroupCode}`;

          const giftItem = {
            id: v4(),
            productId: element.productId,
            name: element.productName,
            productNameVat: element.productNameVat,
            code: code,
            price: element.sellingPrice,
            quantity: 1,
            type: element.productType,
            imeiCode: '',
            totalPrice: element.sellingPrice,
            discount: '',
            quantityInStock: element.quantityInStock,
            storeId: this.selectedClassStore,
            billItemType: BILL_ITEM_TYPE.PRODUCT_BONUS,
            checked: true,
            belongBillDetailId: parentId,
            returnPrice: element.price,
            accessoryGroupId: element.accessoryGroupId,
            accessoryGroupCode: element.accessoryGroupCode,
            listProductVariant: element.listProductVariant,
            isGetVat: false,
          };
          this.listBillItem.push(giftItem);
        });

        //attach
        billItem.listAccessoryPromotion = item.listAccessoryPromotion;

        this.listBillItem = this.sortListBillItem(this.listBillItem);
      });
    },
    deleteBillItem(id) {
      this.listBillItem = this.listBillItem.filter(
        (billItem) => billItem.id !== id && billItem.belongBillDetailId !== id
      );

      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    changeCouponCode() {
      if (
        this.isValidCoupon === true &&
        this.mainModel.couponCode !== this.validCouponCode
      ) {
        this.isValidCoupon = false;
        this.stateCouponCode = null;
      }
    },
    checkCoupon() {
      if (this.listBillItem.length <= 0 || this.listBillItem === []) {
        makeToastFaile('Bạn chưa chọn sản phẩm nào');
        return;
      }
      const billItems = this.formatListBillItem();
      const data = {
        storeId: this.mainModel.storeId,
        listProduct: billItems,
        couponCode: this.mainModel.couponCode,
      };
      this.onSubmiting = true;
      ApiService.post('coupon/check-coupon', data)
        .then(({ data }) => {
          this.onSubmiting = false;
          if (data.status === 1) {
            makeToastSuccess(data.message);
            if (data.data !== null) {
              this.mainModel.discountAmount = data.data;
              this.couponValue = data.data;
              this.mainModel.discountType = AMOUNT_TYPE.MONEY;
              this.isValidCoupon = true;
              this.validCouponCode = this.mainModel.couponCode;
              this.stateCouponCode = true;
            }
          } else {
            this.stateCouponCode = false;
            makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.onSubmiting = false;
          makeToastFaile(response.$error);
        });
    },
    showGiftModal(billItemId) {
      this.selectedBillItem.billItemId = billItemId;
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      setTimeout(() => {
        this.$refs['bill-gift-modal'].showModal();
      }, TIME_TRIGGER);
    },
    addGift(gifts) {
      this.listBillItem = this.listBillItem.concat(gifts);
      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    showAttachModal(billItemId) {
      this.selectedBillItem.billItemId = billItemId;
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      this.selectedBillItem.attachItems = billItem
        ? billItem.listAccessoryPromotion
        : [];
      setTimeout(() => {
        this.$refs['bill-attach-modal'].showModal();
      }, TIME_TRIGGER);
    },
    addAttach(attachs) {
      this.listBillItem = this.listBillItem.concat(attachs);
      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    showWarrantyModal(productId) {
      const billItem = this.listBillItem.find(
        (item) => item.productId === productId
      );
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      this.selectedBillItem.productId = productId;
      setTimeout(() => {
        this.$refs['bill-warranty-modal'].showModal();
      }, TIME_TRIGGER);
    },
    addWarranty(warrantyItems) {
      const listItem = warrantyItems.map((item) => {
        return {
          id: v4(),
          stt: 0,
          productId: item.id,
          name: item.productName,
          productNameVat: '',
          code: item.productCode,
          price: item.sellingPrice,
          type: 4,
          quantity: 1,
          imeiCode: '',
          discount: 0,
          quantityInStock: 0,
          storeId: this.mainModel.storeId,
          comboItems: [],
          billItemType: BILL_ITEM_TYPE.PRODUCT,
          checked: true,
          listAccessoryPromotion: [],
          returnPrice: 0,
          accessoryGroupId: '',
          accessoryGroupCode: '',
          note: '',
          discountType: AMOUNT_TYPE.PERCENT,
          discountProgramId: -1,
          isGetVat: false,
          sellingPrice: item.sellingPrice,
        };
      });
      this.listBillItem = this.listBillItem.concat(listItem);
      this.listBillItem = this.sortListBillItem(this.listBillItem);
    },
    checkValidImei(value) {
      this.isValidImei = value;
    },
    updateItem(item) {
      this.listBillItem.forEach((element, index) => {
        if (element.id === item.id) {
          this.listBillItem[index].name = item.name;
          this.listBillItem[index].imeiCode = item.imeiCode;
          this.listBillItem[index].productId = item.productId;
          this.listBillItem[index].price = item.price
            ? unMaskPrice(item.price)
            : 0;
          this.listBillItem[index].quantity = item.quantity
            ? unMaskPrice(item.quantity)
            : 0;
          this.listBillItem[index].discount = item.discount
            ? unMaskPrice(item.discount)
            : 0;
          this.listBillItem[index].note = item.note;
          this.listBillItem[index].discountType = item.discountType;
          this.listBillItem[index].discountProgramId = item.discountProgramId;
        }
      });
    },
    showChangeGiftModal(billItemId) {
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.listProductVariant = billItem
        ? billItem.listProductVariant
        : [];
      this.selectedBillItem.productId = billItem ? billItem.productId : null;
      this.selectedBillItem.billItemId = billItem.id;
      setTimeout(() => {
        this.$refs['change-gift-modal'].showModal();
      }, TIME_TRIGGER);
    },
    onChangeGift(productId) {
      const productSelected = this.selectedBillItem.listProductVariant.find(
        (variant) => variant.productId === productId
      );
      const billItem = this.listBillItem.find(
        (item) => item.id === this.selectedBillItem.billItemId
      );
      billItem.productId = productSelected.productId;
      billItem.name = productSelected.productName;
      billItem.productNameVat = productSelected.productNameVat;
    },
    showNoteModal(billItemId) {
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      this.selectedBillItem.note = billItem ? billItem.note : '';
      this.selectedBillItem.billItemId = billItemId;
      setTimeout(() => {
        this.$refs['bill-note-modal'].showModal();
      }, TIME_TRIGGER);
    },
    onChangeNote(note) {
      const billItem = this.listBillItem.find(
        (item) => item.id === this.selectedBillItem.billItemId
      );
      billItem.note = note;
    },
    showListImeiModal(billItemId) {
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      this.selectedBillItem.productName = billItem ? billItem.name : '';
      this.selectedBillItem.productId = billItem ? billItem.productId : null;
      this.selectedBillItem.billItemId = billItem.id;
      setTimeout(() => {
        this.$refs['list-imei-modal'].showModal(
          this.selectedBillItem.productId
        );
      }, TIME_TRIGGER);
    },
    applyImei(imeiCode) {
      const billItem = this.listBillItem.find(
        (item) => item.id === this.selectedBillItem.billItemId
      );
      billItem.imeiCode = imeiCode;
    },
    viewInventory(billItemId) {
      const billItem = this.listBillItem.find((item) => item.id === billItemId);
      if (billItem) {
        let nameRouter = '';
        if (billItem.type === 2) {
          nameRouter = 'inventory-detail-imei';
        } else {
          nameRouter = 'inventory-detail';
        }
        const { href } = this.$router.resolve({
          name: nameRouter,
          query: {
            id: billItem.productId,
          },
        });
        window.open(href, '_blank');
      }
    },
    isValidImeiCode(listBillItem) {
      let check = true;
      for (let index = 0; index < listBillItem.length; index++) {
        const element = listBillItem[index];
        if (
          element.type === PRODUCT_TYPE.PRODUCT_IMEI &&
          element.checked === true
        ) {
          if (!element.imeiCode || !element.imeiCode.trim()) {
            check = false;
            break;
          }
        }
      }
      return check;
    },
    onSubmit(mode = 'save') {
      this.onSubmiting = true;

      if (!this.mainModel.storeId) {
        this.onSubmiting = false;
        return makeToastFaile('Vui lòng chọn cửa hàng!');
      }

      if (!this.listBillItem.length) {
        this.onSubmiting = false;
        return makeToastFaile('Bạn chưa chọn sản phẩm nào');
      }
      if (!this.isValidImei) {
        this.onSubmiting = false;
        //kt lại 1 lần nữa xem IMEI có hợp lệ ko
        makeToastFaile('Mã IMEI không hợp lệ để bán!');
        this.isValidImei = true;
        return;
      }
      if (!this.isValidImeiCode(this.listBillItem)) {
        this.onSubmiting = false;
        return makeToastFaile('Vui lòng nhập IMEI cho sản phẩm IMEI');
      }
      if (
        this.prevSubtractPoint !== this.mainModel.subtractPoint &&
        !this.mainModel.isSubtractPointValidated
      ) {
        this.onSubmiting = false;
        return makeToastFaile('Vui lòng xác thực tiêu điểm tích lũy!');
      }

      if (this.mainModel.couponCode) {
        if (
          this.prevCouponCode !== this.mainModel.couponCode &&
          !this.isValidCoupon
        ) {
          this.onSubmiting = false;
          return makeToastFaile('Vui lòng xác thực mã coupon!');
        }
      }

      const billData = this.setBillData();
      // check creditFeeAmount
      if (billData.creditAmount === 0) {
        billData.otherFees = 0;
      }

      this.onPaymentSubmiting = true;
      if (!this.validatePayment(billData)) {
        this.onSubmiting = false;
        return makeToastFaile('Vui lòng nhập thông tin thanh toán hợp lệ!');
      }

      if (!billData.customerMobile) {
        this.onSubmiting = false;
        return makeToastFaile('Vui lòng nhập thông tin khách hàng hợp lệ!');
      }

      if (!this.mainModel.id) {
        if (!this.mainModel.orderId && this.orderNotComplete) {
          this.showWarningOrderNotComplete(billData);
        } else {
          this.onCallApiCreate(billData);
        }
      } else {
        this.onCallApiUpdate(billData, mode);
      }
    },
    onCallApiCreate(billData) {
      const url = this.mainModel.fromBillNumber
        ? `bills/re-create/${this.mainModel.fromBillNumber}`
        : 'bills/v2';
      ApiService.post(url, billData)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            this.$router.push({
              path: '/bills',
            });
          } else {
            makeToastFaile(data.message);
            this.onSubmiting = false;
          }
        })
        .catch(({ response }) => {
          this.onSubmiting = false;
          makeToastFaile(response.$error);
        });
    },
    onCallApiUpdate(billData, mode = 'save') {
      ApiService.put('bills/v2', billData)
        .then(({ data }) => {
          if (data.status === 1) {
            makeToastSuccess(data.message);
            if (mode === 'save-print') {
              this.$router.push({
                name: 'print-bill',
                query: { id: billData.billNumber },
              });
            } else {
              this.$router.push({
                path: '/bills',
              });
            }
            this.onSubmiting = false;
          } else {
            makeToastFaile(data.message);
            this.onSubmiting = false;
          }
        })
        .catch(({ response }) => {
          this.onSubmiting = false;
          makeToastFaile(response.$error);
        });
    },
    setBillData() {
      const billItems = this.formatListBillItem();
      return {
        id: this.mainModel.id,
        billNumber: this.mainModel.billNumber || null,
        storeId: this.mainModel.storeId,
        //khach hang
        customerMobile: this.mainModel.customerModel.customerMobile.trim(),
        customerName: this.mainModel.customerModel.customerName.trim(),
        customerAddress: this.mainModel.customerModel.customerAddress,
        customerCity: this.mainModel.customerModel.customerCity,
        customerDistrict: this.mainModel.customerModel.customerDistrict,
        customerWard: this.mainModel.customerModel.customerWard,
        customerLastName: this.mainModel.customerModel.customerLastName,
        orderSourceId: this.mainModel.orderSourceId,
        //khach hang bao hanh
        warrantyCustomerId: this.mainModel.warrantyCustomerModel.customerId,
        warrantyCustomerName: this.mainModel.warrantyCustomerModel.customerName,
        warrantyCustomerPhone:
          this.mainModel.warrantyCustomerModel.customerMobile,
        warrantyCustomerLastName:
          this.mainModel.warrantyCustomerModel.customerLastName,
        //nhan vien
        saleId: this.mainModel.billEmployeeInfo.saleId,
        technicalId: this.mainModel.billEmployeeInfo.technicalId,
        accessoryStaffId: this.mainModel.billEmployeeInfo.accessoryStaffId,
        cashierId: this.mainModel.billEmployeeInfo.cashierId,
        managerId: this.mainModel.billEmployeeInfo.managerId,
        assistantId: this.mainModel.billEmployeeInfo.assistantId,
        customerReceptionId:
          this.mainModel.billEmployeeInfo.customerReceptionId,
        shipperId: this.mainModel.billEmployeeInfo.shipperId,
        //ghi chu
        customerNote: this.mainModel.billNote.customerNote,
        saleNote: this.mainModel.billNote.saleNote,
        warrantyNote: this.mainModel.billNote.warrantyNote,
        //chiet khau
        discountType: this.mainModel.discountType,
        discountAmount: this.mainModel.discountAmount
          ? unMaskPrice(this.mainModel.discountAmount)
          : 0,
        //tien mat
        cashAmount: this.mainModel.paymentInfo.cashAmount
          ? unMaskPrice(this.mainModel.paymentInfo.cashAmount)
          : 0,
        cashAccountId: this.mainModel.paymentInfo.cashAccountId,
        cashAccountCode: this.mainModel.paymentInfo.cashAccountCode,
        //chuyen khoan
        transferAmount: this.mainModel.paymentInfo.transferAmount
          ? unMaskPrice(this.mainModel.paymentInfo.transferAmount)
          : 0,
        transferAccountID: this.mainModel.paymentInfo.transferAccountantId,
        transferAccountCode: this.mainModel.paymentInfo.transferAccountCode,
        transferReferenceCode: this.mainModel.paymentInfo.transferReferenceCode,
        //quet the
        creditAmount: this.mainModel.paymentInfo.creditAmount
          ? unMaskPrice(this.mainModel.paymentInfo.creditAmount)
          : 0,
        creditAccountId: this.mainModel.paymentInfo.creditAccountantId,
        creditAccountCode: this.mainModel.paymentInfo.creditAccountCode,
        creditCode: this.mainModel.paymentInfo.creditCardNo,
        creditCardNo: this.mainModel.paymentInfo.creditCardCode,
        //phi quet the
        otherFees: this.mainModel.paymentInfo.creditCardFee
          ? unMaskPrice(this.mainModel.paymentInfo.creditCardFee)
          : 0,
        payCreditFeeType: this.mainModel.paymentInfo.payCreditFeeType,
        creditFeeAccountId: this.mainModel.paymentInfo.creditFeeAccountantId,
        creditFeeAcountCode: this.mainModel.paymentInfo.creditFeeAcountCode,
        //tra gop
        installedMoneyAmount: this.mainModel.paymentInfo.installedMoneyAmount
          ? unMaskPrice(this.mainModel.paymentInfo.installedMoneyAmount)
          : 0,
        installMoneyAccountId: this.mainModel.paymentInfo.installMoneyAccountId,
        installMoneyAccountCode: this.selectedInstallmentAccountCode,
        customerIndentifyNo: this.mainModel.paymentInfo.customerIndentifyNo,
        installMoneyCode: this.mainModel.paymentInfo.installmentCode,
        listBillItem: billItems,
        orderId: this.mainModel.orderId,
        //hoa don do
        vatCompanyName: this.mainModel.vatInvoice.companyName,
        vatCompanyAddress: this.mainModel.vatInvoice.companyAddress,
        vatCompanyType: this.mainModel.vatInvoice.vatCompanyType,
        email: this.mainModel.vatInvoice.email,
        taxCode: this.mainModel.vatInvoice.taxCode,
        //tieu diem
        pointUse: this.mainModel.subtractPoint
          ? this.mainModel.subtractPoint
          : 0,
        isSubtractPointValidated: this.mainModel.isSubtractPointValidated,
        isCountPoint: this.mainModel.isCountPoint,
        //coupon
        isValidCouponCode: this.isValidCoupon,
        couponCode: this.mainModel.couponCode,
        //chuong trinh tra gop
        installmentProgramId: this.selectedInstallmentProgramAccount,
        installmentProgramMonthNo: this.installmentProgramMonthNo,
      };
    },
    validatePayment(billData) {
      const amounts = [
        billData.cashAmount,
        billData.transferAmount,
        billData.creditAmount,
        billData.otherFees,
        billData.installedMoneyAmount,
      ];
      const accountIds = [
        billData.cashAccountId,
        billData.transferAccountID,
        billData.creditAccountId,
        billData.creditFeeAccountId,
        billData.installMoneyAccountId,
      ];

      for (let index = 0; index < amounts.length; index++) {
        const amount = amounts[index];
        const accountId = accountIds[index];
        if (parseInt(amount) > 0 && !accountId) {
          this.onPaymentSubmiting = true;
          return false;
        }
      }
      this.onPaymentSubmiting = false;
      return true;
    },
    formatListBillItem() {
      const mainItems = this.listBillItem.filter(
        (item) => item.billItemType === BILL_ITEM_TYPE.PRODUCT
      );
      const giftItems = this.listBillItem.filter(
        (item) => item.billItemType === BILL_ITEM_TYPE.PRODUCT_BONUS
      );
      const attachItems = this.listBillItem.filter(
        (item) => item.billItemType === BILL_ITEM_TYPE.PRODUCT_PROMTION
      );

      const giftItemFormated = this.convertBillItem(giftItems);
      const attachItemFormated = this.convertBillItem(attachItems);

      return mainItems.map((billItem) => {
        const gifts = giftItemFormated.filter(
          (giftItem) => giftItem.belongBillDetailId === billItem.id
        );
        const attachs = attachItemFormated.filter(
          (attchItem) => attchItem.belongBillDetailId === billItem.id
        );

        return {
          id: billItem.id || null,
          productId: billItem.productId,
          productPrice: unMaskPrice(billItem.price),
          quantity: billItem.quantity ? unMaskPrice(billItem.quantity) : 1,
          discountAmount: billItem.discount
            ? unMaskPrice(billItem.discount)
            : 0,
          imeiCode: billItem.imeiCode,
          productType: billItem.billItemType,
          accessoryGroupId: billItem.accessoryGroupId,
          accessoryGroupCode: billItem.accessoryGroupCode,
          repurchasePrice: billItem.returnPrice
            ? unMaskPrice(billItem.returnPrice)
            : 0,
          isGiftTaken: billItem.checked,
          note: billItem.note,
          gifts: gifts,
          attachs: attachs,
          discountType: billItem.discountType,
          discountProgramId: billItem.discountProgramId,
          issuedVat: billItem.isGetVat,
        };
      });
    },
    convertBillItem(items) {
      return items.map((billItem) => {
        return {
          id: billItem.id || null,
          productId: billItem.productId,
          productPrice: unMaskPrice(billItem.price),
          quantity: billItem.quantity ? unMaskPrice(billItem.quantity) : 1,
          discountAmount: billItem.discount
            ? unMaskPrice(billItem.discount)
            : 0,
          imeiCode: billItem.imeiCode,
          productType: billItem.billItemType,
          accessoryGroupId: billItem.accessoryGroupId,
          accessoryGroupCode: billItem.accessoryGroupCode,
          repurchasePrice: billItem.returnPrice
            ? unMaskPrice(billItem.returnPrice)
            : 0,
          isGiftTaken: billItem.checked,
          note: billItem.note,
          gifts: [],
          attachs: [],
          discountType: AMOUNT_TYPE.MONEY,
          discountProgramId: null,
          issuedVat: billItem.isGetVat,
          belongBillDetailId: billItem.belongBillDetailId,
        };
      });
    },
    getPointRate() {
      ApiService.get(`pointSetting`).then(({ data }) => {
        this.pointSetting.rate = parseInt(data.data.pointRate);
        this.pointSetting.allowRoundToDown =
          data.data.allowAddPointRoundToDown === 1 ? true : false;
      });
    },
    getListOrderSource() {
      ApiService.get(`orderSources/getAll`).then((data) => {
        this.listCustomerSource = data.data.data;
      });
    },
    getOTP() {
      this.onSubmiting = true;
      const data = {
        customerId: this.mainModel.customerModel.customerId,
        pointUse: this.mainModel.subtractPoint,
      };
      ApiService.post('customerOTP/', data)
        .then(({ data }) => {
          this.onSubmiting = false;
          if (data.status === 1) {
            makeToastSuccess(data.message);
            if (data.data !== null) {
              this.mainModel.otpCode = '123456';
              this.stateOTP = false;
            }
          } else {
            makeToastFaile(data.message);
          }
        })
        .catch((response) => {
          this.onSubmiting = false;
          makeToastFaile(response.$error);
        });
    },
    checkOTP() {
      this.onSubmiting = false;
      const data = {
        customerId: this.mainModel.customerModel.customerId,
        pointUse: this.mainModel.subtractPoint,
        otpCode: this.mainModel.otpCode,
      };
      ApiService.post('customerOTP/checkOTP', data)
        .then(({ data }) => {
          this.onSubmiting = false;
          if (data.status === 1) {
            makeToastSuccess(data.message);
            if (data.data !== null) {
              this.mainModel.totalAmountPoint = data.data;
              this.mainModel.isSubtractPointValidated = true;
              this.stateOTP = true;
            }
          } else {
            makeToastFaile(data.message);
            this.stateOTP = false;
          }
        })
        .catch(({ response }) => {
          this.onSubmiting = false;
          makeToastFaile(response.$error);
        });
    },
    getOrderById() {
      ApiService.query(`orders/${this.mainModel.orderId}`).then((data) => {
        const {
          saleNote,
          customerNote,
          customerId,
          discountAmount,
          discountType,
          transferAmount,
          depositAmount,
          storeId,
          orderItems,
          orderSourceId,
          storeName,
          creditAmount,
          installedMoneyAmount,
          customerShipFee,
          warrantyNote,
          codeShip,
          saleStoreId,
          saleStoreName,
        } = data.data.data;
        this.mainModel.billNote.warrantyNote = warrantyNote;
        this.mainModel.storeId = storeId;
        this.mainModel.storeName = storeName;
        this.mainModel.billEmployeeInfo.saleId = saleStoreId;
        this.mainModel.billEmployeeInfo.saleName = saleStoreName;
        this.mainModel.billNote.saleNote = saleNote;
        this.mainModel.billNote.customerNote = customerNote;
        this.mainModel.customerModel.customerId = customerId;
        this.mainModel.discountAmount = discountAmount ? discountAmount : 0;
        this.mainModel.discountType = discountType;
        this.searchStore = storeName;
        this.mainModel.orderSourceId = orderSourceId;
        this.mainModel.moneyTransfered = transferAmount;
        this.mainModel.moneyPaid = depositAmount;
        this.mainModel.moneyCredit = creditAmount;
        this.mainModel.moneyInstallment = installedMoneyAmount;
        this.mainModel.codeShip = codeShip;
        this.inputSearchProductProp.disabled = false;
        this.mainModel.customerModel.customerMobile =
          data.data.data.customerMobile;
        this.mainModel.customerModel.customerAddress =
          data.data.data.customerAddress;
        this.mainModel.customerModel.customerName = data.data.data.customerName;
        this.mainModel.customerModel.customerCity = data.data.data.customerCity;
        this.mainModel.customerModel.customerWard = data.data.data.customerWard;
        this.mainModel.customerModel.customerDistrict =
          data.data.data.customerDistrict;
        this.mainModel.vatInvoice.companyAddress =
          data.data.data.vatCompanyAddress;
        this.mainModel.vatInvoice.companyName = data.data.data.vatCompanyName;
        this.mainModel.vatInvoice.taxCode = data.data.data.taxCode;
        this.mainModel.vatInvoice.email = data.data.data.email;
        this.mainModel.couponCode = data.data.data.couponCode;
        this.isValidCoupon = data.data.data.couponCode ? true : false;
        this.mainModel.customerShipFee = customerShipFee ? customerShipFee : 0;
        this.mainModel.customerModel.totalPoint = data.data.data.customerPoint;
        this.mainModel.subtractPoint = data.data.data.subtractPoint;
        this.mainModel.totalAmountPoint = data.data.data.subtractPointAmount;
        this.mainModel.isSubtractPointValidated =
          data.data.data.subtractPointAmount > 0 ? true : false;
        this.listBillItem = orderItems.map((orderItem) => {
          let isGiftTaken = true;
          if (orderItem.isGiftTaken === 0) {
            isGiftTaken = true;
          }
          return {
            id: orderItem.id,
            productId: orderItem.productId,
            name: orderItem.productName,
            code: orderItem.productCode,
            productNameVat: orderItem.productNameVat,
            type: orderItem.productType,
            price: orderItem.productPrice,
            imeiCode: orderItem.imeiCode,
            quantity: orderItem.quantity,
            totalPrice: orderItem.totalAmount,
            discount:
              orderItem.orderItemType === AMOUNT_TYPE.MONEY
                ? orderItem.discountAmount
                : 0,
            discountType: AMOUNT_TYPE.MONEY,
            quantityInStock: orderItem.quantityInStock,
            comboItems: orderItem.productInCombos,
            storeId: storeId,
            deleted: orderItem.deleted,
            billItemType: orderItem.orderItemType,
            returnPrice: orderItem.repurchasePrice
              ? orderItem.repurchasePrice
              : 0,
            checked: isGiftTaken,
            orderItemType: orderItem.orderItemType,
            listAccessoryBonus: [],
            note: orderItem.note ? orderItem.note : '',
            listAccessoryPromotion: orderItem.listAccessoryPromotion
              ? orderItem.listAccessoryPromotion
              : [],
            belongBillDetailId: orderItem.belongOrderDetailId,
            isGetVat: false,
          };
        });
        this.listBillItem = this.sortListBillItem(this.listBillItem);
        this.inputSearchProductProp.disabled = false;
        this.renderComponent = true;
      });
    },
    getBillById() {
      ApiService.get(`bills/${this.mainModel.billNumber}`).then((response) => {
        if (response.data.status === 0) {
          return makeToastFaile('Tải thông tin hóa đơn thất bại!');
        }
        const dataRes = response.data.data;
        const {
          id,
          storeId,
          storeName,
          saleNote,
          customerNote,
          warrantyNote,
          customerId,
          discountAmount,
          discountType,
          orderSourceId,
          customerShipFee,
          codeShip,
          cashAmount,
          cashAccountId,
          cashAccName,
          cashAccountCode,
          transferAmount,
          transferAccountId,
          transferAccName,
          transferAccountCode,
          transferReferenceCode,
          creditAmount,
          creditAccountId,
          creditAccName,
          creditAccountCode,
          creditCardNo,
          creditCode,
          otherFees,
          payCreditFeeType,
          creditFeeAcountId,
          creditFeeAccName,
          creditFeeAcountCode,
          installedMoneyAmount,
          installMoneyAccountId,
          installmentAccName,
          customerIndentifyNo,
          installMoneyCode,
          depositAmount,
          transferFromOrder,
          moneyCredit,
          moneyInstallment,
          pointToMoney,
          subtractPoint,
          couponCode,
          billItems,
          editable,
          isSubtractPointValidated,
        } = dataRes;
        this.mainModel.id = id;
        this.mainModel.storeId = storeId;
        this.mainModel.storeName = storeName;
        this.mainModel.billNote.warrantyNote = warrantyNote;
        this.mainModel.billNote.saleNote = saleNote;
        this.mainModel.billNote.customerNote = customerNote;
        this.mainModel.customerModel.customerId = customerId;
        this.mainModel.customerModel.customerMobile = dataRes.customerPhone;
        this.mainModel.customerModel.customerAddress = dataRes.customerAddress;
        this.mainModel.customerModel.customerName = dataRes.customerName;
        this.mainModel.customerModel.customerCity = dataRes.customerCity;
        this.mainModel.customerModel.customerWard = dataRes.customerWard;
        this.mainModel.customerModel.customerDistrict =
          dataRes.customerDistrict;
        this.mainModel.customerModel.totalPoint = dataRes.customerPoint;
        this.mainModel.orderSourceId = orderSourceId;
        this.mainModel.discountAmount = discountAmount ? discountAmount : 0;
        this.mainModel.discountType = discountType;
        this.mainModel.codeShip = codeShip;
        this.mainModel.customerShipFee = customerShipFee ? customerShipFee : 0;
        this.mainModel.billEmployeeInfo.saleId = dataRes.saleId;
        this.mainModel.billEmployeeInfo.saleName = dataRes.saleName;
        this.mainModel.billEmployeeInfo.technicalId = dataRes.technicalId;
        this.mainModel.billEmployeeInfo.technicalName = dataRes.techName;
        this.mainModel.billEmployeeInfo.cashierId = dataRes.cashierId;
        this.mainModel.billEmployeeInfo.cashierName = dataRes.cashierName;
        this.mainModel.billEmployeeInfo.managerId = dataRes.managerId;
        this.mainModel.billEmployeeInfo.managerName = dataRes.managerName;
        this.mainModel.billEmployeeInfo.assistantId = dataRes.assistantId;
        this.mainModel.billEmployeeInfo.assistantName = dataRes.assistantName;
        this.mainModel.billEmployeeInfo.customerReceptionId =
          dataRes.customerReceptionId;
        this.mainModel.billEmployeeInfo.customerReceptionName =
          dataRes.customerReceptionName;
        this.mainModel.billEmployeeInfo.accessoryStaffId =
          dataRes.accessoryStaffId;
        this.mainModel.billEmployeeInfo.accessoryStaffName =
          dataRes.accessoryStaffName;
        this.mainModel.billEmployeeInfo.shipperId = dataRes.shipperId;
        this.mainModel.billEmployeeInfo.shipperName = dataRes.shipperName;
        this.mainModel.paymentInfo.cashAmount = cashAmount;
        this.mainModel.paymentInfo.cashAccountId = cashAccountId;
        this.mainModel.paymentInfo.cashAccountantName = cashAccName;
        this.mainModel.paymentInfo.cashAccountCode = cashAccountCode;
        this.mainModel.paymentInfo.transferAmount = transferAmount;
        this.mainModel.paymentInfo.transferAccountantId = transferAccountId;
        this.mainModel.paymentInfo.transferAccountantName = transferAccName;
        this.mainModel.paymentInfo.transferAccountCode = transferAccountCode;
        this.mainModel.paymentInfo.transferReferenceCode =
          transferReferenceCode;
        this.mainModel.paymentInfo.creditAmount = creditAmount;
        this.mainModel.paymentInfo.creditAccountantId = creditAccountId;
        this.mainModel.paymentInfo.creditAccountCode = creditAccountCode;
        this.mainModel.paymentInfo.creditAccountantName = creditAccName;
        this.mainModel.paymentInfo.creditCardNo = creditCode;
        this.mainModel.paymentInfo.creditCardCode = creditCardNo;
        this.mainModel.paymentInfo.creditCardFee = otherFees;
        this.mainModel.paymentInfo.payCreditFeeType = payCreditFeeType;
        this.mainModel.paymentInfo.creditFeeAccountantId = creditFeeAcountId;
        this.mainModel.paymentInfo.creditFeeAcountCode = creditFeeAcountCode;
        this.mainModel.paymentInfo.creditFeeAccountantName = creditFeeAccName;
        this.mainModel.paymentInfo.installedMoneyAmount = installedMoneyAmount;
        this.mainModel.paymentInfo.installMoneyAccountId =
          installMoneyAccountId;
        this.mainModel.paymentInfo.installmentAccountName = installmentAccName;
        this.mainModel.paymentInfo.customerIndentifyNo = customerIndentifyNo;
        this.mainModel.paymentInfo.installmentCode = installMoneyCode;
        this.mainModel.warrantyCustomerModel.customerId =
          dataRes.warrantyCustomerId;
        this.mainModel.warrantyCustomerModel.customerName =
          dataRes.warrantyCustomerName;
        this.mainModel.warrantyCustomerModel.customerMobile =
          dataRes.warrantyCustomerPhone;
        this.mainModel.warrantyCustomerModel.customerLastName =
          dataRes.warrantyCustomerLastName;
        this.mainModel.orderId = dataRes.orderId;
        this.mainModel.moneyTransfered = transferFromOrder;
        this.mainModel.moneyPaid = depositAmount;
        this.mainModel.moneyCredit = moneyCredit;
        this.mainModel.moneyInstallment = moneyInstallment;
        this.mainModel.totalAmountPoint = pointToMoney;
        this.mainModel.subtractPoint = subtractPoint;
        this.prevSubtractPoint = subtractPoint;
        this.mainModel.isSubtractPointValidated = isSubtractPointValidated;
        this.mainModel.couponCode = couponCode;
        this.prevCouponCode = couponCode;
        this.mainModel.vatInvoice.companyName = dataRes.companyName;
        this.mainModel.vatInvoice.companyAddress = dataRes.vatCompanyAddress;
        this.mainModel.vatInvoice.vatCompanyType = dataRes.vatCompanyType;
        this.mainModel.vatInvoice.email = dataRes.email;
        this.mainModel.vatInvoice.taxCode = dataRes.taxCode;
        this.mainModel.companyBill = dataRes.taxCode ? true : false;
        this.mainModel.billNote.customerNote = dataRes.customerNote;
        this.mainModel.billNote.saleNote = dataRes.saleNote;
        this.mainModel.billNote.warrantyNote = dataRes.warrantyNote;
        this.mainModel.editable = editable;
        this.listBillItem = billItems.map((billItem) => {
          return {
            id: billItem.id,
            productId: billItem.productId,
            name: billItem.productName,
            productNameVat: billItem.productNameVat,
            code: billItem.productCode,
            price: billItem.productPrice,
            type: billItem.productType,
            quantity: billItem.quantity,
            imeiCode: billItem.imeiCode,
            totalPrice:
              billItem.quantity * billItem.productPrice -
              billItem.discountAmount,
            discount: billItem.discountAmount,
            comboItems: billItem.listProductInCombo,
            billItemType: billItem.billItemType,
            checked:
              billItem.isGiftTaken === null ? true : billItem.isGiftTaken,
            returnPrice: billItem.repurchasePrice
              ? billItem.repurchasePrice
              : 0,
            accessoryGroupId: billItem.accessoryGroupId
              ? billItem.accessoryGroupId
              : '',
            accessoryGroupCode: billItem.accessoryGroupCode
              ? billItem.accessoryGroupCode
              : '',
            note: billItem.note,
            belongBillDetailId: billItem.belongBillDetailId,
            listAccessoryPromotion: billItem.listProductPromotion,
            quantityInStock: billItem.quantityInStock,
            storeId: storeId,
            discountType: billItem.discountType,
            discountProgramId: billItem.discountProgramId,
            isGetVat: billItem.issuedVat === 1 ? true : false,
            disabledByInstallmentProgram: this.applyInstallmentProgram
              ? true
              : false,
            isInstallmentProgramProduct: this.applyInstallmentProgram
              ? true
              : false,
            sellingPrice: billItem.sellingPrice || 0,
            listedPrice: billItem.listedPrice || 0,
          };
        });
        this.listBillItem = this.sortListBillItem(this.listBillItem);
        this.inputSearchProductProp.disabled = false;
        if (this.$route.name === 'add-bill') {
          this.mainModel.id = null;
          this.mainModel.billNumber = null;
          this.mainModel.storeDisable = true;
        } else {
          this.pageTitle = `Thông tin hóa đơn: #${this.mainModel.billNumber} - Ngày: ${dataRes.createdAt}`;
        }
        setTimeout(() => {
          this.renderComponent = true;
        }, TIME_TRIGGER);

        this.fetchStoreByUser();
      });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    openFileUpload() {
      document.getElementById('upload-files').click();
    },
    handleFileUpload(event) {
      // this.linkHref = "";
      this.fileAttach.files = event.target.files;
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    //This is where we implement the storage service
    save(formData) {
      this.currentStatus = STATUS_SAVING;
      //Implement your own storage service when upload() is called.
      //For test purposes, I added a delay service
      this.upload(formData)
        .then((x) => {
          this.uploadedFiles = this.uploadedFiles.concat(x);
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          console.log(err);
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    upload(formData) {
      const photos = formData.getAll('photos');
      const promises = photos.map((x) =>
        this.getImage(x).then((img) => ({
          id: img,
          originalName: x.name,
          fileName: x.name,
          url: img,
        }))
      );
      return Promise.all(promises);
    },
    getImage(file) {
      return new Promise((resolve) => {
        const fReader = new FileReader();
        const img = document.createElement('img');

        fReader.onload = () => {
          img.src = fReader.result;
          resolve(this.getBase64Image(img));
        };

        fReader.readAsDataURL(file);
      });
    },
    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;

      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = img.src;
      return dataURL;
    },
    //Returns true for image data types for others false, for preview purpose
    typecheck(oInput) {
      var _validFileExtensions = ['.jpg', '.jpeg', '.bmp', '.gif', '.png'];
      if (oInput.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (
            oInput
              .substr(
                oInput.length - sCurExtension.length,
                sCurExtension.length
              )
              .toLowerCase() == sCurExtension.toLowerCase()
          ) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          return false;
        }
      }
      return true;
    },
    //Removes the selected file from the array.
    removeFile(fileCount, key) {
      fileCount = this.uploadedFiles.length;
      if (fileCount === 1) {
        this.uploadedFiles.splice(key, 1);
        this.reset();
      } else {
        this.uploadedFiles.splice(key, 1);
      }
    },
    //Func to truncate long filenames to short ones to display
    ftruncate(n) {
      // var len = 10;
      var ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase();
      var filename = n.replace('.' + ext, '');
      return filename + '.' + ext;
    },
    // Handle file changes
    filesChange(fieldName, fileList) {
      const formData = new FormData();
      if (!fileList.length) return;
      // append the files to FormData
      Array.from(Array(fileList.length).keys()).map((x) => {
        formData.append(fieldName, fileList[x], fileList[x].name);
      });
      // Save it to cloud
      this.save(formData);
    },
    submitFileUpload() {
      let formData = new FormData();
      for (const i of Object.keys(this.fileAttach.files)) {
        formData.append('fileUploads', this.fileAttach.files[i]);
      }
      this.currentStatus = STATUS_SAVING;
      const context = this;
      axios
        .post(
          `${cmdUrl.File.upload}?entityId=${this.mainModel.billNumber}&entity=${UPLOAD_ENTITY.BILLS}`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${JwtService.getToken()}`,
            },
          }
        )
        .then((resp) => {
          if (resp.status === 200) {
            context.currentStatus = STATUS_SUCCESS;
          }
        })
        .catch(() => {
          context.currentStatus = STATUS_FAILED;
        });
    },
    deleteAttachment(id) {
      ApiService.delete(`${cmdUrl.File.root}/${id}`).then((deleteResponse) => {
        if (deleteResponse.status === 200) {
          if (deleteResponse.data.data === true) {
            const index = this.attachments.findIndex((item) => item.id === id);
            this.attachments.splice(index, 1);
          }
        }
      });
    },
    loadAttachments() {
      this.isLoadAttachmentSuccess = false;
      ApiService.query('file', {
        params: {
          entity: UPLOAD_ENTITY.BILLS,
          entityId: this.mainModel.billNumber,
        },
      })
        .then(async (resp) => {
          if (resp.status === 200) {
            const attachments = resp.data.data.map((item) => ({
              id: item.id,
              fileName: item.fileName,
              originalName: item.fileName,
            }));

            this.attachments = await Promise.all(
              attachments.map(async ({ fileName, ...rest }) => {
                const resp = await axios.get(
                  `${cmdUrl.File.download}?filename=${fileName}`,
                  {
                    responseType: 'arraybuffer',
                    headers: {
                      Authorization: `Bearer ${JwtService.getToken()}`,
                    },
                  }
                );
                const contentType = resp.headers['content-type'];
                const base64 = `data:${contentType};base64,${Buffer.from(
                  resp.data
                ).toString('base64')}`;
                return {
                  ...rest,
                  url: base64,
                  fileName,
                };
              })
            );

            this.isLoadAttachmentSuccess = true;
          }
        })
        .catch(() => {
          this.isLoadAttachmentSuccess = false;
        });
    },
    handleHidePreviewFile() {
      this.previewFile.visible = false;
    },
    showMultiplePreViewFile(indexOfFile) {
      const files = this.uploadedFiles.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewFile.imgs = files;
      this.previewFile.index = indexOfFile;
      this.previewFile.visible = true;
    },
    handleHidePreviewAttachment() {
      this.previewAttachment.visible = false;
    },
    showMultiplePreViewAttachment(indexOfFile) {
      const files = this.attachments.map((file) => {
        return {
          src: file.url,
          title: file.fileName,
        };
      });
      this.previewAttachment.imgs = files;
      this.previewAttachment.index = indexOfFile;
      this.previewAttachment.visible = true;
    },
    viewHistoryDetail(item) {
      this.$router.push({
        name: 'detail-bill-history',
        query: { id: item.id },
      });
    },
    fetchBillHistory: async function () {
      const params = {
        //  loại hóa đơn
        type: 2,
      };
      await ApiService.setHeader();
      await ApiService.query(
        '/histories/getByReferenceId/' + this.mainModel.billNumber,
        {
          params,
        }
      ).then((res) => {
        res.data.data.map((x) => {
          let data = {
            id: x.id,
            type: x.type,
            referenceId: x.referenceId,
            action: x.action,
            description: x.description,
            originalValue: x.originalValue,
            newValue: x.newValue,
            updatedBy: x.updatedBy,
            createdAt: moment(String(x.createdAt)).format('DD/MM/YYYY HH:mm'),
            updatedAt: moment(x.updatedAt).format('DD/MM HH:mm'),
          };
          this.histories.push(data);
        });
      });
    },
    onUpdatePayment(paymentInfo) {
      this.mainModel.paymentInfo = cloneDeep(paymentInfo);
    },
    onUpdateCustomer(customerInfo) {
      this.mainModel.customerModel = cloneDeep(customerInfo);
    },
    onChangeSubtractPoint() {
      if (this.prevSubtractPoint !== this.mainModel.subtractPoint) {
        this.mainModel.isSubtractPointValidated = false;
      } else {
        this.mainModel.isSubtractPointValidated = true;
      }
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          this.inputSearchProductProp.disabled = false;
          if (!this.mainModel.billEmployeeInfo.saleId) {
            this.mainModel.billEmployeeInfo.saleId = data.data.id;
            this.mainModel.billEmployeeInfo.saleName = data.data.employeeName;
          }

          if (
            data.data.jobTitleId === 6 &&
            !this.mainModel.billEmployeeInfo.cashierId
          ) {
            this.mainModel.billEmployeeInfo.cashierId = data.data.id;
            this.mainModel.billEmployeeInfo.cashierName =
              data.data.employeeName;
          }
          const stores = data.data.stores;
          if (stores.length === 1 && !this.mainModel.storeId) {
            this.mainModel.storeId = stores[0].id;
            this.mainModel.storeName = stores[0].name;
            this.mainModel.storeDisable = true;
          }
          if (this.mainModel.id) {
            this.mainModel.storeDisable = true;
          }
        }
      });
    },
    fetchCustomerCransaction(customerTransaction) {
      this.orderNotComplete = customerTransaction.orderNotComplete;
    },
    showWarningOrderNotComplete: function (billData) {
      Swal.fire({
        title: 'Cảnh báo đơn hàng!',
        text: 'Khách hàng có đơn hàng chưa hoàn thành, bạn có chắc muốn tạo hóa đơn mới cho khách hàng này không ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Tạo mới',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
        position: 'top',
      }).then((result) => {
        if (result.value) {
          this.onCallApiCreate(billData);
        } else {
          this.onSubmiting = false;
        }
      });
    },
    linkToWarranty(id) {
      this.$router.push({
        name: 'add-import-warranty',
        query: { refId: id },
      });
    },
  },
};
</script>

<style>
.autosuggest__results ul {
  list-style: none;
  cursor: pointer;
  margin-bottom: 0;
}

.autosuggest__results {
  position: absolute;
  background-color: #fff;
  z-index: 1;
  width: 100%;
  border: 1px solid #e0e0e0;
  overflow: scroll;
  max-height: 200px;
  position: absolute;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
  z-index: 10000001;
  font-weight: 500;
  background: white;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: calc(1.35em + 1.1rem + 2px);
  padding: 0.55rem 0.75rem;
  font-size: 0.925rem;
  line-height: 1.35;
  border-radius: 0.28rem;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>

<style lang="scss">
.bill-info {
  .alert-header {
    position: relative;
    padding: 0.75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }
  .box {
    background: black;
    border-radius: 4px;
    padding-bottom: 100%;
  }
  .headerTable {
    color: rgb(24, 28, 50);
  }

  .form-group label {
    font-weight: 500;
  }

  .textExcessCash {
    font-weight: 500;
    color: blue;
  }
  .row-center-vertical {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .textPoint {
    font-size: 14px;
    font-weight: 500;
  }
  .textFinal {
    font-weight: 500;
    color: brown;
  }
  .textTitlePayment {
    font-weight: 500;
    font-size: 14px;
  }

  .textPayment {
    font-size: 16px;
    font-weight: 500;
    color: brown;
  }

  .no-side {
    padding-left: 0px;
    padding-right: 0px;
  }
}
</style>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-4.scss';
</style>

<style lang="scss">
.upload-file {
  .dropbox {
    margin: auto;
    width: 70%;
    background: #f8f8f8;
    border-radius: 20px;
    color: dimgray;
    padding: 10px 10px;
    min-height: 200px;
    position: relative;
    cursor: pointer;
  }

  .input-file {
    opacity: 0; /* invisible but it's there! */
    left: 0px;
    width: 100%;
    height: 200px;
    position: absolute;
    cursor: pointer;
  }

  .dropbox:hover {
    background: #e8f5e9;
  }

  .dropbox p {
    font-size: 1.2em;
    text-align: center;
    padding: 50px 0;
  }
  .img-container {
    position: relative;
    display: inline-block;
    width: 80px;
    margin: 0px 10px;
  }
  .preview-img {
    max-width: 80px;
    padding: 10px;
    object-fit: contain;
    border: 1px solid #eeecff;
    border-radius: 5px;
  }
  .preview-img-container {
    border: 2px dashed #eeecff;
    padding: 2rem 1rem;
  }

  .preview-box {
    display: inline-block;
  }
  .close {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 15px;
  }
  .remove {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 15px;
  }
  .cancel {
    color: #545454;
    text-decoration: none;
  }
}
</style>
