<template>
  <div>
    <b-form-group :disabled="disabled">
      <label>Điện thoại người nhận bảo hành:</label>
      <vue-autosuggest
        :suggestions="filteredOptionsCustomer"
        @selected="onSelectedCustomer"
        :limit="10"
        @input="onInputChangeCustomer"
        :input-props="inputSearchCustomerProp"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="warrantyCustomerModel.customerMobile"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span class="text-primary">{{ suggestion.item.phoneNo }}</span>
            -
            <span>{{ suggestion.item.fullName }}</span>
          </div>
        </div>
      </vue-autosuggest>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label> Tên: </label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="warrantyCustomerModel.customerName"
        placeholder="Tên người nhận bảo hành"
      ></b-form-input>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Họ:</label>
      <b-form-input
        class="input-style"
        size="sm"
        type="text"
        v-model="warrantyCustomerModel.customerLastName"
        placeholder="Họ người nhận bảo hành"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
import { TIME_TRIGGER } from '../../../utils/constants';
import ApiService from '@/core/services/api.service';
import decounce from 'debounce';

export default {
  props: ['billData', 'disabled'],
  data() {
    return {
      filteredOptionsCustomer: [],
      optionsCustomer: [
        {
          data: [],
        },
      ],
      inputSearchCustomerProp: {
        class: 'autosuggest__input',
        placeholder: 'SĐT người nhận bảo hành',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      warrantyCustomerModel: {
        customerId: null,
        customerMobile: '',
        customerName: '',
        customerLastName: '',
      },
    };
  },
  computed: {},
  mounted() {},
  watch: {
    warrantyCustomerModel: {
      handler: function (newValue) {
        this.billData.warrantyCustomerModel = newValue;
      },
      deep: true,
    },
  },
  methods: {
    onSelectedCustomer(option) {
      this.warrantyCustomerModel.customerId = option.item.id;
      this.warrantyCustomerModel.customerMobile = '';
      this.getCustomerInfoById();
    },
    onInputChangeCustomer(text) {
      if (!text) {
        text = '';
      }
      this.warrantyCustomerModel.customerMobile = text;
      this.optionsCustomer[0].data = [];
      this.filteredOptionsCustomer = [
        {
          data: this.optionsCustomer[0].data,
        },
      ];
      this.debounceInput();
    },
    debounceInput: decounce(function () {
      this.getListCustomer();
    }, TIME_TRIGGER),
    getListCustomer() {
      const text = this.warrantyCustomerModel.customerMobile
        ? this.warrantyCustomerModel.customerMobile.trim()
        : null;
      if (!text) {
        return;
      }
      ApiService.get(`customer/get-by-phone?phoneNumber=${text}`).then(
        (data) => {
          this.optionsCustomer[0].data = data.data.data;
          this.filteredOptionsCustomer = [
            {
              data: this.optionsCustomer[0].data,
            },
          ];
        }
      );
    },
    getCustomerInfoById() {
      ApiService.get(`customer/${this.warrantyCustomerModel.customerId}`).then(
        (data) => {
          const customerInfo = data.data.data;
          this.warrantyCustomerModel.customerMobile = customerInfo.phoneNo;
          this.warrantyCustomerModel.customerName = customerInfo.fullName;
          this.warrantyCustomerModel.customerLastName = customerInfo.lastName
            ? customerInfo.lastName
            : '';
        }
      );
    },
    getListOrderSource() {
      ApiService.get(`orderSources/getAll`).then((data) => {
        this.listCustomerSource = data.data.data;
      });
    },
  },
};
</script>

<style>
.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  z-index: 999;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>