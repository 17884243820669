const { ORDER_STATUS } = require('./enum');

module.exports.ORDER_STATUS_NAME = Object.freeze({
  [ORDER_STATUS.NEW]: 'Mới',
  [ORDER_STATUS.CONFIRMING]: 'Đang xử lí',
  [ORDER_STATUS.CONFIRMED]: 'Đã xác nhận',
  [ORDER_STATUS.DELIVERY]: 'Đang giao',
  [ORDER_STATUS.PACKING]: 'Đang đóng gói',
  [ORDER_STATUS.SUCCESS]: 'Thành công',
  [ORDER_STATUS.UN_SUCCESS]: 'Không thành công',
  [ORDER_STATUS.SYSTEM_CANCEL]: 'Hệ thống hủy',
  [ORDER_STATUS.REFUNDED]: 'Đã chuyển hoàn',
  [ORDER_STATUS.NO_CATCHED_PHONE]: 'Không bắt máy',
  [ORDER_STATUS.CR_CANCEL]: 'CR huỷ',
  [ORDER_STATUS.DEPOSIT]: 'Đã đặt cọc',
  [ORDER_STATUS.AGREE_DEPOSIT]: 'Đồng ý cọc',
  [ORDER_STATUS.PREPARED]: 'Đã chuẩn bị',
  [ORDER_STATUS.TRACKING]: 'Đang theo dõi'
});
