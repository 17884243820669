<template>
  <div>
    <vue-autosuggest
      aria-describedby="input-store-live-feedback"
      :suggestions="filteredOptionsStore"
      @selected="onSelectedStore"
      :limit="10"
      @input="onInputChangeStore"
      :input-props="inputSearchStoreProp"
      :should-render-suggestions="(size, loading) => size >= 0 && !loading"
      v-model="storeNameModel"
    >
      <div
        slot-scope="{ suggestion }"
        style="display: flex; align-items: center"
      >
        {{ suggestion.item.name }}
      </div>
    </vue-autosuggest>
    <b-form-invalid-feedback
      id="input-store-live-feedback"
      :state="validateStore"
      >Vui lòng nhập cửa hàng</b-form-invalid-feedback
    >
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  props: ['onSubmiting', 'storeId', 'storeName', 'disable'],
  data() {
    return {
      filteredOptionsStore: [],
      optionsStore: [
        {
          data: [],
        },
      ],
      inputSearchStoreProp: {
        class: 'autosuggest__input',
        placeholder: 'Tên cửa hàng',
        style: 'border-radius:0px!important',
        disabled: false,
      },
      storeNameModel: '',
    };
  },
  watch: {
    storeId: {
      handler: function () {
        this.fetchStoreByUser();
      },
    },
  },
  computed: {
    validateStore() {
      return !this.storeId && this.onSubmiting ? false : true;
    },
  },
  mounted() {
    this.fetchStoreByUser();
    this.storeNameModel = this.storeName ? this.storeName : '';
  },
  methods: {
    onSelectedStore(option) {
      this.storeId = option.item.id;
      this.$emit('onStoreSelected', option.item.id);
    },
    onInputChangeStore(text) {
      this.storeNameModel = text;
      const filteredData = this.optionsStore[0].data
        .filter((item) => {
          return (
            item.name.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.shortName.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, this.limit);
      this.filteredOptionsStore = [
        {
          data: filteredData,
        },
      ];
    },
    fetchStoreByUser() {
      ApiService.get(`stores/get-by-user`).then(({ data }) => {
        if (data.status === 1) {
          const stores = data.data.stores;
          if (stores.length === 1 && !this.storeId) {
            this.storeId = stores[0].id;
            this.storeNameModel = stores[0].name;
          } else {
            this.optionsStore[0].data = stores;
            const tmp = [{ data: this.optionsStore[0].data }];
            this.filteredOptionsStore = tmp;
            this.storeNameModel = this.storeName ? this.storeName : '';
          }
          this.inputSearchStoreProp.disabled = this.disable;
        }
      });
    },
  },
};
</script>

<style>
.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  z-index: 999;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>