<template>
  <div class="bill-employee">
    <b-form-group :disabled="disabled">
      <label for="input-name">Nhân viên bán hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Sale')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <vue-autosuggest
        :suggestions="filteredOptionsEmployee"
        @selected="onSelected($event, 'Sale')"
        :limit="10"
        @input="onInputChange"
        @focus="onFocusInput(jobTitle.TVBH)"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhân viên bán hàng',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="billEmployeeInfo.saleName"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </div>
      </vue-autosuggest>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Nhân viên kỹ thuật:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Technical')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <vue-autosuggest
        :suggestions="filteredOptionsEmployee"
        @selected="onSelected($event, 'Technical')"
        :limit="10"
        @input="onInputChange"
        @focus="onFocusInput(jobTitle.KTPM)"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhân viên kỹ thuật',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="billEmployeeInfo.technicalName"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </div>
      </vue-autosuggest>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Nhân viên CDPK:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Accessory')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <vue-autosuggest
        :suggestions="filteredOptionsEmployee"
        @selected="onSelected($event, 'Accessory')"
        :limit="10"
        @input="onInputChange"
        @focus="onFocusInput(jobTitle.CDPK)"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhân viên CDPK',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="billEmployeeInfo.accessoryStaffName"
      >
        <div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </div>
      </vue-autosuggest>
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Nhân viên thu ngân:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Cashier')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <vue-autosuggest
        :suggestions="filteredOptionsEmployee"
        @selected="onSelected($event, 'Cashier')"
        :limit="10"
        @input="onInputChange"
        @focus="onFocusInput(jobTitle.KTBH)"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhân viên thu ngân',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="billEmployeeInfo.cashierName"
        ><div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </div></vue-autosuggest
      >
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Quản lý cửa hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Manager')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <vue-autosuggest
        :suggestions="filteredOptionsEmployee"
        @selected="onSelected($event, 'Manager')"
        :limit="10"
        @input="onInputChange"
        @focus="onFocusInput(jobTitle.QL)"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Quản lý cửa hàng',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="billEmployeeInfo.managerName"
        ><div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </div></vue-autosuggest
      >
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Trợ lý cửa hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Assistant')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <vue-autosuggest
        :suggestions="filteredOptionsEmployee"
        @selected="onSelected($event, 'Assistant')"
        :limit="10"
        @input="onInputChange"
        @focus="onFocusInput(jobTitle.TL)"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Trợ lý cửa hàng',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="billEmployeeInfo.assistantName"
        ><div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </div></vue-autosuggest
      >
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Tiếp đón khách hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('CustomerReception')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <vue-autosuggest
        :suggestions="filteredOptionsEmployee"
        @selected="onSelected($event, 'CustomerReception')"
        :limit="10"
        @input="onInputChange"
        @focus="onFocusInput(jobTitle.TDKH)"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Tiếp đón khách hàng',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="billEmployeeInfo.customerReceptionName"
        ><div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </div></vue-autosuggest
      >
    </b-form-group>
    <b-form-group :disabled="disabled">
      <label>Nhân viên giao hàng:</label>
      <i
        class="fas fa-exclamation-circle text-warning ml-2 icon-size"
        v-show="showWarning('Shipper')"
        :title="'Nhân viên chưa được lưu'"
      ></i>
      <vue-autosuggest
        :suggestions="filteredOptionsEmployee"
        @selected="onSelected($event, 'Shipper')"
        :limit="10"
        @input="onInputChange"
        :input-props="{
          class: 'autosuggest__input',
          placeholder: 'Nhân viên giao hàng',
        }"
        :should-render-suggestions="(size, loading) => size >= 0 && !loading"
        v-model="billEmployeeInfo.shipperName"
        ><div
          slot-scope="{ suggestion }"
          style="display: flex; align-items: center"
        >
          <div>
            <span>{{ suggestion.item.fullName }}</span>
            <span class="text-primary" v-if="suggestion.item.code">
              - {{ suggestion.item.code }}
            </span>
          </div>
        </div></vue-autosuggest
      >
    </b-form-group>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import { JOB_TITLE } from '../../../utils/enum';
import { xoa_dau } from '../../../utils/common';
//import { cloneDeep } from 'lodash';

export default {
  props: ['storeId', 'billDataEmployeeInfo', 'disabled'],
  data() {
    return {
      filteredOptionsEmployee: [],
      optionsEmployee: [
        {
          data: [],
        },
      ],
      jobTitle: JOB_TITLE,
      billEmployeeInfo: {
        saleId: null,
        saleName: '',
        technicalId: null,
        technicalName: '',
        cashierId: null,
        cashierName: '',
        accessoryStaffId: null,
        accessoryStaffName: '',
        managerId: null,
        managerName: '',
        assistantId: null,
        assistantName: '',
        customerReceptionId: null,
        customerReceptionName: '',
        shipperId: null,
        shipperName: '',
      },
      billDataEmployee: null,
    };
  },
  computed: {},
  mounted() {
    this.billEmployeeInfo = this.billDataEmployeeInfo;
    this.billDataEmployee = this.billDataEmployeeInfo;
    if (this.storeId) {
      this.fetchEmployeeByStore();
    }
  },
  watch: {
    storeId: {
      handler: function (newValue, oldValue) {
        let isChangeStore = false;
        if (oldValue && oldValue !== newValue) {
          isChangeStore = true;
        }
        this.fetchEmployeeByStore(isChangeStore);
      },
    },
  },
  methods: {
    onSelected(option, type) {
      switch (type) {
        case 'Sale':
          this.billEmployeeInfo.saleId = option.item.id;
          this.billEmployeeInfo.saleName = option.item.fullName;
          this.billDataEmployee.saleId = option.item.id;
          break;
        case 'Technical':
          this.billEmployeeInfo.technicalId = option.item.id;
          this.billEmployeeInfo.technicalName = option.item.fullName;
          this.billDataEmployee.technicalId = option.item.id;
          break;
        case 'Accessory':
          this.billEmployeeInfo.accessoryStaffId = option.item.id;
          this.billEmployeeInfo.accessoryStaffName = option.item.fullName;
          this.billDataEmployee.accessoryStaffId = option.item.id;
          break;
        case 'Cashier':
          this.billEmployeeInfo.cashierId = option.item.id;
          this.billEmployeeInfo.cashierName = option.item.fullName;
          this.billDataEmployee.cashierId = option.item.id;
          break;
        case 'Manager':
          this.billEmployeeInfo.managerId = option.item.id;
          this.billEmployeeInfo.managerName = option.item.fullName;
          this.billDataEmployee.managerId = option.item.id;
          break;
        case 'Assistant':
          this.billEmployeeInfo.assistantId = option.item.id;
          this.billEmployeeInfo.assistantName = option.item.fullName;
          this.billDataEmployee.assistantId = option.item.id;
          break;
        case 'CustomerReception':
          this.billEmployeeInfo.customerReceptionId = option.item.id;
          this.billEmployeeInfo.customerReceptionName = option.item.fullName;
          this.billDataEmployee.customerReceptionId = option.item.id;
          break;
        case 'Shipper':
          this.billEmployeeInfo.shipperId = option.item.id;
          this.billEmployeeInfo.shipperName = option.item.fullName;
          this.billDataEmployee.shipperId = option.item.id;
          break;
      }
    },
    onInputChange(text) {
      if (!text) {
        text = '';
      }

      const filteredData = this.optionsEmployee[0].data
        .filter((item) => {
          const nameNoneSign = xoa_dau(item.fullName);
          return (
            nameNoneSign.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.fullName.toLowerCase().indexOf(text.toLowerCase()) > -1 ||
            item.code.toLowerCase().indexOf(text.toLowerCase()) > -1
          );
        })
        .slice(0, 10);

      this.filteredOptionsEmployee = [
        {
          data: filteredData,
        },
      ];
    },
    fetchEmployeeByStore(isChangeStore) {
      const listStoreId = [this.storeId];
      if (isChangeStore) {
        this.billDataEmployee = {};
        this.billEmployeeInfo = {};
      }
      ApiService.get(
        `employees/get-by-store?employeeName=&storeId=${listStoreId}`
      ).then((data) => {
        const employees = data.data.data;
        this.optionsEmployee[0].data = employees.map((item) => {
          return {
            id: item.id,
            code: item.code ? item.code : '',
            fullName: item.fullName ? item.fullName : '',
            jobTitleId: item.jobTitleId,
          };
        });
        this.filteredOptionsEmployee = [{ data: this.optionsEmployee[0].data }];

        this.autoFill(employees);
      });
    },
    onFocusInput(jobTitleId) {
      const suggesstionData = this.sortEmpByJobTitle(jobTitleId);
      this.filteredOptionsEmployee = [{ data: suggesstionData }];
    },
    sortEmpByJobTitle(jobTitleId) {
      const empJobs = this.optionsEmployee[0].data.filter(
        (emp) => emp.jobTitleId === jobTitleId
      );
      const empDiffJobs = this.optionsEmployee[0].data.filter(
        (emp) => emp.jobTitleId !== jobTitleId
      );
      return empJobs.concat(empDiffJobs);
    },
    autoFill(employees) {
      // auto fill for HTKT
      const listHTKT = employees.filter(
        (item) => item.jobTitleId === this.jobTitle.KTPM
      );
      if (
        listHTKT &&
        listHTKT.length === 1 &&
        !this.billDataEmployee.technicalId
      ) {
        this.billEmployeeInfo.technicalName = listHTKT[0].fullName;
        this.billEmployeeInfo.technicalId = listHTKT[0].id;
      }
      // auto fill for QL
      const listQL = employees.filter(
        (item) => item.jobTitleId === this.jobTitle.QL
      );
      if (listQL && listQL.length === 1 && !this.billDataEmployee.managerId) {
        this.billEmployeeInfo.managerName = listQL[0].fullName;
        this.billEmployeeInfo.managerId = listQL[0].id;
      }
      // auto fill for TL
      const listTL = employees.filter(
        (item) => item.jobTitleId === this.jobTitle.TL
      );
      if (listTL && listTL.length === 1 && !this.billDataEmployee.assistantId) {
        this.billEmployeeInfo.assistantName = listTL[0].fullName;
        this.billEmployeeInfo.assistantId = listTL[0].id;
      }
      // auto fill for TDKH
      const listTDKH = employees.filter(
        (item) => item.jobTitleId === this.jobTitle.TDKH
      );
      if (
        listTDKH &&
        listTDKH.length === 1 &&
        !this.billDataEmployee.customerReceptionId
      ) {
        this.billEmployeeInfo.customerReceptionName = listTDKH[0].fullName;
        this.billEmployeeInfo.customerReceptionId = listTDKH[0].id;
      }
      // auto fill for CDPK
      const listCDPK = employees.filter(
        (item) => item.jobTitleId === this.jobTitle.CDPK
      );
      if (
        listCDPK &&
        listCDPK.length === 1 &&
        !this.billDataEmployee.accessoryStaffId
      ) {
        this.billEmployeeInfo.accessoryStaffName = listCDPK[0].fullName;
        this.billEmployeeInfo.accessoryStaffId = listCDPK[0].id;
      } else if (
        (this.billDataEmployee.saleId || this.billEmployeeInfo.saleId) &&
        !this.billDataEmployee.accessoryStaffId
      ) {
        this.billEmployeeInfo.accessoryStaffName =
          this.billDataEmployee.saleName;
        this.billEmployeeInfo.accessoryStaffId = this.billDataEmployee.saleId;
      }
    },
    showWarning(type) {
      if (!this.billDataEmployee) {
        return false;
      }
      switch (type) {
        case 'Sale':
          if (!this.billDataEmployee.saleId) {
            return true;
          }

          return false;
        case 'Technical':
          if (!this.billDataEmployee.technicalId) {
            return true;
          }

          return false;
        case 'Accessory':
          if (!this.billDataEmployee.accessoryStaffId) {
            return true;
          }

          return false;
        case 'Cashier':
          if (!this.billDataEmployee.cashierId) {
            return true;
          }

          return false;
        case 'Manager':
          if (!this.billDataEmployee.managerId) {
            return true;
          }

          return false;
        case 'Assistant':
          if (!this.billDataEmployee.assistantId) {
            return true;
          }

          return false;
        case 'CustomerReception':
          if (!this.billDataEmployee.customerReceptionId) {
            return true;
          }

          return false;
        case 'Shipper':
          if (!this.billDataEmployee.shipperId) {
            return true;
          }

          return false;
        default:
          return false;
      }
    },
  },
};
</script>

<style>
.autosuggest__results {
  position: absolute;
  width: 100%;
  max-height: 200px;
  z-index: 999;
  border: 1px solid #e0e0e0;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #fff;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__input {
  display: block;
  width: 100%;
  height: calc(1.35em + 1.1rem + 2px);
  line-height: 1.35;
  padding: 0.55rem 0.75rem;
  font-weight: 400;
  font-size: 0.925rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.28rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.autosuggest__input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.autosuggest__results-item:hover {
  background-color: #ddd;
}

.autosuggest__results ul li {
  padding: 8px;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}
</style>

<style lang="scss">
.bill-employee {
  .icon-size {
    font-size: 1rem !important;
  }
}
</style>
