<template>
  <div>
    <b-form-group>
      <label>Chiết khấu</label>
      <b-input-group>
        <template #append>
          <b-button
            size="sm"
            style="width: 40px"
            variant="secondary"
            @click="onChangeDiscountType"
          >
            <i
              v-if="selectDiscountType === 2"
              class="fa fa-percent"
              aria-hidden="true"
            ></i>
            <i v-else class="fas fa-dollar-sign" aria-hidden="true"></i>
          </b-button>
        </template>
        <template class="relative">
          <b-form-input
            size="sm"
            class="text-right"
            id="input-discount"
            v-model="orderDiscount.discountAmount"
            @change="onChangeDiscountAmount"
            :min="1"
            v-mask="mask"
            autocomplete="off"
          ></b-form-input>
          <label class="absolute text-primary">{{
            selectDiscountType === 2 ? 'Phần trăm' : 'Tiền mặt'
          }}</label>
        </template>
      </b-input-group>
    </b-form-group>
    <b-form-group :disabled="id ? true : false">
      <label>Mã coupon:</label>
      <b-input-group>
        <b-form-input
          class="input-style"
          size="sm"
          v-model="orderDiscount.couponCode"
          placeholder="Nhập mã coupon"
          @change="onChangeCouponCode()"
          autocomplete="off"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            style="width: 40px"
            @click="checkCoupon()"
            variant="secondary"
            size="sm"
          >
            <i class="fas fa-sync"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group v-if="customerInfo.customerId">
      <label
        >Tiêu điểm:
        <small
          class="text-success font-weight-bolder"
          v-if="orderDiscount.subtractPointAmount"
          >(Đã xác thực OTP)</small
        ><small class="text-danger font-weight-bolder" v-else
          >(Chưa xác thực OTP)</small
        ></label
      >
      <b-input-group>
        <b-form-input
          class="input-style text-right"
          size="sm"
          v-model="orderDiscount.subtractPoint"
          placeholder="Nhập số điểm"
          v-mask="mask"
          autocomplete="off"
          @change="onChangeSubtractPoint"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            style="width: 40px"
            variant="secondary"
            size="sm"
            @click="checkCustomerPoint()"
          >
            <i class="fas fa-mobile-alt"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group v-if="customerInfo.customerId && showOtp">
      <label>Mã OTP: </label>
      <b-input-group>
        <b-form-input
          class="input-style text-right"
          size="sm"
          v-model="orderDiscount.otpCode"
          placeholder="Nhập mã OTP"
          :disabled="orderDiscount.subtractPointAmount ? true : false"
          autocomplete="off"
        ></b-form-input>
        <b-input-group-append>
          <b-button
            style="width: 40px"
            variant="secondary"
            size="sm"
            :disabled="orderDiscount.subtractPointAmount ? true : false"
            @click="checkValidOTP()"
          >
            <i class="fas fa-sync"></i>
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <b-form-group>
      <label for="input-name">Ngày hẹn thanh toán:</label>
      <div class="input-group">
        <date-picker
          placeholder="Ngày hẹn thanh toán"
          class="form-control-sm input-style"
          :config="dpConfigs.date"
          v-model="orderDiscount.paymentDate"
          :wrap="true"
        ></date-picker>
        <div class="input-group-append">
          <button
            class="btn btn-secondary btn-sm datepickerbutton"
            type="button"
            title="Toggle"
          >
            <i class="far fa-calendar"></i>
          </button>
        </div>
      </div>
    </b-form-group>
    <b-form-group>
      <label for="input-name">Phí vận chuyển thu của khách</label>
      <b-form-input
        class="input-style text-right"
        size="sm"
        type="text"
        v-model="orderDiscount.customerShipFee"
        placeholder="Phí vận chuyển thu của khách"
        v-mask="mask"
      ></b-form-input>
    </b-form-group>
    <b-form-group>
      <label for="input-name">Phí trả hãng vận chuyển</label>
      <b-form-input
        class="input-style text-right"
        size="sm"
        type="text"
        v-model="orderDiscount.shipFee"
        placeholder="Phí trả hãng vận chuyển"
        v-mask="mask"
      ></b-form-input>
    </b-form-group>
    <b-form-group v-if="orderInfo.orderId">
      <!-- <template slot="label">&nbsp;</template> -->
      <b-card no-body class="mt-2">
        <b-card-header
          style="padding: 10px 7px 3px 10px; background-color: #f1f1f1"
        >
          <div class="d-flex">
            <i class="fas fa-history mr-2" style="padding-top: 0.5px"></i>
            <h6>Lịch sử</h6>
          </div>
        </b-card-header>
        <b-card-body class="p-1 pb-0">
          <b-table-simple responsive id="history-table">
            <b-tbody v-for="(history, index) in histories" :key="index">
              <b-tr>
                <b-td style="width: 50%">
                  <b style="font-size: 1rem">{{ history.createdByName }}</b>
                  <span style="font-size: 10px">{{ history.createdAt }}</span>
                </b-td>
                <b-td style="width: 50%">
                  <!-- <a
                                                    class="text-primary pointer"
                                                    @click="getHistoryTab"
                                                  >
                                                    {{ history.actionName }}
                                                  </a> -->
                  <router-link
                    class="text-primary"
                    :to="{
                      name: 'detail-order-history',
                      query: {
                        id: history.id,
                      },
                    }"
                  >
                    {{ history.actionName }}
                  </router-link>

                  <span v-if="history.action === 3" style="font-size: 0.85em">
                    {{ history.newValue.statusName }}
                  </span>
                  <span v-else style="font-size: 0.85em">
                    <span
                      v-if="
                        history.originalValue.status !== history.newValue.status
                      "
                    >
                      {{ history.originalValue.statusName }}
                      ->
                      {{ history.newValue.statusName }}
                    </span>
                  </span>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-card-body>
      </b-card>
    </b-form-group>
  </div>
</template>


<script>
import ApiService from '@/core/services/api.service';
import datePicker from 'vue-bootstrap-datetimepicker';
// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import jQuery from 'jquery';
import Swal from 'sweetalert2';

jQuery.extend(true, jQuery.fn.datetimepicker.defaults, {
  icons: {
    time: 'far fa-clock',
    date: 'far fa-calendar',
    up: 'fas fa-arrow-up',
    down: 'fas fa-arrow-down',
    previous: 'fas fa-chevron-left',
    next: 'fas fa-chevron-right',
    today: 'fas fa-calendar-check',
    clear: 'far fa-trash-alt',
    close: 'far fa-times-circle',
  },
});
import {
  currencyMask,
  makeToastFaile,
  makeToastSuccess,
  unMaskPrice,
} from '@/utils/common';
import { AMOUNT_TYPE } from '@/utils/enum';
import timeUtils from '@/utils/date';
import { mapGetters } from 'vuex';
import { SET_ORDER_DISCOUNT_INFO } from '@/core/services/store/order/order-discount.module.js';
import { ORDER_STATUS } from '@/utils/constants';
import moment from 'moment';
import { commonMixin } from '@/view/mixins/';
import { cloneDeep } from '../../../utils/common';

export default {
  props: {
    action: String,
  },
  mixins: [commonMixin],
  data() {
    return {
      orderDiscount: null,
      selectDiscountType: 1,
      stateCouponCode: null,
      selectTypeSearch: 1,
      listTypeSearch: [
        {
          id: 1,
          name: 'Tìm sản phẩm',
        },
        {
          id: 2,
          name: 'Tìm sản phẩm imei',
        },
      ],
      searchProduct: null,
      filteredOptionsProduct: [],
      mask: currencyMask,
      listOrderItem: [],
      id: null,
      dpConfigs: null,
      histories: [],
      orderInfo: null,
      customerInfo: null,
      onLoading: false,
      showOtp: false,
      validCouponCode: null,
      subtractPointTerm: 0,
    };
  },
  components: {
    datePicker,
  },
  watch: {
    orderDiscount: {
      handler: function (newValue) {
        this.$store.dispatch(SET_ORDER_DISCOUNT_INFO, newValue);
      },
      deep: true,
    },
  },
  created() {
    this.dpConfigs = timeUtils.DP_CONFIG;

    this.orderDiscount = this.getOrderDiscount;
    this.orderInfo = this.getOrderInfo;
    this.customerInfo = this.getOrderCustomerInfo;

    if (this.action === 'update') {
      this.fetchOrderHistory(this.orderInfo.orderId);
    }
  },
  mounted() {},
  computed: {
    ...mapGetters([
      'getOrderDiscount',
      'getOrderInfo',
      'getOrderCustomerInfo',
      'getProducts',
    ]),
  },
  methods: {
    fetchOrderHistory: function (orderId) {
      const params = {
        //  loại đơn hàng
        type: 1,
        orderBy: ['createdAt', 'DESC'],
      };
      ApiService.query('/histories/getByReferenceId/' + orderId, {
        params,
      }).then((response) => {
        response.data.data.map((item) => {
          const data = {
            ...item,
            originalValue: item.originalValue || null,
            newValue: item.newValue || null,
            createdAt: moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss'),
            updatedAt: moment(item.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
          };
          const items = [data.originalValue, data.newValue];

          items.map((element) => {
            if (element && element.status) {
              const listStatus = ORDER_STATUS.reduce((result, current) => {
                if (current.checked === false) {
                  current.value = Number(current.value);
                  result.push(current);
                }
                return result;
              }, []);
              element.statusName = null;
              const status = listStatus.find(
                (x) => +x.value === +element.status
              );
              if (status) element.statusName = status.text;
            }
          });
          if (this.histories.length < 3) {
            this.histories.push(data);
          }
        });
      });
    },
    onChangeDiscountAmount: async function () {
      if (this.validCouponCode && this.orderDiscount.couponCode) {
        this.orderDiscount.couponCode = null;
        this.validCouponCode = false;
      }
    },
    onChangeDiscountType() {
      this.selectDiscountType =
        this.selectDiscountType === AMOUNT_TYPE.PERCENT
          ? AMOUNT_TYPE.MONEY
          : AMOUNT_TYPE.PERCENT;
      this.orderDiscount.discountType = this.selectDiscountType;
    },
    onChangeSubtractPoint: async function () {
      if (this.orderDiscount.subtractPointAmount) {
        const text = `Chỉnh sửa lại tiêu điểm sẽ xác nhận lại mã otp`;

        const alert = await this.showWarningPointAlert(text);
        if (alert.isConfirmed) {
          this.orderDiscount.otpCode = null;
          this.orderDiscount.subtractPointAmount = null;
          return;
        } else {
          this.orderDiscount.subtractPoint = this.subtractPointTerm;
        }
      }
    },
    onChangeCouponCode() {
      if (!this.orderDiscount.couponCode) {
        this.validCouponCode = null;
        this.orderDiscount.discountAmount = 0;
        this.orderDiscount.discountType = 1;
        return;
      }
      if (
        this.validCouponCode &&
        this.validCouponCode !== this.orderDiscount.couponCode
      ) {
        this.orderDiscount.discountAmount = 0;
        this.orderDiscount.discountType = 1;
        this.validCouponCode = null;
      }
    },
    checkCoupon: async function () {
      const products = this.getProducts;
      if (!products || !products.length) {
        makeToastFaile(`Vui lòng chọn sản phẩm`);
        return;
      }

      if (!this.orderDiscount.couponCode) {
        makeToastFaile(`Vui lòng nhập mã coupon`);
        return;
      }

      if (this.orderDiscount.discountAmount) {
        const text = `Sử dụng mã coupon ${this.orderDiscount.couponCode} thay cho chiết khấu`;

        const alert = await this.showWarningAlert(text);

        if (!alert.isConfirmed) {
          this.orderDiscount.couponCode = null;
          this.validCouponCode = null;
          return;
        }
      }

      // const productsFormatter = this.formatOrderItem(products);
      const data = {
        storeId: this.orderInfo.storeId,
        listProduct: this.handleOrderItemUpsert(cloneDeep(products)),
        couponCode: this.orderDiscount.couponCode,
      };

      ApiService.post('coupon/check-coupon', data)
        .then((response) => {
          const { data } = response.data;
          const { status, message } = response.data;

          if (status === 1) {
            makeToastSuccess(message);
            this.orderDiscount.discountAmount = data;
            this.orderDiscount.discountType = 1;
            this.validCouponCode = data;
          } else {
            makeToastFaile(message);
          }
        })
        .catch(({ response }) => {
          makeToastFaile(response.$error);
        });
    },
    checkCustomerPoint() {
      if (!this.onLoading) {
        const pointUse = unMaskPrice(this.orderDiscount.subtractPoint) || 0;

        this.orderDiscount.subtractPointAmount = null;
        this.orderDiscount.otpCode = null;
        const customerId = this.customerInfo.customerId || null;

        if (pointUse <= 0) {
          this.showOtp = false
          makeToastFaile('Số điểm không hợp lệ');
          return;
        }

        this.onLoading = true;
        const data = {
          customerId: customerId,
          pointUse: pointUse,
        };

        ApiService.post('customerOTP/', data)
          .then((response) => {
            this.onLoading = false;
            const { status, message } = response.data;
            if (status === 1) {
              makeToastSuccess(message);
              this.subtractPointTerm = pointUse;
              this.showOtp = true;
            } else {
              makeToastFaile(message);
            }
          })
          .catch(({ response }) => {
            this.onLoading = false;
            makeToastFaile(response.$error);
          });
      }
    },
    checkValidOTP() {
      if (!this.onLoading) {
        this.onLoading = true;

        const customerId = this.customerInfo.customerId;
        const data = {
          customerId: customerId,
          pointUse: unMaskPrice(this.orderDiscount.subtractPoint),
          otpCode: this.orderDiscount.otpCode,
        };

        ApiService.post('customerOTP/checkOTP', data)
          .then((response) => {
            this.onLoading = false;

            const { data } = response.data;
            const { status, message } = response.data;

            if (status === 1) {
              makeToastSuccess(message);
              this.orderDiscount.subtractPointAmount = data;
            } else {
              makeToastFaile(data.message);
            }
          })
          .catch(({ response }) => {
            this.onLoading = false;
            makeToastFaile(response.$error);
          });
      }
    },
    showWarningAlert: function (text) {
      return Swal.fire({
        title: 'Sử dụng chiết khấu!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    },
    showWarningPointAlert: function (text) {
      return Swal.fire({
        title: 'Sử dụng điểm!',
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'rgb(221, 51, 51)',
        cancelButtonColor: '#E5EAEE',
        confirmButtonText: 'Đồng ý',
        cancelButtonText: '<span style="color:#464E5F">Hủy</span>',
        reverseButtons: true,
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    },
  },
};
</script>
<style scoped>
#history-table tbody tr td > span {
  display: block;
}
#history-table th,
td {
  padding: 0.3rem;
}
#history-table tbody tr td > b {
  width: max-content;
}
</style>

